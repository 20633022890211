import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import Spinner from '@/components/ui/Spinner/Spinner.tsx';
import { capitalize } from 'lodash';
import styles from './ButtonLink.module.scss';

export interface ButtonLinkProps {
	href: string;
	target?: string;
	size?: 'small' | 'medium' | 'large';
	color?: 'default' | 'grey' | 'green' | 'darkGreen' | 'link' | 'transparent';
	align?: 'left' | 'center' | 'right';
	borderRadius?: 'small' | 'medium' | 'large';
	fullWidth?: boolean;
	disabled?: boolean;
	loading?: boolean;
	leftIcon?: ReactElement;
	rightIcon?: ReactElement;
	children: ReactNode;
	className?: string;
	hover?: boolean;
}

const ButtonLink: React.FC<ButtonLinkProps> = ({
	href,
	target = '_self',
	size = 'medium',
	color = 'link',
	align = 'left',
	borderRadius = 'small',
	fullWidth = false,
	disabled,
	loading,
	leftIcon = undefined,
	rightIcon = undefined,
	children,
	className,
	hover = true,
}) => {
	const borderRadiusMapping: { [key in NonNullable<ButtonLinkProps['borderRadius']>]: number } = {
		small: 5,
		medium: 15,
		large: 25,
	};

	return (
		<a
			href={href}
			target={target}
			className={classNames(
				styles.wrapper,
				styles[`size${capitalize(size)}`],
				styles[color],
				styles[align],
				fullWidth && styles.fullWidth,
				loading && styles.loading,
				disabled && styles.disabled,
				hover && styles.hover,
				className,
			)}
			style={{
				borderRadius: borderRadiusMapping[borderRadius],
			}}
		>
			{!!leftIcon && leftIcon}

			{loading ? <Spinner /> : children}

			{!!rightIcon && rightIcon}
		</a>
	);
};

export default ButtonLink;
