import React from 'react';
import styles from './Welcome.module.scss';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';
import Container from '@/components/ui/Container/Container.tsx';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock';

const Welcome: React.FC = () => {
	const user = useWhoamiStore(state => state.user);

	return (
		<div className={styles.welcomeWrapper}>
			<Container>
				<FlexBlock justifyContent="space-between">
					<h1 className={styles.welcomeTitle}>Sustainability tools for you, {user?.firstName} &#128154;</h1>
				</FlexBlock>
			</Container>
		</div>
	);
};

export default Welcome;
