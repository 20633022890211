import React, { ReactNode } from 'react';
import useResponsiveValue, { ResponsiveValue } from '@/hooks/useBreakpointValue.ts';
import { Property } from 'csstype';

interface ColumnProps {
	children: ReactNode;
	className?: string;
	rowGap?: ResponsiveValue<Property.RowGap>;
	columnGap?: ResponsiveValue<Property.ColumnGap>;
	flex?: ResponsiveValue<Property.Flex>;
}

const Column: React.FC<ColumnProps> = ({ children, className, rowGap, columnGap, flex = '1' }) => {
	return (
		<div
			className={className}
			style={{
				rowGap: useResponsiveValue(rowGap),
				columnGap: useResponsiveValue(columnGap),
				flex: useResponsiveValue(flex),
			}}
		>
			{children}
		</div>
	);
};

export default Column;
