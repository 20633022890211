import axios from 'axios';
import { User } from '@/api/user.ts';
import LoginRequest = App.Data.Request.LoginRequest;
import EnableUserTwoFaRequest = App.Data.Request.EnableUserTwoFaRequest;

interface LogoutResponse {
	message: string;
}

export interface WhoamiResponse {
	accessCountry?: string;
	bookingEngine?: string;
	currency?: string;
	locale?: string;
	spotnanaSsoUrl?: string;
	weGlotLanguageCode?: string;
	user?: User;
	roles?: string[];
	ssoUrls?: {
		google: string;
		microsoft: string;
	};
	redirectUrl?: string;
	twoFaEnabled?: boolean;
	twoFaUrl?: string;
}

export interface SsoUserRegisterRequest {
	organizationId: number | undefined;
	token: string;
	provider: string;
	legalEntityId: number | undefined;
}

export interface SsoGetUsersOrganizationRequest {
	provider: string;
	token: string;
}

export interface LegalEntity {
	id: number;
	name: string;
	currency: string;
	countryCode: string;
	city: string;
}

export interface SsoGetUsersOrganizationResponse {
	organizations: {
		countryCode: string;
		id: number;
		name: string;
		legalEntities: LegalEntity[];
	}[];
}

export interface SpotananaWhoamiResponse {
	loggedIn: boolean;
	userUuid?: string;
	ssoUrl?: string;
}

interface UserOrganizationResponse {
	legalEntities: LegalEntity[];
	name: string;
	addressLine1: string | null;
	postalCode: string | null;
	countryCode: string | null;
}

export interface RegisterUserRequest {
	user: {
		firstName: string;
		middleName?: string;
		lastName: string;
		email: string;
		password: string;
		confirmPassword: string;
		phone: string;
		addressLine1?: string;
		city?: string;
		postalCode?: string;
		countryCode?: string;
		stateProvinceCode?: string;
	};
	legalEntityId?: number | undefined;
}

export const enableTwoFactor = async (data: EnableUserTwoFaRequest): Promise<void> => {
	const response = await axios.patch('/users/2fa/enable', data);
	return response.data;
};

export const disableTwoFactor = async (): Promise<void> => {
	const response = await axios.patch('/users/2fa/disable');
	return response.data;
};

export const login = async (requestData: LoginRequest): Promise<void> => {
	const response = await axios.post('/users/login', requestData);
	return response.data;
};

export const logout = async (): Promise<LogoutResponse> => {
	const response = await axios.post('/users/logout');
	return response.data;
};

export const whoami = async (): Promise<WhoamiResponse> => {
	const response = await axios.get<WhoamiResponse>('/users/whoami');
	return response.data;
};

export const spotnanaWhoami = async (targetPath: string | null): Promise<SpotananaWhoamiResponse> => {
	const response = await axios.get<SpotananaWhoamiResponse>('/spotnana/whoami?targetPath=' + targetPath);
	return response.data;
};

export const forgotPassword = async (email: string): Promise<void> => {
	const response = await axios.post('/users/password/forgot', {
		email,
	});
	return response.data;
};

export const resetPassword = async (email: string, password: string, token: string): Promise<void> => {
	const response = await axios.post('/users/password/reset', {
		email,
		password,
		token,
	});
	return response.data;
};

export const ssoUserRegister = async (data: SsoUserRegisterRequest): Promise<void> => {
	const response = await axios.post(`/auth/${data.provider}/users`, data);
	return response.data;
};

export const ssoGetUsersOrganization = async (
	data: SsoGetUsersOrganizationRequest,
): Promise<SsoGetUsersOrganizationResponse> => {
	const response = await axios.get(`/auth/${data.provider}/organizations`, {
		params: data,
	});
	return response.data;
};

// During user's onboarding
export const getUsersOrganization = async (token: string): Promise<UserOrganizationResponse> => {
	const response = await axios.get<UserOrganizationResponse>(`/organizations/${token}`);
	return response.data;
};

//  Onboarding employee
export const registerUser = async (data: RegisterUserRequest): Promise<void> => {
	const response = await axios.post(`/users`, data);
	return response.data;
};
