import React from 'react';
import MainLayout from '@/layouts/MainLayout/MainLayout.tsx';
import styles from './Error404.module.scss';
import routes from '@/constants/routes.ts';
import { useNavigate } from 'react-router';
import Button from '@/components/ui/Button/Button.tsx';

const Error404: React.FC = () => {
	const navigate = useNavigate();

	return (
		<MainLayout>
			<div className={styles.wrapper}>
				<h1>Page not found</h1>
				<Button
					size="large"
					color="green"
					align="center"
					borderRadius="large"
					onClick={() => navigate(routes.dashboard)}
				>
					Go home
				</Button>
			</div>
		</MainLayout>
	);
};

export default Error404;
