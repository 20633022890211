import CardWithImage from '@/components/ui/CardWithImage/CardWithImage';
import styles from './EmissionReportCard.module.scss';
import EmissionsReport from '@/assets/Illustrations/Emissions-report.png';
import routes from '@/constants/routes';
import Button from '@/components/ui/Button/Button';
import { useNavigate } from 'react-router-dom';

const EmissionReportCard = () => {
	const navigate = useNavigate();

	return (
		<CardWithImage
			reverse
			illustration={<img src={EmissionsReport} />}
			header="Emission reports"
			text={
				<p>
					Get an overview of your company’s travel emissions with a detailed report. Choose to download all
					emissions data or filter by a specific time period.
				</p>
			}
			button={
				<Button color="grey" onClick={() => navigate(routes.emissionReports)}>
					Generate emission reports
				</Button>
			}
			className={styles.emissionCard}
		/>
	);
};

export default EmissionReportCard;
