import React, { ReactNode } from 'react';
import { Property } from 'csstype';
import useResponsiveValue, { ResponsiveValue } from '@/hooks/useBreakpointValue.ts';

interface RowProps {
	children: ReactNode;
	maxWidth?: string;
	className?: string;
	flexDirection?: ResponsiveValue<Property.FlexDirection>;
	rowGap?: ResponsiveValue<Property.RowGap>;
	columnGap?: ResponsiveValue<Property.ColumnGap>;
}

const Row: React.FC<RowProps> = ({
	children,
	maxWidth = '100%',
	className,
	flexDirection = 'row',
	rowGap,
	columnGap,
}) => {
	return (
		<div
			className={className}
			style={{
				display: 'flex',
				maxWidth: maxWidth,
				flexDirection: useResponsiveValue(flexDirection),
				rowGap: useResponsiveValue(rowGap),
				columnGap: useResponsiveValue(columnGap),
				marginBottom: '65px',
			}}
		>
			{children}
		</div>
	);
};

export default Row;
