import React, { useEffect } from 'react';
import CardWithImage from '@/components/ui/CardWithImage/CardWithImage.tsx';
import CO2GraphIllustration from '@/assets/Illustrations/CO2-Graph.svg?react';
import Button from '@/components/ui/Button/Button.tsx';
import ArrowRight from '@/assets/Icons/Arrow-Right.svg?react';
import Container from '@/components/ui/Container/Container.tsx';
import styles from './EmissionTarget.module.scss';
import { useModalStore } from '@/store/useModalStore.ts';
import EmissionTargetModal from '@/pages/Dashboard/EmissionTargetModal/EmissionTargetModal.tsx';
import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { deleteEmissionTarget, getEmissionTarget } from '@/api/emissionTarget.ts';
import PlanetIllustration from '@/assets/Illustrations/Planet-Leaves.svg?react';
import moment from 'moment';
import { isDevelopment } from '@/constants/envMode.ts';
import queryKeys from '@/constants/queryKeys.ts';
import { AxiosError } from 'axios';
import { DashboardAnalyticsData } from '@/api/dashboardAnalytics.ts';

interface EmissionTargetProps {
	queryAnalytics: UseQueryResult<DashboardAnalyticsData | null, unknown>;
}

const EmissionTarget: React.FC<EmissionTargetProps> = ({ queryAnalytics }) => {
	const { openModal, activeModal } = useModalStore(state => state);
	const { refetch: refetchChart } = queryAnalytics;
	const {
		data: emissionTargetData,
		isError,
		error,
		refetch,
	} = useQuery({
		queryKey: [queryKeys.getEmissionTarget],
		queryFn: getEmissionTarget,
		retry: (_, error: AxiosError) => {
			return error.response?.status !== 404;
		},
	});

	// When emission target is updated, refetch chart
	useEffect(() => {
		refetchChart();
	}, [emissionTargetData, isError, refetchChart]);

	const mutation = useMutation({
		mutationFn: deleteEmissionTarget,
		onSuccess: () => {
			refetch();
		},
		onError: (error: AxiosError) => {
			if (error.response?.status === 404) {
				refetch();
			}
		},
	});

	const handleDelete = () => {
		if (emissionTargetData) {
			mutation.mutate();
		}
	};

	const axiosError = error as AxiosError;
	const is404Error = axiosError?.response?.status === 404;

	if (isError && !is404Error) {
		return (
			<Container>
				<CardWithImage
					reverse
					header="Oops!"
					illustration={<PlanetIllustration />}
					text={<p className={styles.emission}>We're encountering an issue retrieving your data.</p>}
					className={styles.cardWrapper}
				/>
			</Container>
		);
	}

	return (
		<>
			<Container>
				{emissionTargetData && !is404Error ? (
					<CardWithImage
						reverse
						illustration={<PlanetIllustration />}
						header="CO2 Emissions target"
						{...(isDevelopment && {
							button: (
								<>
									<p className={styles.showOnStaging}>This button only shown on staging </p>
									<Button
										size="large"
										onClick={handleDelete}
										loading={mutation.isPending}
										disabled={mutation.isPending}
										className={styles.deleteButton}
									>
										Delete Carbon Budget Target
									</Button>
								</>
							),
						})}
						text={
							<>
								<p className={styles.reduction}>{emissionTargetData.reductionPercentage}% reduction</p>
								<p className={styles.emission}>
									An absolute reduction from {moment().subtract(1, 'year').year()} emissions to
									<span> {emissionTargetData?.targetCo2} tons</span>.
								</p>
								<div className={styles.yearWrapper}>{moment().year()} target</div>
								<button
									className={styles.updateButton}
									onClick={() => openModal('emissionTargetModal')}
								>
									Change your company target
								</button>
							</>
						}
						className={styles.cardWrapper}
					/>
				) : (
					<CardWithImage
						reverse
						illustration={<CO2GraphIllustration />}
						text={
							<>
								<p>
									Emissions reduction targets are one the best ways to focus and communicate your
									organization's attention on climate change, sustainability, and ESG performance.
								</p>
								<p>
									Emissions targets are clear, quantifiable goals and market signals that help align
									your company's purpose and sustainability strategy with a carbon accounting process
									and operational steps to reduce its environmental footprint.
								</p>
							</>
						}
						header="CO2 Emissions target"
						button={
							<Button
								color="green"
								onClick={() => openModal('emissionTargetModal')}
								rightIcon={<ArrowRight />}
								className={styles.emissionButton}
							>
								Start setting your target
							</Button>
						}
						className={styles.cardWrapper}
					/>
				)}
			</Container>
			<EmissionTargetModal key={activeModal} />
		</>
	);
};

export default EmissionTarget;
