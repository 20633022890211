import React from 'react';
import { APIProvider, Map, useMap } from '@vis.gl/react-google-maps';
import styles from './MapPreview.module.scss';
import polyline from '@mapbox/polyline';
import GoogleDirectionsRouteData = App.Data.GoogleDirectionsRouteData;

interface MapPreviewProps {
	mode?: App.Enums.TransportationMode;
	route: GoogleDirectionsRouteData;
	onOpenModal: (route: GoogleDirectionsRouteData) => void;
}

export const modeColors = {
	driving: '#FF5733',
	transit: '#4285F4',
	walking: '#34A853',
	bicycling: '#7733ff',
};

const MapContent: React.FC<{ route: GoogleDirectionsRouteData; mode?: App.Enums.TransportationMode }> = ({
	route,
	mode,
}) => {
	const map = useMap();
	const [path, setPath] = React.useState<any[]>([]);

	React.useEffect(() => {
		if (route && map) {
			const bounds = new window.google.maps.LatLngBounds();
			const fullPath: any[] = [];

			const decodedPolyline = polyline.decode(route.polyline).map(([lat, lng]) => ({
				lat,
				lng,
			}));
			decodedPolyline.forEach((point: any) => bounds.extend(point));
			fullPath.push(...decodedPolyline);
			setPath(fullPath);

			map.fitBounds(bounds);
		}
	}, [route, map]);

	React.useEffect(() => {
		if (path.length > 0 && map) {
			const polylinePath = new window.google.maps.Polyline({
				path: path,
				geodesic: true,
				strokeColor: modeColors[mode as keyof typeof modeColors] || '#4285F4',
				strokeOpacity: 0.8,
				strokeWeight: 5,
			});

			polylinePath.setMap(map);

			return () => polylinePath.setMap(null);
		}
	}, [path, map]);

	return null;
};

const MapPreview: React.FC<MapPreviewProps> = ({ mode, route, onOpenModal }) => {
	const apiKey = import.meta.env.VITE_GOOGLE_CLOUD_API_KEY;

	return (
		<div className={styles.mapPreviewWrapper} onClick={() => onOpenModal(route)}>
			<APIProvider apiKey={apiKey} libraries={['geometry']}>
				<div className={styles.container}>
					<Map
						disableDefaultUI={true}
						gestureHandling="none"
						defaultZoom={14}
						defaultCenter={{
							lat: route.centerCoordinates.latitude,
							lng: route.centerCoordinates.longitude,
						}}
					>
						<MapContent route={route} mode={mode} />
					</Map>
				</div>
			</APIProvider>
			<div className={styles.overlay}>Open map</div>
		</div>
	);
};

export default MapPreview;
