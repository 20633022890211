import Quiz from '@/assets/Illustrations/Take-quiz.svg?react';
import QuizModal from '../../QuizModal/QuizModal';
import Button from '@/components/ui/Button/Button';
import CardWithImage from '@/components/ui/CardWithImage/CardWithImage';
import { useModalStore } from '@/store/useModalStore';
import ArrowRight from '@/assets/Icons/Arrow-Right.svg?react';
import styles from './QuizCard.module.scss';

const QuizCard = () => {
	const { openModal, activeModal } = useModalStore(state => state);

	return (
		<>
			<CardWithImage
				reverse
				illustration={<Quiz />}
				header="Try the sustainability quiz!"
				button={
					<Button
						color="green"
						size="large"
						rightIcon={<ArrowRight />}
						onClick={() => {
							openModal('quizModal');
						}}
					>
						Take the quiz
					</Button>
				}
				className={styles.quizCard}
			/>
			<QuizModal key={activeModal} />
		</>
	);
};

export default QuizCard;
