import React, { ReactElement } from 'react';
import styles from './CardWithImage.module.scss';
import Card from '@/components/ui/Card/Card.tsx';
import classNames from 'classnames';

interface CardWithImageProps {
	illustration: ReactElement;
	header: string;
	text?: ReactElement;
	reverse?: boolean;
	button?: ReactElement;
	className?: string;
}

const CardWithImage: React.FC<CardWithImageProps> = ({ illustration, header, text, button, reverse, className }) => {
	return (
		<Card className={classNames(styles.cardWrapper, reverse && styles.reverse, className)}>
			<div className={styles.imageWrapper}>{illustration}</div>
			<div className={styles.content}>
				<h4>{header}</h4>
				{text && text}
				{button}
			</div>
		</Card>
	);
};

export default CardWithImage;
