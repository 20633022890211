import { FC } from 'react';
import classNames from 'classnames';
import SpinnerIcon from '@/assets/Icons/Spinner-2.svg?react';
import styles from './Spinner.module.scss';

interface SpinnerProps {
	color?: string;
	overlay?: boolean;
	message?: string;
	absolute?: boolean;
}

const Spinner: FC<SpinnerProps> = ({ color = 'black', overlay = false, message, absolute = true }) => {
	const containerClassnames = classNames(
		styles.spinnerContainer,
		styles[color],
		overlay && styles.overlay,
		absolute && styles.absolute,
	);

	return (
		<div>
			<div className={containerClassnames}>
				<SpinnerIcon className={styles.spinnerIcon} />
				{message && <p className={styles.message}>{message}</p>}
			</div>
		</div>
	);
};

export default Spinner;
