import React from 'react';
import styles from './FormikErrors.module.scss';
import { useFormikContext } from 'formik';

export interface GlobalErrors {
	_error?: string;
}

const GlobalError: React.FC = () => {
	const { errors } = useFormikContext<GlobalErrors>();

	if (!errors._error) {
		return null;
	}

	return <p className={styles.errorText}>{errors._error}</p>;
};

export default GlobalError;
