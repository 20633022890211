import Button from '@/components/ui/Button/Button';
import CardWithImage from '@/components/ui/CardWithImage/CardWithImage';
import { externalLinks } from '@/constants/externalLinks';
import styles from './CertificateCard.module.scss';
import CertificateImage from '@/assets/Images/Certificate.png';
import ButtonLink from '@/components/ui/ButtonLink/ButtonLink.tsx';

const CertificateCard = () => (
	<CardWithImage
		reverse
		illustration={<img src={CertificateImage} />}
		header="Your climate certificate"
		text={
			<>
				<p>
					Get an overview of your company’s travel emissions with a detailed report. Choose to download all
					emissions data or filter by a specific time period.
				</p>
				<ButtonLink target="_blank" href={externalLinks.certificateExplanation}>
					What is the certificate?
				</ButtonLink>
			</>
		}
		button={
			<Button
				color="grey"
				onClick={() => {
					window.open(externalLinks.climateCertificateExample, '_blank');
				}}
			>
				See climate certificate example
			</Button>
		}
		className={styles.certificateCard}
	/>
);

export default CertificateCard;
