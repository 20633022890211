import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { activateAccountSchema } from '@/validations/user.ts';
import FormikInput from '@/components/Formik/FormikInput.tsx';
import MainLayout from '@/layouts/MainLayout/MainLayout.tsx';
import Container from '@/components/ui/Container/Container.tsx';
import Button from '@/components/ui/Button/Button.tsx';
import styles from './ActivateAccount.module.scss';
import handleResponseErrors from '@/utils/handleResponseErrors.ts';
import { activateAccount } from '@/api/user.ts';
import { whoami } from '@/api/auth.ts';
import GlobalError from '@/components/FormikErrors/GlobalError.tsx';
import { useParams } from 'react-router';
import { externalLinks } from '@/constants/externalLinks';

interface ActivateAccountValues {
	password: string;
	confirmPassword: string;
}

const ActivateAccount: React.FC = () => {
	const { token } = useParams<{ token: string }>();

	const handleSubmit = async (
		values: ActivateAccountValues,
		formikHelpers: FormikHelpers<ActivateAccountValues>,
	): Promise<void> => {
		const changedValues = { password: values.password, token: token || '' };

		try {
			await activateAccount(changedValues);
			await whoami();
			window.location.href = externalLinks.spotnana.book;
		} catch (error) {
			handleResponseErrors(error, formikHelpers);
		}
	};

	return (
		<MainLayout>
			<Container>
				<div className={styles.activateAccountWrapper}>
					<Formik
						initialValues={{
							password: '',
							confirmPassword: '',
						}}
						validationSchema={activateAccountSchema}
						onSubmit={handleSubmit}
					>
						{({ isSubmitting }) => (
							<Form className={styles.formWrapper}>
								<h3>Activate your account</h3>
								<p>Please choose your password below</p>

								<GlobalError />
								<FormikInput
									name="password"
									label="Password*"
									type="password"
									className={styles.formikInput}
								/>
								<FormikInput
									name="confirmPassword"
									label="Confirm Password*"
									type="password"
									className={styles.formikInput}
								/>
								<Button
									type="submit"
									size="large"
									color="green"
									disabled={isSubmitting}
									loading={isSubmitting}
									className={styles.submitButton}
								>
									Activate my account
								</Button>
							</Form>
						)}
					</Formik>
				</div>
			</Container>
		</MainLayout>
	);
};

export default ActivateAccount;
