import Card from '@/components/ui/Card/Card';
import Container from '@/components/ui/Container/Container';
import MainLayout from '@/layouts/MainLayout/MainLayout';
import styles from './EmissionReports.module.scss';
import { useState } from 'react';
import Select from '@/components/ui/Select/Select';
import moment, { Moment } from 'moment';
import DatePicker from '@/components/ui/DatePicker/DatePicker';
import { parseDate } from '@internationalized/date';
import { DateValue } from 'react-aria-components';
import Button from '@/components/ui/Button/Button';
import useDownloadFile from './useDownloadFile';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import breakpoints from '@/constants/breakpoints.ts';

type RangeValue = 'all' | '12months' | '6months' | '3months' | '30days' | 'custom';

const rangeValues: {
	label: string;
	value: RangeValue;
}[] = [
	{
		label: 'All time',
		value: 'all',
	},
	{
		label: 'Last 12 months',
		value: '12months',
	},
	{
		label: 'Last 6 months',
		value: '6months',
	},
	{
		label: 'Last 3 months',
		value: '3months',
	},
	{
		label: 'Last 30 days',
		value: '30days',
	},
	{
		label: 'Custom',
		value: 'custom',
	},
];

const momentToDateValue = (date: Moment): DateValue => {
	return parseDate(date.format('YYYY-MM-DD'));
};

const EmissionReports = () => {
	const [range, setRange] = useState<RangeValue>(rangeValues[0]?.value);
	const [startDate, setStartDate] = useState<DateValue>(momentToDateValue(moment().startOf('year')));
	const [endDate, setEndDate] = useState<DateValue>(momentToDateValue(moment()));

	const handleRangeChange = (value: RangeValue) => {
		setRange(value);

		const today = moment();

		switch (value) {
			case '12months':
				setStartDate(momentToDateValue(today.clone().subtract(12, 'months').startOf('day')));
				setEndDate(momentToDateValue(today.clone().endOf('day')));
				break;
			case '6months':
				setStartDate(momentToDateValue(today.clone().subtract(6, 'months').startOf('day')));
				setEndDate(momentToDateValue(today.clone().endOf('day')));
				break;
			case '3months':
				setStartDate(momentToDateValue(today.clone().subtract(3, 'months').startOf('day')));
				setEndDate(momentToDateValue(today.clone().endOf('day')));
				break;
			case '30days':
				setStartDate(momentToDateValue(today.clone().subtract(30, 'days').startOf('day')));
				setEndDate(momentToDateValue(today.clone().endOf('day')));
				break;
			case 'custom':
				break;
			case 'all':
			default:
				break;
		}
	};

	const handleStartDateChange = (date: DateValue) => {
		const newStartDate = moment(date.toString());
		if (newStartDate.isSame(moment(endDate.toString()), 'day')) {
			setEndDate(momentToDateValue(newStartDate.clone().add(1, 'days')));
		} else if (newStartDate.isAfter(moment(endDate.toString()))) {
			setEndDate(momentToDateValue(newStartDate.clone().add(1, 'days')));
		}
		setStartDate(parseDate(newStartDate.format('YYYY-MM-DD')));
		setRange('custom');
	};

	const handleEndDateChange = (date: DateValue) => {
		const newEndDate = moment(date.toString());
		if (newEndDate.isSame(moment(startDate.toString()), 'day')) {
			setStartDate(momentToDateValue(newEndDate.clone().subtract(1, 'days')));
		} else if (newEndDate.isBefore(moment(startDate.toString()))) {
			setStartDate(momentToDateValue(newEndDate.clone().subtract(1, 'days')));
		}
		setEndDate(parseDate(newEndDate.format('YYYY-MM-DD')));
		setRange('custom');
	};

	const isDatesEnabled = range === 'custom';
	const { downloader } = useDownloadFile();

	const onDownlaod = (fileType: 'csv' | 'xlsx') => {
		const apiUrl = import.meta.env.VITE_API_URL;
		const params = new URLSearchParams();

		params.append('file', fileType);

		if (range !== 'all') {
			params.append('from', moment(startDate.toString()).format('YYYY-MM-DD'));
			params.append('to', moment(endDate.toString()).format('YYYY-MM-DD'));
		}

		const link = `${apiUrl}/carbon-emissions/download?${params.toString()}`;
		return downloader(link);
	};

	return (
		<MainLayout>
			<title>Goodwings - Compromise free travel</title>
			<Container>
				<Card className={styles.card}>
					<h1>Emissions reports</h1>
					<p>
						Get an overview of your company’s travel emissions with a detailed report. Choose to download
						all emissions data or filter by a specific time period.
					</p>
					<FlexBlock
						alignItems={{
							[breakpoints.zero]: 'unset',
							[breakpoints.tabletWide]: 'flex-end',
						}}
						gap="20px"
						flexDirection={{
							[breakpoints.zero]: 'column',
							[breakpoints.tabletWide]: 'row',
						}}
						className={styles.inputs}
					>
						<Select
							className={styles.select}
							options={rangeValues}
							value={range}
							// @ts-ignore
							onChange={(value: string) => handleRangeChange(value as RangeValue)}
						/>

						<div className={styles.dates}>
							{!isDatesEnabled && <div className={styles.customOverlay} />}
							<DatePicker
								value={startDate}
								onChange={handleStartDateChange}
								label="Start date"
								isDisabled={!isDatesEnabled}
							/>

							<DatePicker
								value={endDate}
								onChange={handleEndDateChange}
								label="End date"
								isDisabled={!isDatesEnabled}
							/>
						</div>
					</FlexBlock>

					<div className={styles.buttons}>
						<Button color="green" onClick={() => onDownlaod('xlsx')}>
							Excel (xlsx)
						</Button>
						<Button color="green" onClick={() => onDownlaod('csv')}>
							CSV
						</Button>
					</div>
				</Card>
			</Container>
		</MainLayout>
	);
};

export default EmissionReports;
