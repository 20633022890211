import React from 'react';
import styles from './Container.module.scss';

const Container: React.FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<div className={styles.container}>
			<div className={styles.childrenWrapper}>{children}</div>
		</div>
	);
};

export default Container;
