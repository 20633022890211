import React, { useCallback, useState } from 'react';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { disableTwoFactor, enableTwoFactor, whoami } from '@/api/auth.ts';
import Button from '@/components/ui/Button/Button.tsx';
import Toast, { ToastProps } from '@/components/ui/Toast/Toast.tsx';
import { QRCodeSVG } from 'qrcode.react';
import googlePlay from '@/assets/Icons/Google-Play-Store-Badge.svg';
import appStore from '@/assets/Icons/Apple-App-Store-Badge.svg';
import styles from './TwoFaAuth.module.scss';
import classNames from 'classnames';
import { externalLinks } from '@/constants/externalLinks';
import { useMutation } from '@tanstack/react-query';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';
import Container from '@/components/ui/Container/Container.tsx';
import FormikPinInput from '@/components/Formik/FormikPinInput.tsx';
import handleResponseErrors from '@/utils/handleResponseErrors.ts';
import { twoFactorSchema } from '@/validations/user.ts';
import EnableUserTwoFaRequest = App.Data.Request.EnableUserTwoFaRequest;

const TwoFaAuth: React.FC = () => {
	const { twoFaEnabled, twoFaUrl, setWhoami } = useWhoamiStore();
	const [toastProps, setToastProps] = useState<ToastProps>({ message: '', type: 'success', show: false });

	const handleToastClose = useCallback(() => {
		setToastProps(prev => ({ ...prev, show: false }));
	}, []);

	const disableTwoFactorMutation = useMutation({
		mutationFn: disableTwoFactor,
		onSuccess: async () => {
			setWhoami(await whoami());
			setToastProps({ message: '2FA Disabled Successfully!', type: 'success', show: true });
		},
		onError: () => {
			setToastProps({ message: 'Failed to disable 2FA.', type: 'error', show: true });
		},
	});

	const handleTwoFactorSubmit = async (
		values: EnableUserTwoFaRequest,
		formikHelpers: FormikHelpers<EnableUserTwoFaRequest>,
	) => {
		const { resetForm } = formikHelpers;

		try {
			await enableTwoFactor(values);

			setWhoami(await whoami());

			setToastProps({ message: '2FA Enabled Successfully!', type: 'success', show: true });

			resetForm();
		} catch (error) {
			handleResponseErrors(error, formikHelpers);
		}
	};

	const formik = useFormik({
		initialValues: {
			twoFaCode: '',
		},
		validationSchema: twoFactorSchema,
		onSubmit: handleTwoFactorSubmit,
	});

	const { isSubmitting } = formik;

	return (
		<Container>
			<div className={styles.twoFactorWrapper}>
				<div className={styles.twoFactorIntro}>
					<h2>Two-Factor Authentication (2FA)</h2>
					<p>
						Secure your account by enabling two-factor authentication. This prevents third-party devices
						from logging into your account <strong>even if they know your password</strong>.
					</p>
					<p>
						Simply log in to your Goodwings account using a two-factor authentication code from your mobile
						phone or tablet.
					</p>
					<p className={styles.statusInfo}>
						Two-factor authentication for your account is currently:{' '}
						<span className={classNames(!twoFaEnabled && styles.disabled)}>
							{twoFaEnabled ? 'Enabled' : 'Disabled'}
						</span>
						.
					</p>
				</div>
				<div className={styles.divider} />
				{!twoFaEnabled ? (
					<div className={styles.qrCodeSection}>
						<p className={styles.stepText}>
							<span>Step 1:</span> Install the Google Authenticator app on your mobile device or tablet:
						</p>
						<div className={styles.appLinks}>
							<a href={externalLinks.googlePlayStore}>
								<img src={googlePlay} alt="Get it on Google Play" />
							</a>
							<a href={externalLinks.appleStore}>
								<img src={appStore} alt="Download on the App Store" />
							</a>
						</div>

						<p className={styles.stepText}>
							<span>Step 2:</span> Scan the following QR code in your Google Authenticator app:
						</p>
						<div className={styles.qrCodeImage}>
							<QRCodeSVG value={twoFaUrl || ''} />
						</div>

						<p className={styles.stepText}>
							<span>Step 3:</span> Enter the code from your Authenticator app:
						</p>

						<FormikProvider value={formik}>
							<Form>
								<FormikPinInput
									align="left"
									size="normal"
									name="twoFaCode"
									length={6}
									allowedChars={/^[0-9]$/}
								/>
								<Button
									type="submit"
									size="large"
									color="green"
									disabled={isSubmitting}
									loading={isSubmitting}
									className={styles.submitButton}
								>
									Enable 2FA
								</Button>
							</Form>
						</FormikProvider>
					</div>
				) : (
					<Button
						type="button"
						size="large"
						color="grey"
						onClick={disableTwoFactorMutation.mutate}
						disabled={disableTwoFactorMutation.isPending}
						loading={disableTwoFactorMutation.isPending}
						className={styles.submitButton}
					>
						Disable 2FA
					</Button>
				)}
				<Toast
					message={toastProps.message}
					type={toastProps.type}
					onClose={handleToastClose}
					show={toastProps.show}
				/>
			</div>
		</Container>
	);
};

export default TwoFaAuth;
