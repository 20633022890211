const queryKeys = {
	getDashboardAnalytics: 'getDashboardAnalytics',
	getEmissionTarget: 'getEmissionTarget',
	getEmployeeOnboardOrganizations: 'employeeOnboardOrganization',
	usersOrganizations: 'usersOrganizations',
	upcomingTrips: 'upcomingTrips',
	suggestedRestaurants: 'suggestedRestaurants',
	suggestedPlacesToRelax: 'suggestedPlacesToRelax',
	transportationToHotel: 'transportationToHotel',
	showRatingModal: 'showRatingModal',
	searchTerm: 'searchTerm',
};

export default queryKeys;
