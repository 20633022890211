const breakpoints = {
	zero: 0,
	phone: 320,
	phoneWide: 480,
	tabletSmall: 640,
	tablet: 768,
	tabletWide: 1024,
	desktop: 1248,
	desktopWide: 1440,
};

export default breakpoints;
