import React from 'react';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import styles from './ModalFooter.module.scss';
import Button from '@/components/ui/Button/Button.tsx';
import ArrowLeft from '@/assets/Icons/Arrow-Left.svg?react';
import ArrowRight from '@/assets/Icons/Arrow-Right.svg?react';
import classNames from 'classnames';

interface ButtonProps {
	hide?: boolean;
	text?: string;
	onClick: () => void;
	disabled?: boolean;
	type?: 'button' | 'submit';
}

interface ModalFooterProps {
	backgroundColor?: string;
	leftButton?: ButtonProps;
	rightButton?: ButtonProps;
	className?: string;
}

const ModalFooter: React.FC<ModalFooterProps> = ({ leftButton = {}, rightButton = {}, className, ...rest }) => {
	const { hide: hideLeft = false, text: textLeft = '', onClick: clickBack = () => {}, ...leftBtnRest } = leftButton;
	const {
		hide: hideRight = false,
		text: textRight = '',
		onClick: clickNext = () => {},
		type: type,
		...rightBtnRest
	} = rightButton;

	return (
		<FlexBlock
			className={classNames(styles.modalFooter, className)}
			justifyContent={hideLeft ? 'flex-end' : 'space-between'}
			{...rest}
		>
			{!hideLeft && (
				<div>
					<Button
						leftIcon={<ArrowLeft />}
						size="large"
						hover={false}
						type={type}
						onClick={clickBack}
						className={styles.leftButton}
						{...leftBtnRest}
					>
						{textLeft}
					</Button>
				</div>
			)}
			{!hideRight && (
				<div>
					<Button
						rightIcon={<ArrowRight />}
						color="green"
						size="large"
						type={type}
						onClick={clickNext}
						{...rightBtnRest}
					>
						{textRight}
					</Button>
				</div>
			)}
		</FlexBlock>
	);
};

export default ModalFooter;
