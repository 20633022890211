import * as Yup from 'yup';
import * as yup from 'yup';
import { countries } from '@/constants/countries.ts';
import { LegalEntity } from '@/api/auth.ts';
import { DateValue } from 'react-aria-components';

export const loginSchema = Yup.object().shape({
	email: Yup.string().email('The email must be a valid email address').required('Email is required'),
	password: Yup.string().required('Password is required'),
	twoFaCode: Yup.string()
		.nullable()
		.matches(/^\d{6}$/, 'The code must be exactly 6 digits'),
});

export const onboardEmployeeSchema = (legalEntities: LegalEntity[]) =>
	Yup.object().shape({
		user: Yup.object().shape({
			firstName: Yup.string()
				.max(25, 'The first name may not be greater than 25 characters')
				.required('First name is required'),
			middleName: Yup.string().max(40, 'The first name may not be greater than 40 characters'),
			lastName: Yup.string()
				.max(40, 'The first name may not be greater than 40 characters')
				.required('Last name is required'),
			email: Yup.string().email('Invalid email format').required('Email is required'),
			password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
			confirmPassword: Yup.string()
				.oneOf([yup.ref('password'), undefined], 'Passwords do not match')
				.required('Password is required'),
			phone: Yup.string().required('Phone number is required'),
			addressLine1: Yup.string().max(64, 'Address is too long'),
			countryCode: Yup.string().required('Country is required'),
			stateProvinceCode: Yup.string().test('conditional-state', 'State is required', function (value) {
				const { countryCode } = this.parent;
				const country = countries.find(c => c.countryCode === countryCode);
				if (country && country.states && Object.keys(country.states).length > 0) {
					return !!value;
				}
				return true;
			}),
		}),
		...(legalEntities.length > 1
			? {
					legalEntityId: Yup.number().required('Legal entity is required'),
				}
			: {}),
	});

const today = new Date();
const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

export const addToCalendarSchema = Yup.object().shape({
	date: Yup.mixed()
		.test('is-valid-date', 'Invalid date', value => {
			if (!value || typeof value !== 'object') return false;

			const { year, month, day } = value as DateValue;
			const parsedDate = new Date(year, month - 1, day);

			// Ensure the date is in the future
			return parsedDate instanceof Date && !isNaN(parsedDate.getTime()) && parsedDate >= minDate;
		})
		.required('Date is required'),
	time: Yup.string().required('Time is required'),
});
