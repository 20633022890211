import React from 'react';
import Modal from '@/components/ui/Modal/Modal.tsx';
import styles from './LegacyUsage.module.scss';
import { Logo } from '@/components/Logo/Logo.tsx';
import { externalLinks } from '@/constants/externalLinks.ts';

const LegacyUsage: React.FC = () => {
	return (
		<Modal name="legacyUsage" closeable={false} outsideClickEnabled={false} className={styles.legacyUsageWrapper}>
			<a href={externalLinks.hubspot} className={styles.logoBttn}>
				<Logo />
			</a>
			<div className={styles.desc}>
				<h1>Welcome back! Are you ready to try the new Goodwings?</h1>
				<p>
					While you were gone we've been working on improving the platform to offer you more powerful
					features, a smoother interface and an even better experience when booking and managing trips.
				</p>
				<p>
					Are you ready to explore the new and improved platform? If so, please reach out to your admin or
					contact our Customer Success team to reactivate your subscription at this email
					service@goodwings.com.
				</p>
			</div>
			<a href="mailto:service@goodwings.com" className={styles.cta}>
				Send email
			</a>
		</Modal>
	);
};

export default LegacyUsage;
