import React from 'react';
import TextArea, { TextAreaProps } from '@/components/ui/TextAreaField/TextArea.tsx';
import { useField } from 'formik';

interface FormikTextAreaProps extends Omit<TextAreaProps, 'value' | 'onChange' | 'errorMessage' | 'touched'> {
	name: string;
}

const FormikTextArea: React.FC<FormikTextAreaProps> = ({ name, ...props }) => {
	const [{ value, onBlur }, { error, touched }, { setValue }] = useField(name);

	return (
		<TextArea
			{...props}
			name={name}
			onBlur={onBlur}
			value={value}
			onChange={e => setValue(e.target.value)}
			errorMessage={error}
			touched={touched}
		/>
	);
};

export default FormikTextArea;
