import { AnalyticsPeriod, initialAnalyticsPeriod } from '@/api/dashboardAnalytics.ts';
import { create } from 'zustand';

export type ContributionType = 'me' | 'department' | 'legalEntity' | 'company';

interface DashboardAnalytics {
	timePeriod: AnalyticsPeriod;
	setTimePeriod: (timePeriod: AnalyticsPeriod) => void;
	contribution: ContributionType;
	setContribution: (contributionType: ContributionType) => void;
}

const initialData: Omit<DashboardAnalytics, 'setTimePeriod' | 'setContribution'> = {
	timePeriod: initialAnalyticsPeriod,
	contribution: 'company',
};

const useDashboardAnalytics = create<DashboardAnalytics>(set => ({
	...initialData,
	setTimePeriod: timePeriod => {
		set({ timePeriod });
	},
	setContribution: contribution => {
		set({ contribution });
	},
}));

export default useDashboardAnalytics;
