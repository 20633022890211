import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { ssoGetUsersOrganization, ssoUserRegister, SsoUserRegisterRequest, whoami } from '@/api/auth.ts';
import { useNavigate } from 'react-router';
import MainLayout from '@/layouts/MainLayout/MainLayout.tsx';
import Container from '@/components/ui/Container/Container.tsx';
import routes from '@/constants/routes.ts';
import Spinner from '@/components/ui/Spinner/Spinner.tsx';
import classNames from 'classnames';
import styles from './SsoRegister.module.scss';
import Button from '@/components/ui/Button/Button.tsx';
import { countries } from '@/constants/countries.ts';
import { Form, Formik, FormikHelpers } from 'formik';
import handleResponseErrors from '@/utils/handleResponseErrors.ts';
import GlobalError from '@/components/FormikErrors/GlobalError.tsx';
import queryKeys from '@/constants/queryKeys.ts';
import { externalLinks } from '@/constants/externalLinks';

const useQueryParams = () => {
	return new URLSearchParams(useLocation().search);
};

const SsoRegister: React.FC = () => {
	const navigate = useNavigate();
	const queryParams = useQueryParams();
	const token = queryParams.get('token');
	const provider = queryParams.get('provider');
	const [step, setStep] = useState(1);
	const [initialValues, setInitialValues] = useState<SsoUserRegisterRequest>({
		organizationId: undefined,
		legalEntityId: undefined,
		token: token || '',
		provider: provider || '',
	});

	const { data, isSuccess, isError, isLoading } = useQuery({
		queryKey: [queryKeys.usersOrganizations],
		queryFn: () => ssoGetUsersOrganization({ provider: provider || '', token: token || '' }),
		enabled: !!token && !!provider,
	});

	useEffect(() => {
		if (isSuccess && !data?.organizations?.length) {
			navigate(routes.noOrganization);
		}

		if (isError) {
			navigate(routes.authError);
		}

		if (data?.organizations?.length === 1 && data?.organizations[0]?.legalEntities?.length === 1) {
			setInitialValues(prev => ({
				...prev,
				organizationId: data.organizations[0].id,
				legalEntityId: data.organizations[0].legalEntities[0].id,
			}));
			handleSubmit(initialValues, {} as FormikHelpers<SsoUserRegisterRequest>);
		}

		if (data?.organizations?.length === 1 && data?.organizations[0]?.legalEntities?.length > 1) {
			setInitialValues(prev => ({
				...prev,
				organizationId: data.organizations[0].id,
			}));
			setStep(2);
		}
	}, [data, isSuccess, isError]);

	const handleNext = (values: SsoUserRegisterRequest, formikHelpers: FormikHelpers<SsoUserRegisterRequest>) => {
		if (data?.organizations) {
			const selectedOrgIndex = data.organizations.findIndex(org => org.id === values.organizationId);
			if (step === 1) {
				if (data.organizations[selectedOrgIndex].legalEntities.length <= 1) {
					const updatedValues = {
						...values,
						organizationId: data.organizations[selectedOrgIndex]?.id,
						legalEntityId: data.organizations[selectedOrgIndex]?.legalEntities[0]?.id,
					};

					return handleSubmit(updatedValues, formikHelpers);
				} else {
					setStep(2);
				}
			} else {
				return handleSubmit(values, formikHelpers);
			}
		} else {
			return;
		}
	};

	const handleSubmit = async (
		values: SsoUserRegisterRequest,
		formikHelpers: FormikHelpers<SsoUserRegisterRequest>,
	) => {
		try {
			await ssoUserRegister(values);
			await whoami();
			window.location.href = externalLinks.spotnana.book;
		} catch (error) {
			handleResponseErrors(error, formikHelpers);
		}
	};

	if (
		isLoading ||
		(isSuccess && data?.organizations?.length === 1 && data?.organizations[0]?.legalEntities?.length === 1)
	) {
		return (
			<MainLayout>
				<Spinner />
			</MainLayout>
		);
	}

	return (
		<MainLayout>
			<Container>
				<Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize={true}>
					{form => (
						<Form className={styles.contentWrapper}>
							{step === 1 && (
								<>
									<h3>Choose your organization</h3>
									<GlobalError />
									<div>
										{data?.organizations?.map(({ name, countryCode, id }) => {
											const hasCountryCode = !!countryCode;
											return (
												<div key={id}>
													<div
														onClick={() => form.setFieldValue('organizationId', id)}
														className={classNames(
															styles.option,
															form.values.organizationId === id && styles.selected,
														)}
													>
														{name}
														{hasCountryCode &&
															` (${countries.find(country => country.countryCode === countryCode)?.name})`}
													</div>
												</div>
											);
										})}
									</div>
								</>
							)}
							{step === 2 && (
								<>
									<h3>Choose legal entity</h3>
									<GlobalError />
									<div>
										{data?.organizations
											?.find(
												organization =>
													organization.id === form.values.organizationId ||
													initialValues.organizationId,
											)
											?.legalEntities.map(({ name, id }) => {
												return (
													<div key={id}>
														<div
															onClick={() => form.setFieldValue('legalEntityId', id)}
															className={classNames(
																styles.option,
																form.values.legalEntityId === id && styles.selected,
															)}
														>
															{name}
														</div>
													</div>
												);
											})}
									</div>
								</>
							)}
							<div className={styles.buttonsWrapper}>
								{data && data?.organizations?.length > 1 && step !== 1 && (
									<Button size="large" onClick={() => setStep(1)}>
										Back
									</Button>
								)}
								<Button
									onClick={() => handleNext(form.values, form)}
									size="large"
									color="green"
									loading={form.isSubmitting}
									disabled={
										(step === 1 && !form.values.organizationId) ||
										(step === 2 && !form.values.legalEntityId) ||
										form.isSubmitting
									}
								>
									Continue
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</Container>
		</MainLayout>
	);
};

export default SsoRegister;
