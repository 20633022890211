import { createBrowserRouter, Navigate } from 'react-router-dom';
import { RouterProvider } from 'react-router';
import Dashboard from '@/pages/Dashboard/Dashboard.tsx';
import { FC, ReactElement } from 'react';
import Spinner from '@/components/ui/Spinner/Spinner.tsx';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';
import Logout from '@/pages/Authentication/Logout/Logout.tsx';
import Error404 from '@/pages/errors/Error404/Error404.tsx';
import routes from '@/constants/routes.ts';
import Login from '@/pages/Authentication/Login/Login.tsx';
import ForgotPassword from '@/pages/Authentication/ForgotPassword/ForgotPassword.tsx';
import ResetPassword from '@/pages/Authentication/ResetPassword/ResetPassword.tsx';
import SsoRegister from '@/pages/Authentication/SsoRegister/SsoRegister.tsx';
import NoOrganization from '@/pages/Authentication/NoOrganization/NoOrganization.tsx';
import AuthError from '@/pages/Authentication/AuthError/AuthError.tsx';
import EmployeeOnboard from '@/pages/Authentication/EmployeeOnboard/EmployeeOnboard.tsx';
import SpotnanaRedirection from '@/pages/SpotnanaRedirection/SpotnanaRedirection.tsx';
import ActivateAccount from '@/pages/Authentication/ActivateAccount/ActivateAccount.tsx';
import EmissionReports from '@/pages/EmissionReports/EmissionReports';
import { externalLinks } from '@/constants/externalLinks.ts';
import ThankYouForFeedback from '@/pages/ThankYouForFeedback/ThankYouForFeedback.tsx';
import usePersistentPreferencesStore from '@/store/usePersistentPreferencesStore.ts';
import SecuritySettings from '@/pages/Authentication/SecuritySettings/SecuritySettings.tsx';
import { Roles, User } from '@/api/user.ts';

interface Route {
	path: string;
	element?: ReactElement;
	roles?: Roles[];
}

/**
 * Routes that will be shown to both guest and authenticated users
 */
const commonRoutes: Route[] = [
	{ path: '/user', element: <Navigate to={routes.dashboard} /> }, // TODO - remove this after release
	{ path: routes.logout, element: <Logout /> },
	{ path: '*', element: <Error404 /> },
	{ path: routes.thankYouForFeedback, element: <ThankYouForFeedback /> },
];

/**
 * Routes that will be shown to authenticated users only
 */
const authenticatedRoutes: Route[] = [
	{ path: routes.dashboard, element: <Dashboard /> },
	{ path: routes.spotnanaRedirection, element: <SpotnanaRedirection /> },
	{ path: routes.securitySettings, element: <SecuritySettings /> },
	{
		path: routes.emissionReports,
		element: <EmissionReports />,
		roles: [Roles.CompanyAdmin, Roles.TmcAdmin, Roles.TmcAgent],
	},
];

/**
 * Routes that will be shown to both guests only
 */
const guestRoutes: Route[] = [
	{ path: routes.login, element: <Login /> },
	{ path: routes.forgotPassword, element: <ForgotPassword /> },
	{ path: routes.resetPassword, element: <ResetPassword /> },
	{ path: routes.ssoRegister, element: <SsoRegister /> },
	{ path: routes.noOrganization, element: <NoOrganization /> },
	{ path: routes.authError, element: <AuthError /> },
	{ path: routes.employeeOnboard, element: <EmployeeOnboard /> },
	{ path: routes.activateAccount, element: <ActivateAccount /> },
];

const LoginRedirection: FC<{ returnPath: string }> = ({ returnPath }) => {
	const { setReturnPath } = usePersistentPreferencesStore();

	setReturnPath(returnPath);

	return <Navigate to={routes.login} />;
};

// Get all routes based on loggedIn state
const createRoutes = (loggedIn: boolean, userRole: User['spotnanaRole']): Route[] => {
	return [
		...commonRoutes,
		...authenticatedRoutes.map(route => {
			if (
				route.roles &&
				((userRole && !route.roles.includes(userRole)) || userRole === null || userRole === undefined)
			) {
				// User doesn't have permission for this route, redirect to dashboard
				return {
					...route,
					element: <Error404 />,
				};
			}

			return {
				...route,
			    element: loggedIn ? route.element : <LoginRedirection returnPath={route.path} />,
			};
		}),
		...guestRoutes.map(route => ({
			...route,
			element: !loggedIn ? route.element : <Navigate to={routes.dashboard} key={route.path} />,
		})),
	];
};

const Router = () => {
	const loggedIn = useWhoamiStore(state => state.loggedIn);
	const userRole = useWhoamiStore(state => state.user?.spotnanaRole);
	const bookingEngine = useWhoamiStore(state => state.bookingEngine);
	const { returnPath, clearReturnPath } = usePersistentPreferencesStore();

	// If the login state is not determined yet
	if (loggedIn === undefined) {
		return <Spinner />;
	}

	if (loggedIn && returnPath) {
		window.location.href = returnPath;
		clearReturnPath();
	}

	// If the logged-in user is not using Spotnana, redirect back to the legacy platform
	if (loggedIn && bookingEngine !== 'spotnana') {
		const currentPath = window.location.pathname;
		const queryString = window.location.search;

		window.location.href = `${externalLinks.goodwingsLegacy.root}${currentPath}${queryString}`;

		// Retain the spinner while the redirection is happening
		return <Spinner />;
	}

	// Get all routes, conditionally based on the loggedIn state
	const routes = createRoutes(loggedIn, userRole || null);

	// Create router
	const router = createBrowserRouter(routes);

	return <RouterProvider router={router} />;
};

export default Router;
