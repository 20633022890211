import React, { useCallback, useState } from 'react';
import MainLayout from '@/layouts/MainLayout/MainLayout.tsx';
import Container from '@/components/ui/Container/Container.tsx';
import ChangePassword from './ChangePassword';
import TwoFaAuth from './TwoFaAuth';
import styles from './SecuritySettings.module.scss';
import Toast, { ToastProps } from '@/components/ui/Toast/Toast.tsx';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';

const SecuritySettings: React.FC = () => {
	const [toastProps, setToastProps] = useState<ToastProps>({ message: '', type: 'success', show: false });
	const handleToastClose = useCallback(() => {
		setToastProps(prev => ({ ...prev, show: false }));
	}, []);

	return (
		<MainLayout>
			<div className={styles.securitySettingsWrapper}>
				<Container>
					<FlexBlock flexDirection="column" rowGap="40px">
						<ChangePassword />
						<TwoFaAuth />
					</FlexBlock>
				</Container>
			</div>
			<Toast
				message={toastProps.message}
				type={toastProps.type}
				onClose={handleToastClose}
				show={toastProps.show}
			/>
		</MainLayout>
	);
};

export default SecuritySettings;
