import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './AutocompletePlaceInput.module.scss';
import Spinner from '@/components/ui/Spinner/Spinner.tsx';
import { AutocompletePlaceOption } from '@/api/search.ts';
import { useDebounce } from '@uidotdev/usehooks';
import { stripHtml } from 'string-strip-html';

type Value = string | number | undefined;

export interface AutocompletePlaceInputProps {
	className?: string;
	label?: string;
	placeholder?: string;
	value: string;
	onChange: (newValue: Value) => void;
	fetchFn: (query: string) => Promise<AutocompletePlaceOption[]>;
	maxLength?: number;
	errorMessage?: string;
	touched?: boolean;
	min?: number;
	disabled?: boolean;
	onBlur?: React.FocusEventHandler | undefined;
	name?: string;
	ref?: React.Ref<HTMLInputElement>;
}

const AutocompletePlaceInput: React.FC<AutocompletePlaceInputProps> = ({
	className,
	label,
	placeholder,
	onChange,
	fetchFn,
	maxLength,
	errorMessage,
	touched,
	min,
	disabled = false,
	onBlur,
	name,
	ref,
	value,
}) => {
	// Reference for detecting clicks outside the input wrapper, to close the dropdown
	const wrapperRef = useRef<HTMLDivElement>(null);

	// State to store the user input and debounced version of it (to prevent fetching on every keystroke)
	const [inputValue, setInputValue] = useState('');
	const debouncedInputValue = useDebounce(inputValue.trim(), 500); // Debounce the input value by 500ms

	const [loading, setLoading] = useState(false);
	const [showDropdown, setShowDropdown] = useState(false);
	const [suggestedOptions, setSuggestedOptions] = useState<AutocompletePlaceOption[]>([]);

	// Function to fetch suggestions based on the debounced input value
	const fetchOptions = useCallback(() => {
		// If there is already a component-value, skip fetching it
		if (value) {
			return;
		}

		// If the empty value is provided, show nothing
		if (!debouncedInputValue) {
			setSuggestedOptions([]);
			setShowDropdown(false);
			setLoading(false);

			return;
		}

		setLoading(true);

		// Fetch suggestions and update dropdown options
		fetchFn(debouncedInputValue)
			.then(options => {
				setSuggestedOptions(options);
				setShowDropdown(true);
			})
			.catch(() => {
				setSuggestedOptions([]);
				setShowDropdown(false);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [debouncedInputValue, value, fetchFn]);

	// Handle click events outside the wrapper to close the dropdown
	const handleClickOutside = useCallback((event: MouseEvent) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
			setShowDropdown(false);
		}
	}, []);

	const handleChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			// Reset the value of the whole component, on every input change
			onChange(undefined);

			// Set the value of the input field
			setInputValue(event.target.value);
		},
		[onChange],
	);

	// Handle the selection of an option from the dropdown
	const handleSelectOption = (selectedOption: AutocompletePlaceOption) => {
		// Set the value of the whole component to the selected item
		onChange(selectedOption.id);

		setShowDropdown(false);

		// Strip HTML from the name and update the input value with name and region
		const plainTextName = stripHtml(selectedOption.name).result;
		setInputValue([plainTextName, selectedOption.region].filter(Boolean).join(', '));
	};

	useEffect(() => {
		// Fetch options when the debounced input value changes
		fetchOptions();
	}, [debouncedInputValue]);

	useEffect(() => {
		// Clear the input value when the dropdown is closed and component value is not present
		if (!showDropdown && !value) {
			setInputValue('');
		}
	}, [showDropdown]);

	// Add and remove the event listener for detecting outside clicks
	useEffect(() => {
		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [handleClickOutside]);

	return (
		<div className={classNames(styles.wrapper, className)} ref={wrapperRef}>
			{!!label && <span className={styles.label}>{label}</span>}
			<div className={styles.inputWrapper}>
				<input
					ref={ref}
					name={name}
					type="text"
					placeholder={placeholder}
					value={inputValue}
					onChange={handleChange}
					maxLength={maxLength}
					min={min}
					disabled={disabled}
					onBlur={onBlur}
					autoComplete="off"
				/>
				{!!errorMessage && touched && <span>{errorMessage}</span>}
				{loading && (
					<span className={styles.spinnerWrapper}>
						<Spinner absolute />
					</span>
				)}
				{showDropdown && suggestedOptions.length > 0 && (
					<ul className={styles.dropdown}>
						{suggestedOptions.map((option: AutocompletePlaceOption) => {
							return (
								<li
									key={option.id}
									onClick={() => handleSelectOption(option)}
									className={styles.option}
									dangerouslySetInnerHTML={{
										__html: [option.name, option.region].filter(Boolean).join(', '),
									}}
								/>
							);
						})}
					</ul>
				)}
			</div>
		</div>
	);
};

export default AutocompletePlaceInput;
