import React from 'react';
import styles from './SelectFiscalDate.module.scss';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import SelectHeader from '@/pages/Dashboard/EmissionTargetModal/SelectHeader.tsx';
import CalendarIcon from '@/assets/Illustrations/Calendar-Full.svg?react';
import FiscalCalendarImage from '@/assets/Images/Fiscal-Calendar.png';
import FormikCalendarPicker from '@/components/Formik/FormikCalendarPicker.tsx';

const SelectFiscalDate: React.FC = () => {
	return (
		<div className={styles.content}>
			<div className={styles.calendarWrapper}>
				<FlexBlock flexDirection="column">
					<SelectHeader
						icon={<CalendarIcon />}
						title="Choose your fiscal dates"
						text="When does your fiscal year start?"
					/>
					<FlexBlock alignItems="center" className={styles.datePicker}>
						<FormikCalendarPicker name='fiscalYearStart' label="FISCAL YEAR START"  />
					</FlexBlock>
				</FlexBlock>
				<FlexBlock justifyContent="flex-end" className={styles.imageWrapper}>
					<img src={FiscalCalendarImage} alt="Fiscal Calendar" />
				</FlexBlock>
			</div>
		</div>
	);
};

export default SelectFiscalDate;
