import React from 'react';
import styles from './SelectPeriod.module.scss';
import SelectHeader from '@/pages/Dashboard/EmissionTargetModal/SelectHeader.tsx';
import CalendarIcon from '@/assets/Illustrations/Calendar-Full.svg?react';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import Button from '@/components/ui/Button/Button.tsx';
import { useEmissionTargetStore } from '@/store/useEmissionTargetStore.ts';
import { useFormikContext } from 'formik';
import { EmissionTargetValues } from '@/pages/Dashboard/EmissionTargetModal/EmissionTargetModal.tsx';
import { EmissionTargetPeriod } from '@/api/emissionTarget.ts';
import breakpoints from '@/constants/breakpoints.ts';
import ArrowRight from '@/assets/Icons/Arrow-Right.svg?react';

const SelectPeriod: React.FC = () => {
	const { setFieldValue } = useFormikContext<EmissionTargetValues>();
	const setPeriod = useEmissionTargetStore(state => state.setPeriod);

	const handleSetPeriod = (period: EmissionTargetPeriod) => {
		setPeriod(period);
		setFieldValue('yearType', period);
	};

	return (
		<div className={styles.content}>
			<SelectHeader
				icon={<CalendarIcon />}
				title="Choose a time period"
				text="Choosing the right reporting period is crucial for accurate and relevant emissions tracking. Align your emissions reporting with the time frame that best matches your business operations and financial reporting."
			/>
			<FlexBlock
				flexDirection={{
					[breakpoints.zero]: 'column',
					[breakpoints.tablet]: 'row',
				}}
				className={styles.boxesWrapper}
			>
				<div className={styles.modalBox}>
					<div>
						<h2>Fiscal year</h2>
						<p>
							If your environmental impact initiatives and financial performance are closely linked,
							consider the fiscal year.
						</p>
						<p>
							This ensures your emissions reporting aligns with your company's financial cycles, making it
							easier to integrate environmental performance in annual reports and strategy reviews.
						</p>
					</div>
					<div>
						<Button
							fullWidth
							color="green"
							size="large"
							rightIcon={<ArrowRight />}
							onClick={() => handleSetPeriod('fiscal')}
						>
							Choose fiscal year
						</Button>
					</div>
				</div>
				<div className={styles.modalBox}>
					<div>
						<h2>Calendar year</h2>
						<p>
							Opt for the calendar year if your business operations are impacted by seasonal variations or
							if it simplifies coordination with external reporting requirements and benchmarks.
						</p>
						<p>
							Budgeting by calendar year allows for straightforward year-on-year comparisons and may align
							better with external environmental reporting frameworks.
						</p>
					</div>
					<div>
						<Button
							fullWidth
							color="green"
							size="large"
							rightIcon={<ArrowRight />}
							onClick={() => handleSetPeriod('calendar')}
						>
							Choose calendar year
						</Button>
					</div>
				</div>
			</FlexBlock>
		</div>
	);
};

export default SelectPeriod;
