import { useEffect, useState } from 'react';
import styles from './QuestionList.module.scss';
import Card from '@/components/ui/Card/Card';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock';

//assets
import DoneCheckIcon from '@/assets/Icons/Done-Checkmark.svg?react';

const QuestionsList: React.FC<{
	options: string[];
	answer: string;
	timeRunOut: boolean;
	setIsCorrect: (questionState: 'true' | 'false') => void;
}> = ({ options, answer, setIsCorrect, timeRunOut = false }) => {
	const [selected, setSelected] = useState<string>('');

	const onSelect = (selectedAnswer: string) => {
		if (selected.length === 0 && !timeRunOut) {
			setSelected(selectedAnswer);
			setIsCorrect(selectedAnswer === answer ? 'true' : 'false');
		}
	};

	const isCorrect = answer === selected;
	const rightAnswer = (text: string) =>
		(text === answer && selected !== '') || (selected === '' && timeRunOut && text === answer);

	useEffect(() => {
		setSelected('');
	}, [answer]);

	return (
		<FlexBlock className={styles.optionsRow} flexDirection="column" flexWrap="nowrap">
			{options.map((text, index) => {
				const isCorrectClass = selected === text && isCorrect ? styles.isTrue : '';
				const rightAnswerClass = rightAnswer(text) ? styles.isRightAnswer : '';
				const isNotFalse = selected === text && !isCorrect ? styles.isFalse : '';
				const classNames = `${styles.optionItem} ${isCorrectClass} ${rightAnswerClass} ${isNotFalse}`;
				return (
					<Card
						key={index + text}
						className={`${styles.option} ${selected === text && isCorrect ? styles.isTrue : ''} ${selected === text && !isCorrect ? styles.isFalse : ''} ${rightAnswer(text) ? styles.isRightAnswer : ''}`}
						onClick={() => onSelect(text)}
					>
						<div className={classNames}>{index + 1}</div>
						<div className={classNames}>{text}</div>
						{selected === text && isCorrect && (
							<div className={styles.iconWrapper}>
								<DoneCheckIcon />
							</div>
						)}
					</Card>
				);
			})}
		</FlexBlock>
	);
};

export default QuestionsList;
