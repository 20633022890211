import Card from '@/components/ui/Card/Card';
import styles from './Contribution.module.scss';
import useDashboardAnalytics, { ContributionType } from '@/store/useDashboardAnalytics.ts';
import { DashboardAnalyticsData } from '@/api/dashboardAnalytics.ts';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock';
import Modal from '@/components/ui/Modal/Modal';
import { useModalStore } from '@/store/useModalStore';
import ContributionList from './ContributionList';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import Overlay from '@/components/ui/Overlay/Overlay';
import { FC } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

interface ContributionProps {
	queryAnalytics: UseQueryResult<DashboardAnalyticsData | null, unknown>;
}

export type Title = {
	value: ContributionType;
	label: string;
};

const titles: Title[] = [
	{
		value: 'me',
		label: 'My trips',
	},
	{
		value: 'department',
		label: 'Employees in department',
	},
	{
		value: 'legalEntity',
		label: 'Departments',
	},
	{
		value: 'company',
		label: 'Legal Entities',
	},
];

export interface UnifiedBreakdown {
	title: string;
	subtitle: string;
	co2eTotal: number;
}

export const mapBreakdownData = (
	data: DashboardAnalyticsData['breakdown'],
	contribution: ContributionType,
): UnifiedBreakdown[] => {
	if (!data) {
		return [];
	}
	switch (contribution) {
		case 'me':
			return data.me.map(item => ({
				title: item.itineraryName,
				subtitle: moment(item.date).format('MMM D, YYYY'),
				co2eTotal: item.co2eTotal ? +item.co2eTotal.toFixed(2) : 0,
			}));
		case 'department':
			return data.department.map(item => ({
				title: item.userFullName,
				subtitle: item.department,
				co2eTotal: item.co2eTotal ? +item.co2eTotal.toFixed(2) : 0,
			}));
		case 'legalEntity':
			return data.legalEntity.map(item => ({
				title: item.department,
				subtitle: `User Count: ${item.userCount}`,
				co2eTotal: item.co2eTotal ? +item.co2eTotal.toFixed(2) : 0,
			}));
		case 'company':
			return data.company.map(item => ({
				title: item.legalEntity,
				subtitle: `User Count: ${item.userCount}`,
				co2eTotal: item.co2eTotal ? +item.co2eTotal.toFixed(2) : 0,
			}));
		default:
			return [];
	}
};

const Contribution: FC<ContributionProps> = ({ queryAnalytics }) => {
	const { contribution } = useDashboardAnalytics();
	const { data, isFetching, isError } = queryAnalytics;

	const title = titles.find(({ value }) => value === contribution);

	const breakdownData: UnifiedBreakdown[] = data ? mapBreakdownData(data.breakdown, contribution) : [];

	const shortBreakdownData = breakdownData?.slice(0, 4);

	const showModalBreakDownDataLink = breakdownData?.length > 4;
	const openModal = useModalStore(state => state.openModal);

	if (isFetching) {
		return <Skeleton borderRadius="10px" className={styles.contribution} />;
	}

	return (
		<Card className={styles.contribution}>
			{!isError && <ContributionList data={shortBreakdownData} title={title} />}
			{showModalBreakDownDataLink && (
				<FlexBlock justifyContent="flex-end">
					<a className={styles.moreLink} onClick={() => openModal('contributionModal')}>
						See all
					</a>
				</FlexBlock>
			)}

			<Modal className={styles.modal} name="contributionModal">
				<ContributionList data={breakdownData} title={title} />
			</Modal>

			{isError && <Overlay subtext="No Data Available" />}
		</Card>
	);
};

export default Contribution;
