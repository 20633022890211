import axios, { isAxiosError } from 'axios';
import { useModalStore } from '@/store/useModalStore.ts';

/**
 * Represents the structure of an error response from the server.
 */
export type ErrorResponse = {
	code: number;
	type: string;
	message?: string;
	data?: {
		fields?: Record<string, string[]>;
	};
};

export const setupAxios = () => {
	axios.defaults.baseURL = import.meta.env.VITE_API_URL;

	// Allow sending credentials (cookies) with requests
	axios.defaults.withCredentials = true;

	const getCsrfToken = async () => {
		await axios.get('/csrf');
	};

	// Request interceptor to add CSRF token to request headers
	axios.interceptors.request.use(
		async config => {
			// Extract the CSRF token from cookies
			const xsrfToken = document.cookie
				.split('; ')
				.find(row => row.startsWith('XSRF-TOKEN='))
				?.split('=')[1];

			// Add XSRF Token only if Goodwings API url
			if (xsrfToken && config.baseURL === axios.defaults.baseURL) {
				config.headers['x-xsrf-token'] = decodeURIComponent(xsrfToken);
			}

			return config;
		},
		error => Promise.reject(error),
	);

	axios.interceptors.response.use(
		response => response,
		async error => {
			const config = error.config;

			// Check if the error is due to expired CSRF token (error 419)
			if (error.response && error.response.status === 419) {
				config._retryCount = config._retryCount || 0;

				// Retry the request up to 3 times
				if (config._retryCount < 3) {
					config._retryCount += 1;

					await getCsrfToken();

					return axios(config);
				}
			}

			// Handle 504 error
			if (error.response && error.response.status === 504) {
				config._retryCount = config._retryCount || 0;

				// Retry the request up to 3 times
				if (config._retryCount < 3) {
					config._retryCount += 1;

					return axios(config);
				}

				//TODO - add modal here
			}

			// Handle 410 error - legacy usage
			if (isErrorMatching(error, 'LegacyPlatformUsageException', 410)) {
				useModalStore.getState().openModal('legacyUsage');
			}

			return Promise.reject(error);
		},
	);
};

/**
 * Checks if the error object has a specific error type and an optional status code.
 *
 * @param err - The error object to check.
 * @param errorType - The error type to match.
 * @param statusCode - The optional status code to match (default: null).
 * @returns True if the error matches the error type and optional status code, false otherwise.
 */
export const isErrorMatching = (err: unknown, errorType: string, statusCode: number | null = null): boolean => {
	if (isAxiosError<ErrorResponse>(err)) {
		const errorStatusCode = err.response?.status || null;
		const responseType = err.response?.data?.type || null;

		return responseType === errorType && (statusCode === null || errorStatusCode === statusCode);
	}

	return false;
};
