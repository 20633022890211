import React, { useEffect, useState } from 'react';
import { getCountries, getPhoneCode } from 'libphonenumber-js';
import { countries } from '@/constants/countries.ts';
import ArrowDown from '@/assets/Icons/Arrow-Down.svg?react';
import styles from './PhoneInput.module.scss';

export interface PhoneInputProps {
	label?: string;
	defaultCountry?: string;
	className?: string;
	errorMessage?: string;
	touched?: boolean;
	disabled?: boolean;
	name?: string;
	value: string;
	onChange: (value: string) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
	label = 'Phone number',
	defaultCountry = 'DK',
	className,
	errorMessage,
	touched,
	disabled = false,
	name,
	value,
	onChange,
	onBlur,
}) => {
	const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
	const [inputValue, setInputValue] = useState('');

	const phoneCountries = getCountries()
		.map(code => ({
			code,
			phoneCode: getPhoneCode(code),
			name: countries.find(country => country.countryCode === code)?.name,
			flag: `https://purecatamphetamine.github.io/country-flag-icons/3x2/${code.toUpperCase()}.svg`,
		}))
		.filter(country => country.name);

	const selectedCountryData = phoneCountries.find(country => country.code === selectedCountry);

	useEffect(() => {
		if (value) {
			const country = phoneCountries.find(c => value.startsWith(`+${c.phoneCode}`));
			if (country) {
				setSelectedCountry(country.code);
				setInputValue(value.slice(country.phoneCode.length + 1));
			} else {
				setInputValue(value);
			}
		}
	}, [value]);

	const handleCountryChange = (code: string) => {
		setSelectedCountry(code);
		const newValue = `+${phoneCountries.find(c => c.code === code)?.phoneCode || ''}${inputValue}`;
		onChange(newValue);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newInputValue = e.target.value.replace(/\D/g, '');
		setInputValue(newInputValue);
		const newValue = `+${selectedCountryData?.phoneCode || ''}${newInputValue}`;
		onChange(newValue);
	};

	return (
		<div className={className}>
			{label && <span>{label}</span>}
			<div className={styles.phoneFieldWrapper}>
				<div className={styles.flagCountryCodeSelectWrapper}>
					{selectedCountryData && (
						<img src={selectedCountryData.flag} alt={`${selectedCountryData.name} flag`} />
					)}
					<div className={styles.customSelectWrapper}>
						<select
							value={selectedCountry}
							onChange={e => handleCountryChange(e.target.value)}
							className={styles.customSelect}
							disabled={disabled}
						>
							{phoneCountries.map(country => (
								<option key={country.code} value={country.code}>
									{country.name} (+{country.phoneCode})
								</option>
							))}
						</select>
						<div className={styles.selectedOption}>+{selectedCountryData?.phoneCode}</div>
						<ArrowDown />
					</div>
				</div>
				<input
					id="phone-input"
					type="tel"
					placeholder="Enter your phone number"
					className={styles.phoneInput}
					value={inputValue}
					onChange={handleInputChange}
					onBlur={onBlur}
					disabled={disabled}
					name={name}
				/>
			</div>
			{!!errorMessage && touched && <div className={styles.error}>{errorMessage}</div>}
		</div>
	);
};

export default PhoneInput;
