import { FC } from 'react';
import { useField } from 'formik';
import Input, { InputProps } from '@/components/ui/Input/Input';

interface FormikInputProps extends Omit<InputProps, 'value' | 'onChange' | 'errorMessage' | 'touched'> {
	name: string;
}

const FormikInput: FC<FormikInputProps> = ({ name, ...props }) => {
	const [{ value, onBlur }, { error, touched }, { setValue }] = useField(name);

	return (
		<Input
			{...props}
			name={name}
			onBlur={onBlur}
			value={value}
			onChange={newValue => setValue(newValue)}
			errorMessage={error}
			touched={touched}
		/>
	);
};

export default FormikInput;
