import React, { useEffect } from 'react';
import Dashboard from '@/pages/Dashboard/Dashboard.tsx';
import RatingModal from '@/components/RatingModal/RatingModal.tsx';
import { useModalStore } from '@/store/useModalStore.ts';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';
import Login from '@/pages/Authentication/Login/Login.tsx';
import styles from './ThankYouForFeedback.module.scss';

const ThankYouForFeedback: React.FC = () => {
	const { openModal, activeModal } = useModalStore();
	const { loggedIn } = useWhoamiStore();

	useEffect(() => {
		openModal('ratingModal');
	}, []);

	return (
		<>
			{loggedIn ? <Dashboard /> : <Login />}
			{activeModal && (
				<div className={styles.modalWrapper}>
					<RatingModal showThankYou />
				</div>
			)}
		</>
	);
};

export default ThankYouForFeedback;
