import styles from './PinInput.module.scss';
import { FC, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PinField from 'react-pin-field';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';

export interface PinInputProps {
	allowedChars?: string | RegExp | ((key: string) => boolean);
	className?: string;
	length: number;
	value: string;
	disabled?: boolean;
	onChange: (newValue: string) => void;
	autoFocus?: boolean;
	error?: string;
	align?: 'left' | 'center';
	size?: 'normal' | 'large';
}

const PinInput: FC<PinInputProps> = ({
	allowedChars = /.*/,
	className,
	length,
	value,
	disabled,
	onChange,
	autoFocus = true,
	error,
	align = 'center',
	size = 'normal',
}) => {
	const inputsRef = useRef<HTMLInputElement[] | null>([]);

	useEffect(() => {
		if (!value) {
			// Clear each input if the value is empty
			inputsRef.current?.forEach(input => {
				if (input) input.value = '';
			});
			return;
		}

		// Update each input with the corresponding character from the value
		inputsRef.current?.forEach((input, index) => {
			if (input) {
				input.value = value[index] || ''; // Ensure undefined characters are not set
			}
		});
	}, [value]);

	const wrapperClassnames = classNames(
		styles.wrapper,
		disabled && styles.disabled,
		!!error && styles.hasError,
		align === 'left' && styles.leftAlign,
		size === 'large' && styles.large,
		className,
	);

	const handleChange = useCallback(
		(code: string) => {
			onChange(code); // Always update the value
		},
		[onChange],
	);

	const handleComplete = useCallback(
		(code: string) => {
			onChange(code); // Call onChange when the full code is entered
		},
		[onChange],
	);

	return (
		<FlexBlock flexDirection="column" className={wrapperClassnames}>
			<FlexBlock columnGap="10px" className={styles.pin}>
				<PinField
					disabled={disabled}
					validate={allowedChars}
					ref={inputsRef}
					autoFocus={autoFocus}
					length={length}
					onComplete={handleComplete}
					onChange={handleChange}
				/>
			</FlexBlock>
			{!!error && <span className={styles.error}>{error}</span>}
		</FlexBlock>
	);
};

export default PinInput;
