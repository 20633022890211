import React, { ReactNode, useEffect } from 'react';
import styles from './WelcomeFinishModal.module.scss';
import { useReward } from 'react-rewards';
import Button, { ButtonProps } from '@/components/ui/Button/Button.tsx';

interface ButtonPropsExtended extends Omit<ButtonProps, 'children'> {
	text: string;
}

export interface Paragraph {
	title?: string;
	desc?: string;
}

export interface ModalData {
	paragraphs: Paragraph[];
}

interface WelcomeFinishModalProps {
	buttonProp: ButtonPropsExtended;
	img: ReactNode;
	text: ModalData;
	showConfetti?: boolean;
}

const RenderParagraph: React.FC<{ paragraphs: Paragraph[] }> = ({ paragraphs }) => {
	return (
		<div className={styles.paragraphWrapper}>
			{paragraphs.map(({ title, desc }, key) => (
				<React.Fragment key={key}>
					{title && <h4>{title}</h4>}
					{desc && <p>{desc}</p>}
				</React.Fragment>
			))}
		</div>
	);
};

const WelcomeFinishModal: React.FC<WelcomeFinishModalProps> = ({ img, text, buttonProp, showConfetti }) => {
	const { reward: confettiReward } = useReward('confettiReward', 'confetti', {
		lifetime: 500,
		elementCount: 100,
		angle: 60,
	});

	useEffect(() => {
		if (showConfetti) {
			confettiReward();
		}
	}, [showConfetti]);

	return (
		<div className={styles.contentWrapper}>
			<div>
				<span id="confettiReward"></span>
				{img}
			</div>
			<RenderParagraph paragraphs={text.paragraphs} />
			<Button {...buttonProp}>{buttonProp.text}</Button>
		</div>
	);
};

export default WelcomeFinishModal;
