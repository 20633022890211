const getBrowserEngine = (): 'WebKit' | 'Blink' | 'Other' => {
	const ua = window.navigator.userAgent;

	if (ua.includes('AppleWebKit') && !ua.includes('Edge')) {
		return 'WebKit';
	}
	if (ua.includes('Chrome') || ua.includes('Chromium') || ua.includes('Edge')) {
		return 'Blink';
	}
	return 'Other';
};

const useDownloadFile = () => {
	const browserEngine = getBrowserEngine();

	return {
		downloader: (url: string): Promise<void> =>
			new Promise(resolve => {
				if (browserEngine === 'WebKit' || browserEngine === 'Blink') {
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('target', '_blank');

					if (typeof link.download !== 'undefined') {
						const fileName = url.substring(url.lastIndexOf('/') + 1);
						link.download = fileName;
					}

					link.click();
					resolve();
				} else {
					window.open(url, '_blank');
					resolve();
				}
			}),
	};
};

export default useDownloadFile;
