import React, { useEffect } from 'react';
import { whoami } from '@/api/auth.ts';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';

const WhoamiProvider: React.FC = () => {
	const setWhoami = useWhoamiStore(state => state.setWhoami);

	useEffect(() => {
		whoami().then(data => {
			if (data.redirectUrl) {
				// Redirect to Spotnana SAML URL
				window.location.href = data.redirectUrl;
			}

			setWhoami(data);
		});
	}, [setWhoami]);

	return null;
};

export default WhoamiProvider;
