import React from 'react';
import MainLayout from '@/layouts/MainLayout/MainLayout.tsx';
import Container from '@/components/ui/Container/Container.tsx';
import Button from '@/components/ui/Button/Button.tsx';
import routes from '@/constants/routes.ts';
import { useNavigate } from 'react-router';
import styles from './AuthError.module.scss';

const AuthError: React.FC = () => {
	const navigate = useNavigate();

	return (
		<MainLayout>
			<Container>
				<div className={styles.authErrorContainer}>
					<h1>Oops! There seems to be an issue with your authentication.</h1>
					<p>
						We apologize for the inconvenience, but it appears that we are currently unable to authenticate
						your account. This could be due to a variety of reasons, including incorrect login credentials,
						expired sessions, or technical difficulties. Please double-check your username and password to
						ensure they are correct. If you continue to experience this issue, our customer service team is
						here to assist you.
					</p>
					<Button color="green" size="large" onClick={() => navigate(routes.login)}>
						Go Home
					</Button>
				</div>
			</Container>
		</MainLayout>
	);
};

export default AuthError;
