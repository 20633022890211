export const externalLinks = {
	goodwingsLegacy: {
		root: import.meta.env.VITE_FRONTEND_V1_URL,
		travelLog: `${import.meta.env.VITE_FRONTEND_V1_URL}/user/travel-log`,
		emissionReports: `${import.meta.env.VITE_FRONTEND_V1_URL}/user/reports/emissions`,
	},
	spotnana: {
		root: 'https://trips.goodwings.com',
		book: '/spotnana?path=/flights',
		admin: '/spotnana?path=/admin',
		myProfile: '/spotnana?path=/profile/personal',
		policySettings: '/spotnana?path=/admin/policies/new',
		myTrips: '/spotnana?path=/trips/upcoming',
		groupTrips: '/spotnana?path=/group-trips',
		companyTrips: '/spotnana?path=/company-trips',
		myApprovals: '/spotnana?path=/approvals',
		companyApprovals: '/spotnana?path=/company/approvals',
		myReports: '/spotnana?path=/traveler-reports',
		companyReports: '/spotnana?path=/reports',
		travellersSafety: '/spotnana?path=/dutyofcare',
		companySettings: '/spotnana?path=/admin/company',
		usersSettings: '/spotnana?path=/admin/users',
		policies: '/spotnana?path=/admin/policies',
		support: '/spotnana?path=/support',
		events: '/spotnana?path=/events',
		agent: '/spotnana?path=/agent/Desktop',
		tmcSettings: '/spotnana?path=/admin/tmc',
	},
	hubspot: 'https://portal.goodwings.com',
	learnMoreAboutSustainableTravel: 'https://blog.goodwings.com/tag/sustainable-business-travel',
	certificateExplanation:
		'https://static.goodwings.com/documents/how-to-read-your-climate-impact-certificate-2024.pdf',
	climateCertificateExample: 'https://static.goodwings.com/pages/dashboard/certificate_2023_example.pdf',
	reforestationVideo: 'https://www.youtube.com/embed/fliUxS7gHcw',
	guideToTravelExpenseManagement: 'https://blog.goodwings.com/complete-guide-to-travel-and-expense-management',
	travelPolicy: 'https://blog.goodwings.com/how-to-implement-a-travel-policy',
	bookADemo: 'https://portal.goodwings.com/book-a-demo',
	instagramGoodwings: 'https://www.instagram.com/goodwingstravel/,',
	twitterGoodwings: 'https://x.com/GoodwingsTravel/',
	facebookGoodwings: 'https://www.facebook.com/goodwingstravel/',
	linkedinGoodwings: 'https://www.linkedin.com/company/goodwings/',
	goodwingsCapterra: 'https://www.capterra.com/p/10010938/Goodwings/',
	googlePlayStore: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
	appleStore: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
};
