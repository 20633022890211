import React, { FC, ReactNode } from 'react';
import useResponsiveValue, { ResponsiveValue } from '@/hooks/useBreakpointValue.ts';
import { Property } from 'csstype';

interface FlexBlockProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	children: ReactNode;
	flexDirection?: ResponsiveValue<Property.FlexDirection>;
	justifyContent?: ResponsiveValue<Property.JustifyContent>;
	alignItems?: ResponsiveValue<Property.AlignItems>;
	flexWrap?: ResponsiveValue<Property.FlexWrap>;
	alignContent?: ResponsiveValue<Property.AlignContent>;
	gap?: ResponsiveValue<Property.Gap>;
	rowGap?: ResponsiveValue<Property.RowGap>;
	flex?: ResponsiveValue<Property.Flex>;
	columnGap?: ResponsiveValue<Property.ColumnGap>;
	className?: string;
}

const FlexBlock: FC<FlexBlockProps> = ({
	children,
	flexDirection = 'row',
	justifyContent,
	alignItems,
	flexWrap,
	alignContent,
	gap,
	rowGap,
	columnGap,
	flex,
	className,
	...props
}) => {
	return (
		<div
			className={className}
			{...props}
			style={{
				display: 'flex',
				flexDirection: useResponsiveValue(flexDirection),
				justifyContent: useResponsiveValue(justifyContent),
				alignItems: useResponsiveValue(alignItems),
				flexWrap: useResponsiveValue(flexWrap),
				alignContent: useResponsiveValue(alignContent),
				gap: useResponsiveValue(gap),
				rowGap: useResponsiveValue(rowGap),
				columnGap: useResponsiveValue(columnGap),
				flex: useResponsiveValue(flex),
			}}
		>
			{children}
		</div>
	);
};

export default FlexBlock;
