import React, { useCallback, useEffect } from 'react';
import { spotnanaWhoami } from '@/api/auth';
import { useNavigate } from 'react-router';
import { externalLinks } from '@/constants/externalLinks';
import { useSearchParams } from 'react-router-dom';
import SpotnanaSpinner from '@/components/ui/SpotnanaSpinner/SpotnanaSpinner.tsx';

const SpotnanaRedirection: React.FC = () => {
	const [urlParams] = useSearchParams();
	const navigate = useNavigate();

	const processRedirection = useCallback(async () => {
		const path = urlParams.get('path');
		const { loggedIn, ssoUrl } = await spotnanaWhoami(path);

		if (loggedIn && urlParams.has('path')) {
			// If user is logged in into Spotnana, redirect to the Spotnana URL path
			window.location.replace(`${externalLinks.spotnana.root}${path}`);
		} else if (ssoUrl) {
			// Otherwise, redirect to the SSO login URL
			window.location.replace(ssoUrl);
		} else {
			// If the user is not using spotnana booking engine, go back
			navigate(-1);
		}
	}, [navigate, urlParams]);

	useEffect(() => {
		processRedirection();
	}, [processRedirection]);

	return <SpotnanaSpinner message="One moment, please..." />;
};

export default SpotnanaRedirection;
