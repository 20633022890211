import {
	Area,
	AreaChart,
	Label,
	Legend,
	LegendProps,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	XAxisProps,
	YAxis,
	YAxisProps,
} from 'recharts';
import styles from './BudgetEmissionChart.module.scss';
import Card from '@/components/ui/Card/Card';
import ChartTooltip from '@/pages/Dashboard/ChartTooltip/ChartTooltip';
import React, { FC } from 'react';
import { DashboardAnalyticsData, Segments } from '@/api/dashboardAnalytics.ts';
import { UseQueryResult } from '@tanstack/react-query';
import useDashboardAnalytics from '@/store/useDashboardAnalytics.ts';
import Skeleton from 'react-loading-skeleton';
import _ from 'lodash';
import moment from 'moment';
import Overlay from '@/components/ui/Overlay/Overlay';

interface BudgetEmissionChartProps {
	queryAnalytics: UseQueryResult<DashboardAnalyticsData | null, unknown>;
}

type ChartKey = 'flight' | 'car' | 'hotel' | 'rail';

const chartDetail: Record<ChartKey, { color: string; dataKey: string; label: string }> = {
	flight: {
		color: '#25528F',
		dataKey: 'flight',
		label: 'Flight',
	},
	car: {
		color: '#9861F6',
		dataKey: 'car',
		label: 'Car',
	},
	hotel: {
		color: 'rgb(91, 222, 182)',
		dataKey: 'hotel',
		label: 'Hotel',
	},
	rail: {
		color: '#FFDE86',
		dataKey: 'rail',
		label: 'Rail',
	},
};

interface CustomTickProps extends XAxisProps {
	x: number;
	y: number;
	payload: {
		value: string;
	};
}

const CustomTick: React.FC<CustomTickProps> = ({ x, y, payload }) => (
	<g transform={`translate(${x},${y})`}>
		<text x={0} y={0} dy={-10} fontSize="10px" textAnchor="middle" fill="#fff">
			{moment(payload.value, 'YYYY-MM').format('MMM')}
		</text>
	</g>
);

interface CustomTickYProps extends YAxisProps {
	x: number;
	y: number;
	payload: {
		value: number;
	};
	total: number;
}

const CustomTickY: React.FC<CustomTickYProps> = ({ x, y, payload, total }) => {
	const value = payload?.value;
	const isOutRange = value >= total;
	return (
		<g transform={`translate(${x},${y})`}>
			<text
				x={0}
				y={0}
				dx={-10}
				fontSize={10}
				textAnchor="middle"
				fill={isOutRange ? '#000' : '#FFF'}
				fillRule="evenodd"
			>
				{value && +value.toFixed(2)}
			</text>
		</g>
	);
};

const CustomLegend: React.FC<LegendProps> = ({ payload }) => (
	<ul
		style={{
			listStyle: 'none',
			margin: 0,
			padding: 0,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
		}}
	>
		{payload?.map((entry, index) => (
			<li key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', margin: '10px' }}>
				<svg width="18" height="18" viewBox="0 0 20 20">
					<rect x="1" y="1" width="18" height="18" rx="4" ry="4" style={{ fill: entry.color }} />
				</svg>
				<span style={{ marginLeft: 10, color: '#4F4F4F', fontSize: '12px' }}>
					{/* @ts-ignore */}
					{entry?.payload?.label || 'value'}
				</span>
			</li>
		))}
	</ul>
);

const findMaxValue = (segments: Segments[]) => {
	const values = _.flatMap(segments, segment => [segment.car, segment.flight, segment.hotel, segment.rail]);

	const maxValue = _.max(values.filter(value => value !== undefined)) || 0;
	return maxValue;
};

const BudgetEmissionChart: FC<BudgetEmissionChartProps> = ({ queryAnalytics }) => {
	const { timePeriod, contribution } = useDashboardAnalytics();

	const budget = queryAnalytics?.data?.stats?.target?.company;

	const chartData = queryAnalytics.data?.segments?.[contribution];

	const max = Math.max(findMaxValue(chartData || []) || 0, budget || 0);

	const loading = queryAnalytics.isLoading || queryAnalytics.isRefetching;
	const isError = queryAnalytics.isError;

	if (loading) {
		return <Skeleton borderRadius="10px" className={styles.chartContainer} />;
	}

	return (
		<Card className={styles.chartContainer}>
			<ResponsiveContainer width="100%" height="100%">
				{/* @ts-ignore */}
				<AreaChart data={chartData} margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
					<g>
						<text
							className="chatTitle"
							x={20}
							y={60}
							fill="#003C37"
							textAnchor="start"
							dominantBaseline="end"
							fontSize="40"
							fontWeight="bold"
						>
							Travel
						</text>
						<text
							className="chatTitle"
							x={20}
							y={100}
							fill="#003C37"
							textAnchor="start"
							dominantBaseline="end"
							fontSize="40"
							fontWeight="bold"
						>
							Emission Budget
						</text>
					</g>
					<Tooltip
						cursor={false}
						content={<ChartTooltip labelMap={chartDetail} extralabel="Tons of CO2" />}
					/>

					<Legend
						wrapperStyle={{ paddingBottom: 70 }}
						content={<CustomLegend />}
						verticalAlign="top"
						align="right"
					/>
					{(['car', 'flight', 'hotel', 'rail'] as ChartKey[]).map(chartKey => (
						<Area
							className={`gw-chart-${chartKey}`}
							key={chartKey}
							type="monotone"
							dataKey={chartKey}
							label={chartDetail[chartKey].label}
							strokeOpacity={1}
							fill={chartDetail[chartKey].color}
							stroke={chartDetail[chartKey].color}
							stackId={10}
							fillOpacity={1}
						/>
					))}
					{/* @ts-ignore */}
					{budget && contribution == 'company' && timePeriod === 'current-year' && (
						<ReferenceLine y={budget} stroke="#003C37" fill="#003C37" strokeDasharray="6 6" strokeWidth={2}>
							<Label
								value="CO2 Budget target"
								position="insideTopLeft"
								style={{ fill: '#000' }}
								dx={10}
								dy={6}
								fontSize={10}
							/>
						</ReferenceLine>
					)}
					<XAxis
						dataKey="label"
						tickLine={false}
						axisLine={false}
						fontSize={8}
						interval="preserveStartEnd"
						color="#fff"
						opacity={1}
						// @ts-ignore
						tick={<CustomTick />}
						mirror
					/>
					<YAxis
						type="number"
						color="#FFF"
						fontSize={12}
						mirror
						tickLine={false}
						orientation="right"
						axisLine={false}
						domain={[0, max]}
						// @ts-ignore
						tick={<CustomTickY total={4000} />}
					/>
				</AreaChart>
			</ResponsiveContainer>
			{isError && <Overlay text="No Data Available" subtext="Error Occurred. Please try again later" />}
		</Card>
	);
};

export default BudgetEmissionChart;
