import React from 'react';
import styles from './SustainabilityToolsHeader.module.scss';
import Container from '@/components/ui/Container/Container.tsx';

const SustainabilityToolsHeader: React.FC = () => {
	return (
		<div className={styles.headerWrapper}>
			<Container>
				<h1>Corporate sustainability tools &#x2699;&#xFE0F;</h1>
			</Container>
		</div>
	);
};

export default SustainabilityToolsHeader;
