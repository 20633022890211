import React from 'react';
import styles from './ExploreGoodwingsMenu.module.scss';
import ButtonLink from '@/components/ui/ButtonLink/ButtonLink.tsx';
import { externalLinks } from '@/constants/externalLinks.ts';
import routes from '@/constants/routes.ts';
import { Link } from 'react-router-dom';
import InstagramIcon from '@/assets/Icons/Instagram.svg?react';
import TwitterIcon from '@/assets/Icons/Twitter.svg?react';
import LinkedinIcon from '@/assets/Icons/Linkedin.svg?react';
import FacebookIcon from '@/assets/Icons/Facebook.svg?react';
import { useWindowSize } from '@react-hook/window-size';
import breakpoints from '@/constants/breakpoints.ts';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';

const ExploreGoodwingsMenu: React.FC = () => {
	const [width] = useWindowSize();

	return (
		<FlexBlock flexDirection="column" rowGap="20px" className={styles.menuWrapper}>
			<FlexBlock
				flexDirection={{
					[breakpoints.zero]: 'column',
					[breakpoints.tablet]: 'row-reverse',
				}}
				justifyContent={{
					[breakpoints.zero]: 'unset',
					[breakpoints.tablet]: 'space-between',
				}}
			>
				<div className={styles.buttonsWrapper}>
					<ButtonLink
						color={width > breakpoints.tablet ? 'green' : 'transparent'}
						size={width > breakpoints.tablet ? 'large' : 'small'}
						href={externalLinks.bookADemo}
					>
						Book a demo
					</ButtonLink>
					<ButtonLink
						color="transparent"
						size={width > breakpoints.tablet ? 'large' : 'small'}
						href={routes.login}
					>
						Sign in
					</ButtonLink>
				</div>
				<div className={styles.info}>
					<p>Info</p>
					<Link to={externalLinks.hubspot}>Explore</Link>
				</div>
			</FlexBlock>
			<FlexBlock justifyContent="space-between" className={styles.socialIcons}>
				<Link to={externalLinks.facebookGoodwings}>
					<FacebookIcon />
				</Link>
				<Link to={externalLinks.linkedinGoodwings}>
					<LinkedinIcon />
				</Link>
				<Link to={externalLinks.twitterGoodwings} className={styles.twitterIcon}>
					<TwitterIcon />
				</Link>
				<Link to={externalLinks.instagramGoodwings}>
					<InstagramIcon />
				</Link>
			</FlexBlock>
		</FlexBlock>
	);
};

export default ExploreGoodwingsMenu;
