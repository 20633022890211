import React, { useState } from 'react';
import Modal from '../ui/Modal/Modal.tsx';
import styles from './RatingModal.module.scss';
import classNames from 'classnames';
import Button from '@/components/ui/Button/Button.tsx';
import { useModalStore } from '@/store/useModalStore.ts';
import AmazonGiftCard from '@/assets/Illustrations/Amazon-Gift-Card.svg?react';
import ThankYouIllustration from '@/assets/Illustrations/Thank-You-Hand.svg?react';
import CapterraIcon from '@/assets/Illustrations/Captera.svg?react';
import { externalLinks } from '@/constants/externalLinks.ts';
import ButtonLink from '@/components/ui/ButtonLink/ButtonLink.tsx';
import { dismissRatingModal, setRating } from '@/api/rating.ts';
import { useMutation } from '@tanstack/react-query';
import Spinner from '@/components/ui/Spinner/Spinner.tsx';
import FormikTextArea from '@/components/Formik/FormikTextArea.tsx';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';

export const ratingSchema = Yup.object().shape({
	rating: Yup.number().min(1, 'Oops! You need to choose a rating before submitting'),
});

interface RatingModalProps {
	showThankYou?: boolean;
}

const RatingModal: React.FC<RatingModalProps> = ({ showThankYou }) => {
	const [showFinishState, setShowFinishState] = useState(false);
	const closeModal = useModalStore(state => state.closeModal);

	const mutation = useMutation({
		mutationFn: (data: { rating: number; comment?: string }) => setRating(data),
		onSuccess: () => {
			setShowFinishState(true);
		},
	});

	const handleSubmit = (values: { rating: number; comment: string }) => {
		const dataToSend: { rating: number; comment?: string } = {
			rating: values.rating,
		};

		if (values.comment.trim()) {
			dataToSend.comment = values.comment;
		}

		mutation.mutate(dataToSend);
	};

	const handleClose = async () => {
		closeModal();
		await dismissRatingModal();
	};

	if (showFinishState || showThankYou) {
		if (mutation.variables && mutation.variables?.rating > 8) {
			return (
				<Modal name="ratingModal" className={classNames(styles.ratingModal, styles.highRating)}>
					<AmazonGiftCard />
					<p>Thank you!</p>
					<p>Could you also share your experience on Capterra?</p>
					<p>As a thank you, you’ll receive a $50 Amazon gift card!</p>
					<ButtonLink fullWidth hover={false} target="_blank" size="large" href={externalLinks.goodwingsCapterra} color="default">
						Submit review on <CapterraIcon />
					</ButtonLink>
				</Modal>
			);
		} else {
			return (
				<Modal name="ratingModal" className={classNames(styles.ratingModal, styles.lowRating)}>
					<ThankYouIllustration />
					<p>Thank you!</p>
					<p>Your feedback helps us make Goodwings better.</p>
				</Modal>
			);
		}
	}

	return (
		<Modal name="ratingModal" onClose={handleClose} className={styles.ratingModal} outsideClickEnabled={false}>
			<h1>We value your feedback!</h1>
			<p className={styles.subtitle}>How likely is it that you would recommend Goodwings to a friend or colleague?</p>
			<Formik initialValues={{ rating: 0, comment: '' }} validationSchema={ratingSchema} onSubmit={handleSubmit}>
				{({ values, setFieldValue }) => (
					<Form>
						<div className={styles.ratingButtonsWrapper}>
							<div className={styles.ratingButtonsLabels}>
								<p>Not at all likely</p>
								<p>Extremely likely</p>
							</div>
							<div className={styles.ratingButtons}>
								{[...Array(10)].map((_, index) => {
									const rating = index + 1;
									return (
										<button
											type="button"
											key={rating}
											className={classNames(
												styles.ratingButton,
												values.rating === rating && styles.selected,
												(rating === 7 || rating === 8) && styles.lightGreen,
												(rating === 9 || rating === 10) && styles.mediumGreen,
											)}
											onClick={() => setFieldValue('rating', rating)}
										>
											{rating}
										</button>
									);
								})}
							</div>
						</div>
						<ErrorMessage name="rating" component="p" className={styles.errorMessage} />
						{mutation.error && <p className={styles.errorMessage}>Something went wrong. Please try again later.</p>}
						{!!values.rating && values.rating < 9 && (
							<div className={styles.textareaLabelWrapper}>
								<p>Tell us a bit more about why you gave a score of {values.rating}</p>
								<FormikTextArea name="comment" placeholder="Share your feedback" className={styles.textarea} />
							</div>
						)}
						<div className={styles.bttnWrapper}>
							<Button onClick={handleClose} size="large" hover={false} fullWidth>
								Maybe later
							</Button>
							<Button color="green" size="large" fullWidth type="submit">
								Submit review
							</Button>
						</div>
						{mutation.isPending && <Spinner absolute overlay />}
					</Form>
				)}
			</Formik>
		</Modal>
	);
};

export default RatingModal;
