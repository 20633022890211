import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface PersistentPreferencesStore {
	isGoodwingsEmployee: boolean;
	setGoodwingsEmployee: (isGoodwingsEmployee: boolean) => void;
	isLanguageNotificationSeen: boolean;
	setIsLanguageNotificationSeen: (isLanguageNotificationSeen: boolean) => void;
	returnPath: string | undefined;
	setReturnPath: (returnPath: string | undefined) => void;
	clearReturnPath: () => void;
}

const storage = {
	name: 'preferences',
	storage: createJSONStorage(() => localStorage),
};

const usePersistentPreferencesStore = create<PersistentPreferencesStore>()(
	persist(
		set => ({
			isGoodwingsEmployee: false,
			setGoodwingsEmployee: isGoodwingsEmployee => set({ isGoodwingsEmployee }),
			isLanguageNotificationSeen: false,
			setIsLanguageNotificationSeen: isLanguageNotificationSeen => set({ isLanguageNotificationSeen }),
			returnPath: undefined,
			setReturnPath: returnPath => set({ returnPath }),
			clearReturnPath: () => set({ returnPath: undefined }),
		}),
		storage,
	),
);

export default usePersistentPreferencesStore;
