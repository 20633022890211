import { Button, Key, Label, ListBox, ListBoxItem, Popover, Select, SelectValue } from 'react-aria-components';
import styles from './Filters.module.scss';
import CalendarIcon from '@/assets/Icons/Calendar-icon.svg?react';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock';
import useDashboardAnalytics, { ContributionType } from '@/store/useDashboardAnalytics.ts';
import { FC, useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';
import { AnalyticsPeriod, DashboardAnalyticsData, initialAnalyticsPeriod } from '@/api/dashboardAnalytics.ts';
import { UseQueryResult } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';

interface FiltersProps {
	queryAnalytics: UseQueryResult<DashboardAnalyticsData | null, unknown>;
}

type OptionType = {
	value: AnalyticsPeriod;
	label: string;
};

const selectOptions: OptionType[] = [
	{ value: initialAnalyticsPeriod, label: 'Current year' },
	{ value: 'last-12-months', label: 'Last 12 months' },
	{ value: 'last-6-months', label: 'Last 6 months' },
	{ value: 'last-3-months', label: 'Last 3 months' },
];

type TabOption = {
	value: ContributionType;
	label: string;
};

const tabsOptions: TabOption[] = [
	{
		value: 'me',
		label: 'Myself',
	},
	{
		value: 'department',
		label: 'Department',
	},
	{
		value: 'legalEntity',
		label: 'Legal Entity',
	},
	{
		value: 'company',
		label: 'Company',
	},
];

const Filters: FC<FiltersProps> = ({ queryAnalytics }) => {
	const { setContribution, setTimePeriod, contribution, timePeriod } = useDashboardAnalytics();
	const { isFetching, data } = queryAnalytics;

	const selectedOption = selectOptions.find(option => option.value === timePeriod);
	const selectedOptionIndex = selectOptions.findIndex(option => option.value === timePeriod) + 1;

	const handleSelectionChange = (data: Key) => {
		try {
			const valueString = data?.toString()?.split('react-aria-')?.[1];
			const value = Number(valueString) - 1;
			const selectedValue = selectOptions?.[value]?.value;
			setTimePeriod(selectedValue);
		} catch (error) {
			console.error('Error in handleSelectionChange:', error);
		}
	};

	const handleTabOnChange = (value: ContributionType) => {
		setContribution(value);
	};

	const indicatorRef = useRef<HTMLDivElement>(null);
	useLayoutEffect(() => {
		if (!isFetching) {
			const activeTabElement = document.querySelector(`.${styles.tab}.${styles.active}`) as HTMLElement;
			if (activeTabElement && indicatorRef.current) {
				indicatorRef.current.style.width = `${activeTabElement.offsetWidth}px`;
				indicatorRef.current.style.transform = `translateX(${activeTabElement.offsetLeft}px)`;
			}
		}
	}, [contribution, isFetching]);

	return (
		<FlexBlock className={styles.filters}>
			<div>
				<Select
					isDisabled={isFetching}
					defaultSelectedKey="react-aria-1"
					onSelectionChange={handleSelectionChange}
					selectedKey={`react-aria-${selectedOptionIndex}`}
					className={styles.select}
				>
					<Label aria-hidden="true" aria-label="Select time period" />
					<Button>
						<span aria-hidden="true">
							<CalendarIcon />
						</span>
						<SelectValue>{selectedOption?.label}</SelectValue>
					</Button>
					<Popover className={styles.popover}>
						<ListBox>
							{selectOptions.map(({ value, label }, index) => (
								<ListBoxItem aria-label={label} className={styles.optionItem} key={`${value}_${index}`}>
									{label}
								</ListBoxItem>
							))}
						</ListBox>
					</Popover>
				</Select>
			</div>
			{isFetching ? (
				<Skeleton width="230px" height="35px" className={styles.tabs} />
			) : (
				<div className={styles.tabs}>
					{tabsOptions.map(({ value, label }) => {
						const size = data?.breakdown?.[value]?.length || 0;
						const hasItems = size > 0;
						if (!hasItems) {
							return null;
						}
						return (
							<div
								onClick={() => handleTabOnChange(value)}
								key={value}
								className={classNames(styles.tab, { [styles.active]: contribution === value })}
							>
								{label}
							</div>
						);
					})}
					<div ref={indicatorRef} className={styles.activeIndicator} />
				</div>
			)}
		</FlexBlock>
	);
};

export default Filters;
