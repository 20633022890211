import React, { useState } from 'react';
import Container from '@/components/ui/Container/Container.tsx';
import { Form, Formik, FormikHelpers } from 'formik';
import FormikInput from '@/components/Formik/FormikInput.tsx';
import MainLayout from '@/layouts/MainLayout/MainLayout.tsx';
import { forgotPassword } from '@/api/auth.ts';
import styles from './ForgotPassword.module.scss';
import Button from '@/components/ui/Button/Button.tsx';
import routes from '@/constants/routes.ts';
import { useNavigate } from 'react-router';
import { forgotPasswordSchema } from '@/validations/user.ts';
import handleResponseErrors from '@/utils/handleResponseErrors.ts';
import GlobalError from '@/components/FormikErrors/GlobalError.tsx';

interface ForgotPasswordValues {
	email: string;
}

const ForgotPassword: React.FC = () => {
	const navigate = useNavigate();
	const [isLinkSent, setIsLinkSent] = useState<boolean>(false);

	const handleSubmit = async (
		values: ForgotPasswordValues,
		formikHelpers: FormikHelpers<ForgotPasswordValues>,
	): Promise<void> => {
		try {
			await forgotPassword(values.email);
			setIsLinkSent(true);
		} catch (error) {
			handleResponseErrors(error, formikHelpers);
		}
	};

	return (
		<MainLayout>
			<Container>
				<div className={styles.forgotContainer}>
					{isLinkSent ? (
						<>
							<h1>The link is on the way</h1>
							<p>Check your mail and hit the link in the email to reset your password</p>
						</>
					) : (
						<>
							<h1>Forgot your password?</h1>
							<Formik<ForgotPasswordValues>
								initialValues={{ email: '' }}
								validationSchema={forgotPasswordSchema}
								onSubmit={handleSubmit}
							>
								{({ isSubmitting }) => (
									<Form>
										<GlobalError />
										<p>
											We've all been there. Put your email in below and we'll send you a new link
											to reset your password.
										</p>
										<div>
											<FormikInput name="email" label="Email*" className={styles.formikInput} />

											<Button
												type="submit"
												color="green"
												size="large"
												loading={isSubmitting}
												disabled={isSubmitting}
											>
												Send me a link
											</Button>
										</div>
									</Form>
								)}
							</Formik>
							<button onClick={() => navigate(routes.login)} className={styles.backButton}>
								Back
							</button>
						</>
					)}
				</div>
			</Container>
		</MainLayout>
	);
};

export default ForgotPassword;
