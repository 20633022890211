import Modal from '@/components/ui/Modal/Modal';
import { useEffect, useMemo, useState } from 'react';
import Button from '@/components/ui/Button/Button';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock';
import styles from './QuizModal.module.scss';
import { shuffle } from 'lodash';
import QuestionsList from './QuestionList.tsx';
import { useReward } from 'react-rewards';
import data from './data.json';
import { useModalStore } from '@/store/useModalStore';

//assets
import TakeQuizImage from '@/assets/Illustrations/Take-quiz.svg?react';
import QuestionBackgroundImage from '@/assets/Images/Question-bg.png';
import FullClockIcon from '@/assets/Icons/Full-clock-icon.svg?react';
import CorrectAnswersImage from '@/assets/Illustrations/Correct-quiz-answers.svg?react';
import InCorrectAnswersImage from '@/assets/Illustrations/Less-than-10-quiz.svg?react';
import WelcomeFinishModal from '@/components/WelcomeFinishModal/WelcomeFinishModal.tsx';
import breakpoints from '@/constants/breakpoints.ts';

type QuestionSelectionState = 'true' | 'false' | 'notSelected';

type Question = {
	question: string;
	options: string[];
	correct_answer: string;
};

type Quiz = {
	quiz_title: string;
	questions: Question[];
};

const QuestionTime = 30;
const QuestionTimeRunOut = 0;

const quiz = data as Quiz;
const QuizModal = () => {
	const questions = useMemo(
		() =>
			quiz.questions.map(question => ({
				...question,
				options: shuffle(question.options),
			})),
		[],
	);
	const questionsSize = questions.length;

	const [step, setStep] = useState<'start' | 'questions' | 'result'>('start');
	const [time, setTime] = useState<number>(QuestionTime);
	const [timeSelected, setTimeSelected] = useState<number>(0);
	const [results, setResults] = useState<boolean[]>([]);
	const [isCorrect, setIsCorrect] = useState<QuestionSelectionState>('notSelected');
	const [resetTransition, setResetTransition] = useState(false);
	const [additionalTime, setAdditionalTime] = useState(false);

	const activeQuestionIndex = results.length;

	const activeQuestion = questions[activeQuestionIndex];

	const correctAnswersCount = results?.filter(answer => answer).length;

	const onHandleSelectCorrect = (selectState: QuestionSelectionState) => {
		setIsCorrect(selectState);
		setTimeSelected(time - 2);
	};

	useEffect(() => {
		let transitionTimeout: number | null = null;

		if (step === 'questions' && activeQuestionIndex < questionsSize) {
			const timerId = setTimeout(() => {
				if (time <= 0 || time < timeSelected) {
					setAdditionalTime(false);
					if (!additionalTime && isCorrect === 'notSelected') {
						setAdditionalTime(true);
						setTime(2);
					} else if (activeQuestionIndex < questionsSize - 1) {
						setResults([...results, isCorrect === 'true']);
						setIsCorrect('notSelected');
						setTime(QuestionTime);
						setTimeSelected(0);
						setResetTransition(true);
						transitionTimeout = setTimeout(() => setResetTransition(false), 200) as unknown as number;
					} else {
						setStep('result');
						setResults([...results, isCorrect === 'true']);
					}
				} else {
					setTime(time - 1);
				}
			}, 1000);

			return () => {
				clearTimeout(timerId);
				if (transitionTimeout !== null) {
					clearTimeout(transitionTimeout);
				}
			};
		}
	}, [time, step, resetTransition, timeSelected]);

	const { reward: confettiReward } = useReward('confettiReward', 'confetti', {
		lifetime: 500,
		elementCount: 100,
		angle: 60,
	});

	const passQuestions = correctAnswersCount >= 8;
	const closeModal = useModalStore(state => state.closeModal);

	const handleCloseModal = () => {
		setStep('start');
		closeModal();
	};

	useEffect(() => {
		if (passQuestions) {
			confettiReward();
		}
	}, [passQuestions]);

	return (
		<Modal name="quizModal" className={styles.quizContainer}>
			{step === 'start' && (
				<FlexBlock
					justifyContent="center"
					alignItems="center"
					alignContent="center"
					flexDirection="column"
					className={styles.startStep}
				>
					<TakeQuizImage />
					<h4>Sustainability Quiz</h4>
					<p>Are you ready to answer 10 questions about sustainability?</p>
					<div>
						<Button color="green" size="large" onClick={() => setStep('questions')}>
							Start the Quiz
						</Button>
					</div>
				</FlexBlock>
			)}
			{step === 'questions' && (
				<div className={styles.questionsStep}>
					<FlexBlock
						className={styles.questionNav}
						flexDirection={{
							[breakpoints.zero]: 'column',
							[breakpoints.tablet]: 'row',
						}}
						justifyContent="space-between"
					>
						<div>
							<h4>
								Question {activeQuestionIndex + 1} out of {questionsSize}
							</h4>
						</div>
						<FlexBlock alignItems="flex-end">
							<FlexBlock flexDirection="column" alignItems="flex-end">
								<h4 className={styles.secondsText}>{additionalTime ? '0' : time} seconds</h4>
								<FlexBlock flexDirection="row-reverse" className={styles.progressWrapper}>
									<div
										className={styles.progress}
										style={{
											width: `${additionalTime ? 0 : (time / QuestionTime) * 100}%`,
											backgroundColor: time < 6 ? '#ea4949' : '#2FAA7B',
											transition: resetTransition ? '0.2s' : '1s',
										}}
									/>
								</FlexBlock>
							</FlexBlock>
							<FullClockIcon />
						</FlexBlock>
					</FlexBlock>
					<div className={styles.question} style={{ backgroundImage: `url('${QuestionBackgroundImage}')` }}>
						<h1>{activeQuestion.question}</h1>
					</div>
					<QuestionsList
						timeRunOut={time < QuestionTimeRunOut || additionalTime}
						options={activeQuestion.options}
						answer={activeQuestion.correct_answer}
						setIsCorrect={onHandleSelectCorrect}
					/>
				</div>
			)}

			{step === 'result' && (
				<WelcomeFinishModal
					showConfetti={passQuestions}
					img={passQuestions ? <CorrectAnswersImage /> : <InCorrectAnswersImage />}
					text={{
						paragraphs: [
							{
								title: `${correctAnswersCount} out of ${questions.length} questions correct`,
							},
							{
								desc: passQuestions
									? 'Congratulations, you sure know your way around sustainability.'
									: "Nice try! But there's always more to learn.",
							},
							{
								desc: passQuestions
									? 'Continue to lead by example and inspire others on their sustainability journeys!'
									: "Consider revisiting some of the topics we covered today. With a little more exploration, you'll be acing this quiz in no time!",
							},
						],
					}}
					buttonProp={{
						color: 'green',
						text: 'Close',
						size: 'large',
						align: 'center',
						onClick: handleCloseModal,
					}}
				/>
			)}
		</Modal>
	);
};

export default QuizModal;
