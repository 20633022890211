import React from 'react';
import styles from './SpotnanaSpinner.module.scss';
import { times, uniqueId } from 'lodash';

interface SpotnanaSpinnerProps {
	message?: string;
}

const SpotnanaSpinner: React.FC<SpotnanaSpinnerProps> = ({ message }) => {
	return (
		<div className={styles.spinnerWrapper}>
			<div className={styles.container}>
				{times(4, () => (
					<div key={uniqueId()} />
				))}
			</div>
			{message && <p className={styles.message}>{message}</p>}
		</div>
	);
};

export default SpotnanaSpinner;
