import axios from 'axios';

interface Organization {
	id: number;
	name: string;
}

export enum Roles {
	CompanyAdmin = 'COMPANY_ADMIN',
	CompanyTravelArranger = 'COMPANY_TRAVEL_ARRANGER',
	TravelArranger = 'TRAVEL_ARRANGER',
	CompanyReportAdmin = 'COMPANY_REPORT_ADMIN',
	TmcAdmin = 'TMC_ADMIN',
	TmcAgent = 'TMC_AGENT',
	GlobalAdmin = 'GLOBAL_ADMIN',
	GlobalAgent = 'GLOBAL_AGENT',
}

export interface User {
	id?: number;
	firstName?: string;
	middleName?: string;
	lastName?: string;
	email?: string;
	hasPassword?: boolean;
	organization?: Organization;
	languageCode?: string;
	roles?: string[];
	spotnanaRole: Roles | null;
}

export interface ChangePasswordRequest {
	password: string;
	oldPassword?: string;
}

interface UpdateUserRequest {
	weGlotLanguageCode: string;
}

interface ActivateAccountRequest {
	password: string;
	token: string;
}

export const updateUser = async (data: UpdateUserRequest): Promise<null> => {
	const response = await axios.put('/users/me', data);
	return response.data;
};

export const changePassword = async (data: ChangePasswordRequest): Promise<void> => {
	const response = await axios.post('/users/password/change', data);
	return response.data;
};

export const activateAccount = async (data: ActivateAccountRequest): Promise<void> => {
	const response = await axios.post('/users/activation', data);
	return response.data;
};
