import * as yup from 'yup';
import * as Yup from 'yup';

export const forgotPasswordSchema = Yup.object().shape({
	email: Yup.string().required('Email is required'),
});

export const resetPasswordSchema = Yup.object().shape({
	email: Yup.string().required('Email is required'),
	password: Yup.string()
		.min(8, 'Password must be at least 8 characters')
		.max(128, 'Password cannot exceed 128 characters')
		.required('Password is required'),
	confirmPassword: Yup.string()
		.required('Confirm Password is required')
		.oneOf([Yup.ref('password')], 'Passwords do not match'),
});

export const changePasswordSchema = (hasPassword: boolean) =>
	yup.object({
		password: yup
			.string()
			.min(8, 'Password must be at least 8 characters')
			.max(128, 'Password cannot exceed 128 characters')
			.test('not-same-as-old', 'New password must be different from the old password', function (value) {
				if (hasPassword && this.parent.oldPassword) {
					return value !== this.parent.oldPassword;
				}
				return true;
			})
			.required('This field is required'),
		confirmPassword: yup
			.string()
			.oneOf([yup.ref('password'), undefined], 'Passwords do not match')
			.required('This field is required'),
		...(hasPassword
			? {
					oldPassword: yup.string().required('This field is required'),
				}
			: {}),
	});

export const activateAccountSchema = Yup.object().shape({
	password: Yup.string()
		.min(8, 'Password must be at least 8 characters')
		.max(128, 'Password cannot exceed 128 characters')
		.required('Password is required'),
	confirmPassword: Yup.string()
		.required('Confirm Password is required')
		.oneOf([Yup.ref('password')], 'Passwords do not match'),
});

export const twoFactorSchema = Yup.object().shape({
	twoFaCode: Yup.string()
		.matches(/^\d{6}$/, 'Code must be exactly 6 digits')
		.required('This field is required'),
});
