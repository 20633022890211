import React from 'react';
import styles from './SelectType.module.scss';
import SelectHeader from '@/pages/Dashboard/EmissionTargetModal/SelectHeader.tsx';
import TargetIcon from '@/assets/Illustrations/Target.svg?react';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import Button from '@/components/ui/Button/Button.tsx';
import { useEmissionTargetStore } from '@/store/useEmissionTargetStore.ts';
import { useFormikContext } from 'formik';
import { EmissionTargetValues } from '@/pages/Dashboard/EmissionTargetModal/EmissionTargetModal.tsx';
import { EmissionTargetType } from '@/api/emissionTarget.ts';
import ArrowRight from '@/assets/Icons/Arrow-Right.svg?react';
import breakpoints from '@/constants/breakpoints.ts';

const SelectType: React.FC = () => {
	const { setFieldValue } = useFormikContext<EmissionTargetValues>();
	const setType = useEmissionTargetStore(state => state.setType);

	const handleSetType = (type: EmissionTargetType) => {
		setType(type);
		setFieldValue('type', type);
	};

	return (
		<div className={styles.content}>
			<SelectHeader
				icon={<TargetIcon />}
				title="How to set an emission target?"
				text="There’s two paths you can take when setting an emission target"
			/>
			<FlexBlock
				flexDirection={{
					[breakpoints.zero]: 'column-reverse',
					[breakpoints.tablet]: 'row',
				}}
				className={styles.boxesWrapper}
			>
				<div className={styles.modalBox}>
					<div>
						<h2>Absolute</h2>
						<p>
							An absolute emissions reduction target focuses on reducing the total quantity of greenhouse
							gas emissions (GHG) emitted by a company or entity.
						</p>
						<p>
							As a simple example, if our company emits 1,000 metric tons (MT) of CO2-e in 2021 and our
							goal is to reduce absolute emissions by 10% in 2022, we need to reduce our annual emissions
							by 100 MT year-over-year from 1,000 to 900 MT of CO2-e.
						</p>
					</div>
					<Button
						fullWidth
						color="green"
						size="large"
						rightIcon={<ArrowRight />}
						onClick={() => handleSetType('absolute')}
						className={styles.button}
					>
						Choose an absolute reduction target
					</Button>
				</div>
				<div className={styles.modalBox}>
					<div className={styles.ribbon}>recommended</div>
					<div>
						<h2>Intensity</h2>
						<p className={styles.ribbonRight}>
							An intensity-based emissions target compares your organizations emissions to some unit of
							economic output.
						</p>
						<p>
							Examples could be revenue-based (i.e., MT of CO2 per million dollars in sales), per capita
							or per employee, per building or facility, per unit or finished product, or another material
							operational indicator
						</p>
					</div>
					<Button
						fullWidth
						color="green"
						size="large"
						rightIcon={<ArrowRight />}
						onClick={() => handleSetType('intensity')}
						className={styles.button}
					>
						Choose an intensity based reduction target
					</Button>
				</div>
			</FlexBlock>
		</div>
	);
};

export default SelectType;
