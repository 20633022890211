import Card from '@/components/ui/Card/Card';
import styles from './ForecastChart.module.scss';
import classNames from 'classnames';
import {
	Area,
	AreaChart,
	CartesianGrid,
	Label,
	Legend,
	LegendProps,
	ReferenceArea,
	ReferenceLine,
	ResponsiveContainer,
	Text,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import CheckboxToggle from '@/components/ui/CheckboxToggle/CheckboxToggle';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock';
import React, { FC, useState } from 'react';
import ChartTooltip from '../ChartTooltip/ChartTooltip';
import Button from '@/components/ui/Button/Button.tsx';
import { useModalStore } from '@/store/useModalStore.ts';
import { bundlingTripsData, directFlightData, Step, travelEconomyClassData } from '@/constants/learnMoreModalData.tsx';
import LearnMoreModal from '@/components/LearnMoreModal/LearnMoreModal.tsx';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import queryKeys from '@/constants/queryKeys.ts';
import { AnalyticsData, DashboardAnalyticsData } from '@/api/dashboardAnalytics.ts';
import useDashboardAnalytics from '@/store/useDashboardAnalytics.ts';
import _, { map } from 'lodash';
import { getEmissionTarget } from '@/api/emissionTarget';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import Overlay from '@/components/ui/Overlay/Overlay';

interface ForecastChartProps {
	queryAnalytics: UseQueryResult<DashboardAnalyticsData | null, unknown>;
}

interface StatsProps {
	title: string;
	subtitle: string;
	amount: number;
	isGrey?: boolean;
}

const Stat: React.FC<StatsProps> = ({ title, subtitle, amount, isGrey }) => (
	<div className={classNames(styles.budget, isGrey && styles.isGrey)}>
		<div className={styles.budgetSubtitle}>{subtitle}</div>
		<div className={styles.budgetTitle}>{title}</div>
		<div className={styles.budgetAmount}>{amount}</div>
		<div className={styles.budgetPrefix}>TONS OF CO2</div>
	</div>
);

const CustomLegend: React.FC<LegendProps> = ({ payload }) => {
	const variants = {
		hidden: { opacity: 0, y: 20 },
		visible: { opacity: 1, y: 0 },
	};

	return (
		<ul
			style={{
				listStyle: 'none',
				margin: 0,
				padding: 0,
				display: 'flex',
				alignItems: 'center',
			}}
		>
			{payload?.map((entry, index) => (
				<li
					key={`item-${index}`}
					style={{ display: 'flex', alignItems: 'center', margin: '20px' }}
					// @ts-ignore
					initial="hidden"
					animate="visible"
					variants={variants}
					transition={{ duration: 0.5, ease: 'easeInOut' }}
				>
					<svg width="20" height="20" viewBox="0 0 20 20">
						<rect x="1" y="1" width="18" height="18" rx="4" ry="4" style={{ fill: entry.color }} />
					</svg>
					<span style={{ marginLeft: 10, color: '#4F4F4F', fontSize: '12px' }}>
						{/* @ts-ignore */}
						{entry?.payload?.label || value}
					</span>
				</li>
			))}
		</ul>
	);
};

const CustomTick = (props: any) => {
	const { x, y, payload } = props;
	const monthName = moment(payload?.value, 'YYYY-MM').format('MMM');

	return (
		<g transform={`translate(${x},${y})`}>
			<Text x={0} y={0} dy={16} textAnchor="middle" fill="#4F4F4F" fontSize={12}>
				{monthName}
			</Text>
		</g>
	);
};

const findMaxValue = (analytics: AnalyticsData[]) => {
	const values = _.flatMap(analytics, data => [
		data.actual,
		data.directFlights,
		data.economyClass,
		data.bundleTrips,
		data.forecast,
	]);

	return _.max(values.filter(value => value !== undefined)) || 0;
};

const firstForecastLabel = (analytics: AnalyticsData[]) => {
	let forecastFirstLabel = '';

	for (let index = 0; index < analytics.length; index++) {
		const element = analytics[index];
		const isForecastMonth = element?.forecast !== null;

		if (isForecastMonth) {
			forecastFirstLabel = element.label;
			break;
		}
	}

	return forecastFirstLabel;
};

interface Option {
	title: string;
	text: string;
	color: string;
	isActive: boolean;
	steps: Step[];
}

type OptionKey = 'economyClass' | 'bundleTrips' | 'directFlights';

interface OptionsState {
	economyClass: Option;
	bundleTrips: Option;
	directFlights: Option;
}

const initialOptions: OptionsState = {
	economyClass: {
		title: 'Travel economy class',
		text: 'trips, less than 8 hours long were on business class',
		color: '#F765A3',
		isActive: false,
		steps: travelEconomyClassData,
	},
	bundleTrips: {
		title: 'Bundle trips',
		text: 'trips, by the same travellers had the same destination within 2 months',
		color: '#9861F6',
		isActive: true,
		steps: bundlingTripsData,
	},
	directFlights: {
		title: 'Take direct flights',
		text: 'air trips, within the last 6 months were multi leg where a direct route existed.',
		color: '#F37E64',
		isActive: false,
		steps: directFlightData,
	},
};

type labelMapKeys = OptionKey | 'actual' | 'forecast';
type LabelMap = {
	[key in labelMapKeys]: { label: string };
};
const labelMap: LabelMap = {
	actual: { label: 'Current emissions' },
	forecast: { label: 'Current emissions forecast' },
	economyClass: { label: 'Travel economy' },
	bundleTrips: { label: 'Bundle trips' },
	directFlights: { label: 'Take direct flight' },
};

const ForecastChart: FC<ForecastChartProps> = ({ queryAnalytics }) => {
	const { contribution, timePeriod } = useDashboardAnalytics();
	const { data, isLoading, isRefetching, isError } = queryAnalytics;

	const emissionQuery = useQuery({
		queryKey: [queryKeys.getEmissionTarget],
		queryFn: getEmissionTarget,
		enabled: true,
	});

	const [options, setOptions] = useState<OptionsState>(initialOptions);
	const { openModal, activeModal } = useModalStore(state => state);

	const dataOptions = data?.options;
	const analytics = data?.analytics;
	const stats = data?.stats;

	const chartData = analytics?.[contribution] || [];
	const budget = emissionQuery?.data?.targetCo2;
	const max = Math.max(findMaxValue(chartData || []) || 0, budget || 0);

	const forecastLabelStart = firstForecastLabel(chartData);
	const forecastLabelEnds = chartData?.[chartData.length - 1]?.label;
	const loading = isLoading || isRefetching;

	const toggleOption = (key: OptionKey) => {
		const updatedOptions = { ...options };

		updatedOptions[key].isActive = !updatedOptions[key].isActive;

		setOptions(updatedOptions);
	};

	if (loading) {
		return <Skeleton borderRadius="10px" height="708px" className={styles.wrapper} />;
	}

	const actualEmissionStatSubtitle = {
		company: 'COMPANY',
		department: 'DEPARTMENT',
		legalEntity: 'LEGAL ENTITY',
		me: 'MY',
	}[contribution];

	const optionsArray = Object.entries(options) as [OptionKey, Option][];

	return (
		<>
			<Card className={styles.wrapper}>
				<div className={styles.optionsColumn}>
					<div className={styles.optionHeader}>
						<div className={styles.optionHeadline}>AI CO2 Assistant</div>
						<p>The following suggestions would help limit your emissions</p>
					</div>
					{optionsArray.map(([optionKey, { title, text, steps, isActive }]) => {
						// @ts-ignore
						const optionData = dataOptions?.[contribution]?.[optionKey];
						return (
							<div className={classNames(styles.option, isActive ? styles.isActive : '')} key={optionKey}>
								<LearnMoreModal name={`learnMoreModal_${optionKey}`} steps={steps} key={activeModal} />
								<div className={styles.optionTitle}>
									<div>
										<strong>{title}</strong>
										<button
											onClick={() => openModal(`learnMoreModal_${optionKey}`)}
											className={styles.learMoreAction}
										>
											?
										</button>
									</div>
									<CheckboxToggle
										onChange={() => toggleOption(optionKey)}
										checked={isActive}
										uncheckedColor="#E5E5EA"
										checkedColor="#34C759"
									/>
								</div>
								<p>
									{optionData?.tripsCount} {text}
								</p>
								<FlexBlock
									className={styles.optionFooter}
									justifyContent="space-between"
									alignItems="center"
								>
									<div className={styles.tag}>
										Saves {optionData?.savings ? +optionData.savings.toFixed(2) : 0} tons of CO2
									</div>
									{isActive && (
										<Button
											onClick={() => openModal(`learnMoreModal_${optionKey}`)}
											size="small"
											className={styles.takeActionButton}
										>
											Take action
										</Button>
									)}
								</FlexBlock>
							</div>
						);
					})}
				</div>
				<div className={styles.chartColumn}>
					<div className={styles.stats}>
						<Stat
							subtitle={actualEmissionStatSubtitle}
							title="EMISSIONS IN PERIOD"
							amount={(stats?.actual?.[contribution] && +stats.actual?.[contribution].toFixed(2)) || 0}
						/>
						{contribution === 'company' &&
							timePeriod === 'current-year' &&
							stats?.target?.[contribution] !== null && (
								<Stat
									subtitle="COMPANY"
									title="TARGET"
									amount={
										(stats?.target?.[contribution] && +stats.target?.[contribution].toFixed(2)) || 0
									}
								/>
							)}
						<Stat
							subtitle="EMISSON"
							title="FORECAST"
							amount={
								(stats?.forecast?.[contribution] && +stats.forecast?.[contribution].toFixed(2)) || 0
							}
							isGrey
						/>
					</div>
					<ResponsiveContainer
						minHeight="500px"
						className="exclude_gw_translation"
						width="100%"
						height="100%"
					>
						{/* @ts-ignore */}
						<AreaChart data={chartData}>
							<CartesianGrid color="#ECECEC" strokeWidth={1} />
							<XAxis color="#4F4F4F" fontSize={12} dataKey="label" tick={<CustomTick />} interval={0} />
							<YAxis
								type="number"
								color="#4F4F4F"
								fontSize={12}
								domain={[0, max * 1.1]}
								tickFormatter={value => Math.round(value).toLocaleString()}
							/>
							<Tooltip
								cursor={false}
								content={<ChartTooltip extralabel="Tons of CO2" labelMap={labelMap} />}
							/>
							<Legend iconType="square" content={<CustomLegend />} wrapperStyle={{ padding: '30px 0' }} />
							<defs>
								<linearGradient id="areaGradient" x1="0" y1="0" x2="0" y2="1">
									<stop offset="0%" stopColor="rgba(126, 255, 206, 0.20)" />
									<stop offset="100%" stopColor="rgba(0, 0, 0, 0.00)" />
								</linearGradient>
							</defs>
							<ReferenceArea
								x1={forecastLabelStart}
								x2={forecastLabelEnds}
								strokeOpacity={0}
								fill="rgba(217, 217, 217, 0.5)"
							>
								<Label
									value="Forecast"
									position="insideTopLeft"
									style={{ fill: '#000', fontWeight: '400' }}
									dx={10}
									dy={10}
									fontSize={16}
								/>
							</ReferenceArea>
							{/* @ts-ignore */}
							{budget &&
								contribution === 'company' &&
								timePeriod === 'current-year' &&
								stats?.target?.[contribution] !== null && (
									<ReferenceLine
										y={budget}
										stroke="#003C37"
										fill="#003C37"
										strokeDasharray="6 6"
										strokeWidth={2}
									>
										<Label
											value="CO2 Budget target"
											position="insideTopLeft"
											style={{ fill: '#000' }}
											dx={10}
											dy={6}
											fontSize={10}
										/>
									</ReferenceLine>
								)}
							<Area
								type="monotone"
								dataKey="actual"
								label="Current emissions"
								stroke="rgb(126, 255, 206)"
								strokeWidth={4}
								fill="url(#areaGradient)"
								dot={{ r: 6, fill: 'rgb(126, 255, 206)', stroke: 'none' }}
								activeDot={{ r: 6, fill: 'rgb(126, 255, 206)', stroke: 'none' }}
							/>
							<Area
								type="monotone"
								dataKey="forecast"
								label="Current emissions forecast"
								stroke="rgb(72, 182, 140)"
								strokeWidth={4}
								fill="url(#areaGradient)"
								dot={{ r: 6, fill: 'rgb(72, 182, 140)', stroke: 'none' }}
								activeDot={{ r: 6, fill: 'rgb(72, 182, 140)', stroke: 'none' }}
							/>
							{map(options, ({ title, color, isActive }, key) => {
								if (!isActive) {
									return false;
								}

								return (
									<Area
										key={key}
										type="monotone"
										dataKey={key}
										label={title}
										stroke={color}
										strokeWidth={4}
										fill="transparent"
										dot={{ r: 6, fill: color, stroke: 'none' }}
										activeDot={{ r: 6, fill: color, stroke: 'none' }}
									/>
								);
							})}
							<Label
								value="CO2 Budget target"
								position="insideTopLeft"
								style={{ fill: '#000' }}
								dx={10}
								dy={budget || 0}
								fontSize={10}
							/>
						</AreaChart>
					</ResponsiveContainer>
				</div>
				{isError && <Overlay text="No Data Available" subtext="Error Occurred. Please try again later" />}
			</Card>
		</>
	);
};

export default ForecastChart;
