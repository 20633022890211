import AirlineSeat from '@/assets/Illustrations/Airline-Seat.svg';
import AirlineSeatCog from '@/assets/Illustrations/Airline-Seat-Cog.svg';
import CalendarGreenRow from '@/assets/Illustrations/Calendar-Green-Row.svg';
import CalendarMegaphone from '@/assets/Illustrations/Calendar-Megaphone.svg';
import BlueTrain from '@/assets/Illustrations/Blue-Train.svg';
import DirectFlight from '@/assets/Illustrations/Direct-Flight.svg';
import LowestLogicalFare from '@/assets/Images/Lowest-Logical-Fare.jpeg';
import LimitBusinessTravel from '@/assets/Images/Limit-Business-Travel.png';
import { ReactNode } from 'react';
import { externalLinks } from '@/constants/externalLinks.ts';

interface Paragraph {
	title?: string;
	desc: string;
}

export interface Step {
	header: string;
	leadingParagraph: Paragraph[];
	endingParagraph?: Paragraph[];
	stylizedBox?: {
		title?: string;
		desc: ReactNode | string;
	};
	img?: string;
	illustration: string;
	buttonText: string;
	buttonAction: string | { href: string };
	showButtonOnlyForAdmin?: boolean;
}

export const travelEconomyClassData: Step[] = [
	{
		illustration: AirlineSeat,
		header: 'Why business class is worse for the environment than economy',
		leadingParagraph: [
			{
				desc: 'Flying business class is generally considered a luxury, offering passengers enhanced comfort, more space, and better services. However, from an environmental perspective, business class can be significantly more impactful than economy class.',
			},
			{
				desc: 'The primary reason is the allocation of space. Business class seats are larger and offer more personal space, which means that fewer passengers can be accommodated in the same area compared to economy class. This results in a higher carbon footprint per passenger because the emissions generated by the flight are divided among fewer people, making the per-person emissions in business class much higher.',
			},
		],
		buttonText: 'Show me how I can take action',
		buttonAction: 'next',
	},
	{
		illustration: AirlineSeatCog,
		header: 'Create a policy for limiting business travel',
		leadingParagraph: [
			{
				desc: 'You can limit or control business travel by implementing travel policies targeted specifically at cabin class. You can also decide to only allow business class if a flight duration is larger than a certain number of hours.',
			},
		],
		img: LimitBusinessTravel,
		buttonText: 'Go to policy settings',
		buttonAction: {
			href: externalLinks.spotnana.policySettings,
		},
	},
];

export const bundlingTripsData: Step[] = [
	{
		illustration: CalendarGreenRow,
		header: 'Why bundling trips is both good for the environment and your budget',
		leadingParagraph: [
			{
				desc: 'Bundling trips, or coordinating travel plans so that multiple business travelers visit the same location within a consolidated time frame means fewer flights, which directly translates to lower costs and lower emissions.',
			},
			{
				desc: "Bundling trips reflects a commitment to sustainable practices, aligning with corporate social responsibility goals. This approach not only benefits the planet but can also enhance the company's reputation among environmentally aware stakeholders.",
			},
		],
		showButtonOnlyForAdmin: true,
		buttonText: 'Show me how I can take action',
		buttonAction: 'next',
	},
	{
		illustration: CalendarMegaphone,
		header: 'Communicate to your employees about bundling of trips',
		leadingParagraph: [
			{
				desc: "While it's not feasible to create a specific travel policy that covers all the nuances of trip bundling, clear and motivational communication can encourage employees to participate actively in this process.",
			},
			{
				desc: 'Utilizing internal communication tools such as Slack, Microsoft Teams, or email can ensure the message reaches all employees efficiently and allows for open dialogue on the subject.',
			},
		],
		stylizedBox: {
			title: 'Here’s a handy message you can alter and share on Slack or Teams',
			desc: (
				<div>
					<strong>🌍✈️ Let’s Bundle Our Trips, Team! ✈️🌍</strong>
					<p>
						Hey everyone! We’re on a mission to make our business travel smarter and greener by bundling our
						trips. This means more fun, less spend, and a happier planet! 🚀💰🌱
					</p>
					<br />
					<strong>Why? 🤔</strong>
					<ul>
						<li>💸 Save Money: Group travel = big savings!</li>
						<li>🌳 Reduce Carbon Footprint: Fewer flights, happier Earth.</li>
						<li>🤝 Team Bonding: Travel together, grow stronger.</li>
					</ul>
					<br />
					<strong>How Can You Participate? 🙌</strong>
					<ul>
						<li>📅 Planning a trip? Check if anyone’s headed the same way.</li>
						<li>
							🔄 Think of any future meeting you need. Could it be planned in the same business travel, so
							you don’t have to go again?
						</li>
						<li>🗣️ Use the #TravelTogether channel to sync up or shout out in team chats.</li>
					</ul>
					<br />
					<p>Let’s make each trip count, for our budgets and our planet! 🌎💼</p>
					<p>Looking forward to seeing your travel plans come together! ✨</p>
				</div>
			),
		},
		buttonText: 'Copy message to clipboard',
		buttonAction: 'copy',
	},
];

export const takingTrainsData: Step[] = [
	{
		illustration: BlueTrain,
		header: 'The impact of taking trains compared to taking flights',
		leadingParagraph: [
			{
				desc: 'Choosing between trains and flights impacts not only our planet but also the total time your journey might take.',
			},
			{
				title: 'Sustainability',
				desc: "Trains are more eco-friendly, emitting far less CO2 per passenger than planes. They're a green choice, especially in regions with renewable-powered rail networks.\nFlights are major carbon emitters, though advancements are being made. They remain less sustainable, especially for short trips",
			},
			{
				title: 'Total Time from End to End',
				desc: 'You wouldn’t imagine it, but trains are often competitive in overall journey time. Central city departures and arrivals, minimal check-in procedures, and no long waits make trains very efficient for shorter distances.\nFlights are fast over long distances, but adding airport commutes, check-in, security, and possible delays can extend total travel time significantly.',
			},
		],
		buttonText: 'Show me how I can take action',
		buttonAction: 'next',
	},
	{
		illustration: BlueTrain,
		header: 'Promote taking trains compared to taking flights',
		leadingParagraph: [
			{
				desc: 'Promoting trains to your employees can make a big impact in the big picture. Not only is it cheaper, but it’s also a lot better for the environment.',
			},
		],
		stylizedBox: {
			title: 'Here’s a handy message you can alter and share on Slack or Teams',
			desc: (
				<div>
					<strong>🚆✈️ Rethink Your Journey: Choose Rail Over Air! ✈️🚆</strong>
					<p>
						Hey Team! Before you click 'Book' on your next flight, let’s pause for a moment. 🛤️🌍 Could
						there be a greener, more scenic route to your destination? Yes, we’re talking about trains!
					</p>
					<br />
					<strong>Why Consider Trains? 🤔</strong>
					<ul>
						<li>
							🌱 <strong>Eco-Friendly:</strong> Significantly lower carbon footprint compared to planes.
						</li>
						<li>
							🕒 <strong>Efficient Travel:</strong> Forget long airport commutes and queues. Enjoy city
							center to city center travel.
						</li>
						<li>
							🎫 <strong>Cost-Effective:</strong> Often, when accounting for all travel costs, trains
							offer great value.
						</li>
					</ul>
					<br />
					<strong>Before Booking a Flight:</strong>
					<ol>
						<li>
							🕵️‍♂️ <strong>Check for a Rail Connection:</strong> A quick search could reveal a convenient
							and sustainable option.
						</li>
						<li>
							📊 <strong>Compare the Total Journey Time:</strong> Remember, airports are not always in the
							city center.
						</li>
						<li>
							💡 <strong>Consider the Benefits:</strong> More than just travel - it’s an experience, with
							scenic views and spacious seating.
						</li>
					</ol>
					<br />
					<p>
						Let’s make our travel choices count for both the planet and our peace of mind. Next time, let’s
						check if there’s a rail option before flying. Who knows? The journey might be just as rewarding
						as the destination!
					</p>
					<p>Safe travels, everyone! 🌟</p>
				</div>
			),
		},
		buttonText: 'Copy message to clipboard',
		buttonAction: 'copy',
	},
];

export const directFlightData: Step[] = [
	{
		illustration: DirectFlight,
		header: 'Taking Direct Flights: Efficient and Eco-Friendly',
		leadingParagraph: [
			{
				desc: 'Choosing direct flights over connections can significantly influence your travel experience and environmental footprint.',
			},
			{
				title: 'Saves Time',
				desc: 'Eliminate layovers and get to your destination faster. Sometimes direct flight can be pricier, but what you save in time and stress is usually worth it.',
			},
			{
				title: 'Reduces Emissions',
				desc: 'Direct routes mean less fuel consumption, decreasing your carbon footprint.',
			},
			{
				title: 'Less Hassle',
				desc: 'Avoid the stress of multiple check-ins and the risk of lost luggage.',
			},
		],
		showButtonOnlyForAdmin: true,
		buttonText: 'Show me how I can take action',
		buttonAction: 'next',
	},
	{
		illustration: DirectFlight,
		header: 'Create a policy for “Lowest Logical Fare” to favor direct flights',
		leadingParagraph: [
			{
				desc: 'Lowest Logical Fare (LLF) is a policy used in corporate travel management to select the most cost-effective and sensible flight options. It balances cost, convenience, and compliance, ensuring that travel decisions support both budgetary goals and traveller needs.',
			},
		],
		endingParagraph: [
			{
				desc: 'By setting up a Lowest Logical Fare policy that favors direct flights, companies can ensure travel efficiency and employee comfort while managing costs.',
			},
		],
		img: LowestLogicalFare,
		buttonText: 'Go to policy settings',
		buttonAction: {
			href: externalLinks.spotnana.policySettings,
		},
	},
];
