import React, { useEffect } from 'react';
import Modal from '@/components/ui/Modal/Modal.tsx';
import Button from '@/components/ui/Button/Button.tsx';
import styles from './TwoFaModal.module.scss';
import { Form, FormikContextType, FormikProvider } from 'formik';
import GlobalError from '@/components/FormikErrors/GlobalError.tsx';
import FormikPinInput from '@/components/Formik/FormikPinInput.tsx';
import { useModalStore } from '@/store/useModalStore.ts';
import LoginRequest = App.Data.Request.LoginRequest;

interface TwoFaModalProps {
	formik: FormikContextType<LoginRequest>;
}

const TwoFaModal: React.FC<TwoFaModalProps> = ({ formik }) => {
	const { closeModal } = useModalStore();

	const { isSubmitting, values, setFieldValue } = formik;

	useEffect(() => {
		// Set empty string on component mount in order to populate initial values
		setFieldValue('twoFaCode', '');

		// Set undefined on component unmount in order to prevent sending twoFa code on regular login form
		return () => {
			setFieldValue('twoFaCode', undefined);
		};
	}, []);

	return (
		<Modal name="twoFaModal" className={styles.twoFaModalWrapper}>
			<h2>Enter the 6-digit token from your Authenticator app</h2>
			<FormikProvider value={formik}>
				<Form>
					<GlobalError />
					<FormikPinInput
						name="twoFaCode"
						length={6}
						size="large"
						allowedChars={/^[0-9]$/}
						className={styles.pinFieldWrapper}
					/>
					<div className={styles.buttonsWrapper}>
						<Button onClick={closeModal} disabled={isSubmitting}>
							Close
						</Button>
						<Button
							type="submit"
							color="green"
							disabled={isSubmitting || !values.twoFaCode || values.twoFaCode.length < 6}
						>
							Confirm
						</Button>
					</div>
				</Form>
			</FormikProvider>
		</Modal>
	);
};

export default TwoFaModal;
