// Create a custom retry function
import { QueryClient } from '@tanstack/react-query';

// Retry request only if the error is not in range 400-499
const retryFunction = (failureCount: number, error: any) => {
	if (error.response && error.response.status >= 400 && error.response.status < 500) {
		return false;
	}

	return failureCount < 3;
};

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: retryFunction,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			staleTime: Infinity,
		},
		mutations: {
			retry: retryFunction,
		},
	},
});

export default queryClient;
