import React, { useCallback, useRef, useState } from 'react';
import Modal from '@/components/ui/Modal/Modal.tsx';
import Column from '@/components/ui/Column/Column.tsx';
import ModalFooter from '@/components/ui/ModalFooter/ModalFooter.tsx';
import CopyClipboard from '@/assets/Icons/Copy-Clipboard.svg?react';
import styles from './LearnMoreModal.module.scss';
import { Paragraph } from '@/components/WelcomeFinishModal/WelcomeFinishModal.tsx';
import DirectFlight from '@/assets/Illustrations/Direct-Flight.svg';
import classNames from 'classnames';
import Toast, { ToastProps } from '@/components/ui/Toast/Toast.tsx';
import { useWhoamiStore } from '@/store/useWhoamiStore';
import { Step } from '@/constants/learnMoreModalData.tsx';
import { Roles } from '@/api/user.ts';

const renderParagraph = (paragraph: Paragraph[]) => {
	return paragraph?.map(({ title, desc }, key) => (
		<div key={key}>
			{title && <h4>{title}</h4>}
			<p>{desc}</p>
		</div>
	));
};

interface LearnMoreModalProps {
	name: string;
	steps: Step[];
}

const LearnMoreModal: React.FC<LearnMoreModalProps> = ({ steps, name }) => {
	const copyElement = useRef<HTMLDivElement>(null);
	const [currentStep, setCurrentStep] = useState<number>(0);
	const [toastProps, setToastProps] = useState<ToastProps>({ message: '', type: 'success', show: false });

	const handleNext = () => {
		if (currentStep < steps.length - 1) {
			setCurrentStep(prevStep => prevStep + 1);
		}
	};

	const handlePrevious = () => {
		if (currentStep > 0) {
			setCurrentStep(prevStep => prevStep - 1);
		}
	};

	const handleToastClose = useCallback(() => {
		setToastProps(prev => ({ ...prev, show: false }));
	}, []);

	const copyRichTextToClipboard = () => {
		const richTextElement = copyElement.current;

		if (!richTextElement) return;

		const clonedElement = richTextElement.cloneNode(true) as HTMLElement;

		document.body.appendChild(clonedElement);

		const richText = clonedElement.innerText;

		const showSuccessToast = () => {
			setToastProps({ message: 'Successfully copied', type: 'success', show: true });
		};

		const showErrorToast = () => {
			setToastProps({ message: 'Message can not be copied', type: 'error', show: true });
		};

		try {
			const range = document.createRange();
			range.selectNodeContents(clonedElement);

			const selection = window.getSelection();
			if (selection) {
				selection.removeAllRanges();
				selection.addRange(range);

				document.execCommand('copy');

				selection.removeAllRanges();
				showSuccessToast();
			}
		} catch (err) {
			if (navigator.clipboard) {
				navigator.clipboard.writeText(richText).then(showSuccessToast).catch(showErrorToast);
			} else {
				showErrorToast();
			}
		} finally {
			document.body.removeChild(clonedElement);
		}
	};

	const { user } = useWhoamiStore();
	const isCompanyAdmin = user?.spotnanaRole === Roles.CompanyAdmin || user?.spotnanaRole === Roles.TmcAdmin;
	const isButtonAdminRestriction = steps[currentStep]?.showButtonOnlyForAdmin || false;
	const showRightButton = isButtonAdminRestriction ? isCompanyAdmin : true;

	if (!steps) {
		return null;
	}

	return (
		<Modal name={name} className={styles.modalWrapper}>
			<div className={styles.contentContainer}>
				<div className={styles.contentWrapper}>
					<Column
						className={classNames(
							styles.illustrationWrapper,
							steps[currentStep].illustration === DirectFlight,
						)}
					>
						<img src={steps[currentStep].illustration} />
					</Column>
					<Column className={styles.textWrapper}>
						<h3>{steps[currentStep].header}</h3>
						{steps[currentStep].leadingParagraph && renderParagraph(steps[currentStep].leadingParagraph)}
						{steps[currentStep].img && <img src={steps[currentStep].img} />}
						{steps[currentStep].stylizedBox && (
							<div className={styles.stylizedBox}>
								<p className={styles.title}>{steps[currentStep].stylizedBox.title}</p>
								<div ref={copyElement} className={styles.copyToClpText}>
									{steps[currentStep].stylizedBox.desc}
								</div>
							</div>
						)}
						{steps[currentStep].endingParagraph && renderParagraph(steps[currentStep].endingParagraph)}
					</Column>
				</div>
			</div>
			{showRightButton && (
				<ModalFooter
					leftButton={{
						text: 'Back',
						hide: currentStep === 0,
						onClick: () => handlePrevious(),
					}}
					rightButton={{
						hide: !showRightButton,
						text: steps[currentStep].buttonText,
						onClick: () => {
							if (steps[currentStep].buttonAction === 'next') {
								handleNext();
							} else if (steps[currentStep].buttonAction === 'copy') {
								copyRichTextToClipboard();
							} else if (
								typeof steps[currentStep].buttonAction === 'object' &&
								'href' in steps[currentStep].buttonAction &&
								steps[currentStep].buttonAction.href
							) {
								window.open(steps[currentStep].buttonAction.href, '_blank');
							}
						},
						...(steps[currentStep].buttonAction === 'copy' && {
							rightIcon: <CopyClipboard />,
						}),
					}}
					className={styles.modalFooter}
				/>
			)}
			<Toast
				message={toastProps.message}
				type={toastProps.type}
				onClose={handleToastClose}
				show={toastProps.show}
			/>
		</Modal>
	);
};

export default LearnMoreModal;
