import React, { useState, useEffect } from 'react';
import { DateInput, DateSegment, TimeField } from 'react-aria-components';
import classNames from 'classnames';
import { FocusEventHandler } from 'react';
import styles from './TimeInput.module.scss';
import { parseTime, Time } from '@internationalized/date';

export interface TimeInputProps {
	label?: string;
	disabled?: boolean;
	className?: string;
	onChange?: (value: string) => void;
	onBlur?: FocusEventHandler | undefined;
	value: string;
	name?: string;
	errorMessage?: string;
	touched?: boolean;
}

const TimeInput: React.FC<TimeInputProps> = ({
	label,
	disabled,
	onChange,
	value,
	name,
	onBlur,
	errorMessage,
	touched,
	className,
}) => {
	const [timeValue, setTimeValue] = useState<Time | null | undefined>(null);

	useEffect(() => {
		if (value) {
			try {
				setTimeValue(parseTime(value));
			} catch (e) {
				setTimeValue(null);
			}
		}
	}, [value]);

	const handleChange = (newValue: Time | null) => {
		setTimeValue(newValue);
		if (onChange && newValue) {
			const hours = newValue.hour.toString().padStart(2, '0');
			const minutes = newValue.minute.toString().padStart(2, '0');
			onChange(`${hours}:${minutes}:00`);
		}
	};

	const labelId = `${name}-label`;

	return (
		<TimeField
			name={name}
			onBlur={onBlur}
			value={timeValue}
			onChange={handleChange}
			isDisabled={disabled}
			className={classNames(styles.timeField, className)}
			aria-labelledby={label ? labelId : undefined}
			aria-label={!label ? 'Time input' : undefined}
		>
			<span className={styles.label} id={labelId}>
				{label}
			</span>
			<DateInput className={styles.dateInput}>{segment => <DateSegment segment={segment} />}</DateInput>
			{touched && errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
		</TimeField>
	);
};

export default TimeInput;
