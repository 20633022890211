import Card from '@/components/ui/Card/Card';
import styles from './VideoCard.module.scss';
import { externalLinks } from '@/constants/externalLinks';

const VideoCard = () => (
	<Card className={styles.videoCard}>
		<iframe
			src={externalLinks.reforestationVideo}
			title="YouTube video player"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
		/>
	</Card>
);

export default VideoCard;
