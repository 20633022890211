import React, { ReactElement } from 'react';
import styles from './SelectHeader.module.scss';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import breakpoints from '@/constants/breakpoints.ts';

interface SelectHeaderProps {
	icon: ReactElement;
	title: string;
	text: string;
}

const SelectHeader: React.FC<SelectHeaderProps> = ({ icon, title, text }) => {
	return (
		<FlexBlock
			flexDirection={{
				[breakpoints.zero]: 'column',
				[breakpoints.tablet]: 'row',
			}}
		>
			<div className={styles.headerImg}>{icon}</div>
			<div className={styles.headerContent}>
				<h2>{title}</h2>
				<p>{text}</p>
			</div>
		</FlexBlock>
	);
};

export default SelectHeader;
