import styles from './Button.module.scss';
import { FC, ReactElement, ReactNode, useCallback } from 'react';
import classNames from 'classnames';
import Spinner from '@/components/ui/Spinner/Spinner.tsx';
import { capitalize } from 'lodash';

export interface ButtonProps {
	size?: 'small' | 'medium' | 'large';
	color?: 'default' | 'grey' | 'green' | 'transparent';
	align?: 'left' | 'center' | 'right';
	borderRadius?: 'small' | 'medium' | 'large';
	fullWidth?: boolean;
	disabled?: boolean;
	loading?: boolean;
	leftIcon?: ReactElement;
	rightIcon?: ReactElement;
	onClick?: () => void;
	children: ReactNode;
	className?: string;
	type?: 'button' | 'submit';
	hover?: boolean;
}

const Button: FC<ButtonProps> = ({
	size = 'medium',
	color = 'default',
	align = 'left',
	borderRadius = 'small',
	fullWidth = false,
	disabled = false,
	loading,
	leftIcon = undefined,
	rightIcon = undefined,
	onClick,
	children,
	type = 'button',
	className,
	hover = true,
}) => {
	const handleClick = useCallback(() => {
		!loading && !disabled && onClick && onClick();
	}, [disabled, loading, onClick]);

	const borderRadiusMapping: { [key in NonNullable<ButtonProps['borderRadius']>]: number } = {
		small: 5,
		medium: 15,
		large: 25,
	};

	return (
		<button
			className={classNames(
				styles.wrapper,
				styles[`size${capitalize(size)}`],
				styles[color],
				styles[align],
				fullWidth && styles.fullWidth,
				loading && styles.loading,
				disabled && styles.disabled,
				hover && styles.hover,
				className,
			)}
			onClick={handleClick}
			type={type}
			disabled={disabled}
			style={{
				borderRadius: borderRadiusMapping[borderRadius],
			}}
		>
			{!!leftIcon && leftIcon}

			{loading ? <Spinner /> : children}

			{!!rightIcon && rightIcon}
		</button>
	);
};

export default Button;
