import { omit } from 'lodash';
import React, { FC, SyntheticEvent, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

interface ImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
	fallbackSrc: string;
}

const Image: FC<ImageProps> = ({ src, fallbackSrc, ...rest }) => {
	const [isImageLoaded, setIsImageLoaded] = useState(false);

	const handleError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
		e.currentTarget.src = fallbackSrc || '';
	};

	const handleLoad = () => {
		setIsImageLoaded(true);
	};

	return (
		<>
			{!isImageLoaded && <Skeleton width="100%" height="100%" />}
			<img
				src={src}
				onError={handleError}
				onLoad={handleLoad}
				style={{ display: isImageLoaded ? 'block' : 'none' }}
				{...omit(rest, ['fallbackUrl'])}
			/>
		</>
	);
};

export default Image;
