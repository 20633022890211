import React from 'react';
import ButtonLink from '@/components/ui/ButtonLink/ButtonLink.tsx';
import { externalLinks } from '@/constants/externalLinks.ts';
import MainLayout from '@/layouts/MainLayout/MainLayout.tsx';
import Container from '@/components/ui/Container/Container.tsx';
import styles from './NoOrganization.module.scss';

const NoOrganization: React.FC = () => {
	return (
		<MainLayout>
			<Container>
				<div className={styles.noOrganizationContainer}>
					<h1>Oops! There seems to be an issue with your authentication.</h1>
					<p>
						We apologize for the inconvenience, but it appears that your organization does not exist in our
						records. If you believe this is an oversight or you're interested in joining our platform,
						please consider scheduling a demo call with us to explore membership options. If you have any
						other concerns or need further clarification, our customer service team is here to assist you.
					</p>
					<ButtonLink href={externalLinks.bookADemo} color="green" size="large">
						Book a demo
					</ButtonLink>
				</div>
			</Container>
		</MainLayout>
	);
};

export default NoOrganization;
