import React, { useState } from 'react';
import styles from './ProfileMenu.module.scss';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';
import LeftArrowIcon from '@/assets/Icons/Left-Arrow-Extended.svg?react';
import MenuIcon from '@/assets/Icons/Hamburger-Menu.svg?react';
import { Popover } from 'react-tiny-popover';
import { useNavigate } from 'react-router';
import routes from '@/constants/routes.ts';
import { Link } from 'react-router-dom';
import breakpoints from '@/constants/breakpoints.ts';
import { useWindowSize } from '@react-hook/window-size';
import classNames from 'classnames';
import { Roles } from '@/api/user.ts';
import CloseIcon from '@/assets/Icons/Close.svg?react';
import {
	companyAdminLinks,
	companyTravelArrangerLinks,
	tmcAdminLinks,
	tmcAgentLinks,
	travelArrangerLinks,
	userLinks,
} from '@/constants/sidebarLinks.ts';
import { commonLinks } from '@/constants/headerLinks.tsx';

interface LinkItem {
	to: string;
	label: string;
}

const ProfileMenu: React.FC = () => {
	const [width] = useWindowSize();
	const user = useWhoamiStore(state => state.user);
	const navigate = useNavigate();
	const [isPopupShown, setIsPopupShown] = useState<boolean>(false);
	const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

	const initials = `${user?.firstName?.[0] || ''}${user?.lastName?.[0] || ''}`;

	const roleBasedMenus: { [key in Roles]?: LinkItem[] } = {
		[Roles.TmcAdmin]: tmcAdminLinks,
		[Roles.TmcAgent]: tmcAgentLinks,
		[Roles.CompanyAdmin]: companyAdminLinks,
		[Roles.CompanyTravelArranger]: companyTravelArrangerLinks,
		[Roles.TravelArranger]: travelArrangerLinks,
	};

	const links =
		user && user.spotnanaRole && roleBasedMenus[user.spotnanaRole] ? roleBasedMenus[user?.spotnanaRole] : userLinks;

	const renderPopoverContent = () => (
		<div className={styles.popover}>
			<span className={styles.firstItem}>{user?.firstName}</span>
			{commonLinks.map(link => (
				<Link key={link.label} to={link.to} className={styles.linkItem}>
					{link.label}
				</Link>
			))}
			<div className={styles.signoutItem} onClick={() => navigate(routes.logout)}>
				<LeftArrowIcon />
				<span>Sign out</span>
			</div>
		</div>
	);

	return (
		<div className={styles.profileMenuWrapper}>
			{width > breakpoints.tabletWide ? (
				<Popover
					isOpen={isPopupShown}
					onClickOutside={() => setIsPopupShown(false)}
					positions={['bottom', 'right']}
					align="end"
					padding={20}
					transform={{ top: -70, left: 10 }}
					transformMode="relative"
					reposition={false}
					content={renderPopoverContent()}
				>
					<div className={styles.initialsWrapper}>
						<div className={styles.initials} onClick={() => setIsPopupShown(!isPopupShown)}>
							{initials}
						</div>
						<div className={styles.tooltip}>Profile and settings</div>
					</div>
				</Popover>
			) : (
				<div className={classNames(styles.sideMenuWrapper, isSideMenuOpen && styles.visibleSideMenu)}>
					<div className={styles.hamburgerMenu} onClick={() => setIsSideMenuOpen(!isSideMenuOpen)}>
						<MenuIcon />
					</div>
					<div className={styles.sideMenu}>
						<div className={styles.overlay} onClick={() => setIsSideMenuOpen(false)}></div>
						<div className={styles.links}>
							<div className={classNames(styles.initialsWrapper, styles.popoverMenu)}>
								<div className={styles.nameWrapper}>
									<div className={styles.initials} onClick={() => setIsPopupShown(!isPopupShown)}>
										{initials}
									</div>
									<p>
										{user?.firstName || ''} {user?.lastName || ''}
									</p>
								</div>
								<button onClick={() => setIsSideMenuOpen(false)}>
									<CloseIcon />
								</button>
							</div>
							{!!links &&
								links.map(link => (
									<Link key={link.label} to={link.to} className={styles.linkItem}>
										{link.label}
									</Link>
								))}
							<div
								className={classNames(styles.signoutItem, styles.popoverMenu)}
								onClick={() => navigate(routes.logout)}
							>
								<span>Sign out</span>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ProfileMenu;
