import { useWindowWidth } from '@react-hook/window-size';
import breakpoints from '@/constants/breakpoints.ts';

export type ResponsiveValue<T> = Partial<Record<keyof typeof breakpoints, T>> | T | undefined;

/**
 * Custom hook to retrieve a value based on the current window width and specified breakpoints.
 *
 * @param {ResponsiveValue<T>} breakpointValues - An object with breakpoints (screen widths as numbers) as keys and corresponding values for those breakpoints, or a single value.
 * @returns {T | undefined} - The value corresponding to the current window width, or undefined if no matching breakpoint is found.
 */
const useResponsiveValue = <T>(breakpointValues: ResponsiveValue<T> | undefined): T | undefined => {
	// Retrieve the current window width
	const currentWindowWidth = useWindowWidth();

	// Return undefined if no breakpointValues object is provided
	if (!breakpointValues) return undefined;

	// Return the provided value if it's not an object with breakpoints
	if (typeof breakpointValues !== 'object') {
		return breakpointValues;
	}

	// Convert breakpoint keys to numbers and sort them in descending order
	const sortedBreakpoints = Object.keys(breakpointValues)
		.map(Number)
		.sort((a, b) => b - a);

	// Find the first breakpoint that is less than or equal to the current window width
	const matchingBreakpoint = sortedBreakpoints.find(breakpoint => currentWindowWidth >= breakpoint);

	// Return the corresponding value or undefined if no match is found
	return matchingBreakpoint !== undefined
		? (breakpointValues as Partial<Record<number, T>>)[matchingBreakpoint]
		: undefined;
};

export default useResponsiveValue;
