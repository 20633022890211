import React from 'react';
import { useField } from 'formik';
import AutocompletePlaceInput, {
	AutocompletePlaceInputProps,
} from '@/components/ui/AutocompletePlaceInput/AutocompletePlaceInput.tsx';

interface FormikAutocompletePlaceInputProps
	extends Omit<AutocompletePlaceInputProps, 'value' | 'onChange' | 'errorMessage' | 'touched'> {
	name: string;
}

const FormikAutocompletePlaceInput: React.FC<FormikAutocompletePlaceInputProps> = ({ name, fetchFn, ...props }) => {
	const [{ value, onBlur }, { error, touched }, { setValue }] = useField(name);

	return (
		<AutocompletePlaceInput
			{...props}
			name={name}
			onBlur={onBlur}
			value={value}
			onChange={setValue}
			errorMessage={error}
			touched={touched}
			fetchFn={fetchFn}
		/>
	);
};

export default FormikAutocompletePlaceInput;
