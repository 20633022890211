import styles from './ChartToolTip.module.scss';
import { TooltipProps } from 'recharts';

interface ChartTooltipProps extends TooltipProps<any, any> {
	extralabel?: string;
	labelMap: {
		[key: string]: {
			color?: string;
			dataKey?: string;
			label: string;
		};
	};
}

const ChartTooltip: React.FC<ChartTooltipProps> = ({ payload, label, extralabel, labelMap }) => {
	if (payload && payload.length > 0) {
		return (
			<div className={styles.tooltip}>
				<div className={styles.tooltipLabel}>{label}</div>
				{payload.map(areaProps => {
					const { name, value, stroke } = areaProps;
					const areaLabel = labelMap?.[name]?.label;
					return (
						<div className={styles.tooltipItem} key={name}>
							<svg width="15" height="20" viewBox="0 0 20 20">
								<rect x="1" y="1" width="18" height="18" rx="4" ry="4" style={{ fill: stroke }} />
							</svg>
							<span style={{ marginLeft: '10px', color: '#000' }}>
								<span style={{ textTransform: 'capitalize' }}>{areaLabel} </span>-{' '}
								<strong>{value && +value.toFixed(2)}</strong> {extralabel ? extralabel : ''}
							</span>
						</div>
					);
				})}
			</div>
		);
	}
	return null;
};

export default ChartTooltip;
