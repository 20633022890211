import React from 'react';
import styles from './UnsupportedBrowser.module.scss';
import Computer from './../assets/Images/Computer-sad.png';
import GWStaticLogo from './../assets/Images/Gw-static-logo.png';
import EdgeBrowser from './../assets/Images/Edge-browser.png';
import ChromeBrowser from './../assets/Images/Chrome-browser.png';
import FirefoxBrowser from './../assets/Images/Firefox-browser.png';

const UnsupportedBrowser: React.FC = () => {
	return (
		<div className={styles.mainWrapper}>
			<div className={styles.main}>
				<div className={styles.top}>
					<img src={GWStaticLogo} alt="Logo" />
				</div>
				<div className={styles.bottom}>
					<img className={styles.computer} src={Computer} alt="Computer" />
					<h1>Internet Explorer is not supported</h1>
					<p>
						We're very sorry, but Goodwings does not support Internet Explorer. We recommend that you try a
						different browser. If you can't download or install a new browser, please contact your system
						administrator.
					</p>
					<div className={styles.browsers}>
						<a href="https://www.microsoft.com/en-us/edge">
							<img src={EdgeBrowser} alt="Edge" />
						</a>
						<a href="https://www.google.com/chrome/">
							<img src={ChromeBrowser} alt="Chrome" />
						</a>
						<a href="https://www.mozilla.org/en-US/firefox/new/">
							<img src={FirefoxBrowser} alt="Firefox" />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UnsupportedBrowser;
