import React, { useEffect, useRef } from 'react';
import { toast, ToastOptions, ToastPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export interface ToastProps {
	message: string;
	type?: 'success' | 'error' | 'info' | 'warning';
	theme?: 'colored' | 'dark' | 'light';
	position?: ToastPosition;
	autoClose?: number | false;
	hideProgressBar?: boolean;
	closeOnClick?: boolean;
	pauseOnHover?: boolean;
	draggable?: boolean;
	onClose?: () => void;
	show: boolean;
}

const Toast: React.FC<ToastProps> = ({
	message,
	type = 'success',
	theme = 'colored',
	position = 'bottom-right',
	autoClose = 4000,
	hideProgressBar = true,
	closeOnClick = true,
	pauseOnHover = false,
	draggable = false,
	onClose,
	show,
}) => {
	const toastIdRef = useRef<string | number | null>(null);

	useEffect(() => {
		if (show && toastIdRef.current === null) {
			const toastOptions: ToastOptions = {
				position,
				theme,
				autoClose,
				hideProgressBar,
				closeOnClick,
				pauseOnHover,
				draggable,
				onClose: () => {
					onClose && onClose();
					toastIdRef.current = null;
				},
			};

			switch (type) {
				case 'success':
					toastIdRef.current = toast.success(message, toastOptions);
					break;
				case 'error':
					toastIdRef.current = toast.error(message, toastOptions);
					break;
				case 'info':
					toastIdRef.current = toast.info(message, toastOptions);
					break;
				case 'warning':
					toastIdRef.current = toast.warning(message, toastOptions);
					break;
				default:
					toastIdRef.current = toast(message, toastOptions);
			}
		} else if (!show && toastIdRef.current !== null) {
			toast.dismiss(toastIdRef.current);
			toastIdRef.current = null;
		}

		return () => {
			if (toastIdRef.current !== null) {
				toast.dismiss(toastIdRef.current);
			}
		};
	}, [show, message, type, position, autoClose, hideProgressBar, closeOnClick, pauseOnHover, draggable, onClose]);

	return null;
};

export default Toast;
