import React from 'react';
import { useField } from 'formik';
import TimeInput, { TimeInputProps } from '@/components/ui/TimeInput/TimeInput';

interface FormikTimeInputProps extends Omit<TimeInputProps, 'value' | 'onChange' | 'errorMessage' | 'touched'> {
	name: string;
}

const FormikTimeInput: React.FC<FormikTimeInputProps> = ({ name, ...props }) => {
	const [field, meta, helpers] = useField(name);

	return (
		<TimeInput
			{...props}
			name={name}
			value={field.value}
			onChange={value => helpers.setValue(value)}
			onBlur={field.onBlur}
			errorMessage={meta.error}
			touched={meta.touched}
		/>
	);
};

export default FormikTimeInput;
