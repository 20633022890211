import CardWithImage from '@/components/ui/CardWithImage/CardWithImage';
import { externalLinks } from '@/constants/externalLinks';
import { Link } from 'react-router-dom';
import styles from './LearnHowToTravelCard.module.scss';
import LearnHowToTravelIllustration from '@/assets/Illustrations/Learn-How-To-Travel.svg?react';

const LearnHowToTravelCard = () => (
	<CardWithImage
		reverse
		illustration={<LearnHowToTravelIllustration />}
		header={'Learn how to travel with a smaller carbon footprint'}
		button={
			<Link to={externalLinks.learnMoreAboutSustainableTravel} target="_blank" rel="noopener noreferrer">
				Learn more
			</Link>
		}
		className={styles.learnHowToTravelCard}
	/>
);

export default LearnHowToTravelCard;
