import React, { useEffect, useRef, useState } from 'react';
import Container from '@/components/ui/Container/Container.tsx';
import Card from '@/components/ui/Card/Card.tsx';
import styles from './UpcomingTrips.module.scss';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from '@tanstack/react-query';
import { getPlacesToRelax, getSuggestedRestaurants, getTransportationToHotel, getUpcomingTrips } from '@/api/trips.ts';
import MarkerPinIcon from '@/assets/Icons/Marker-Pin.svg?react';
import { chunk, isEmpty, last, upperFirst } from 'lodash';
import queryKeys from '@/constants/queryKeys.ts';
import Button from '@/components/ui/Button/Button.tsx';
import SearchIcon from '@/assets/Icons/Search.svg?react';
import { searchCity, searchLodging } from '@/api/search.ts';
import FormikAutocompletePlaceInput from '@/components/Formik/FormikAutocompletePlaceInput.tsx';
import { Form, FormikProvider, useFormik } from 'formik';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';
import FallbackImage from '@/assets/Images/Fallback-Image.png';
import RestaurantFallbackImage from '@/assets/Images/Restaurant-Placeholder.jpeg';
import Image from '@/components/ui/Image/Image.tsx';
import classNames from 'classnames';
import Accordion from '@/components/ui/Accordion/Accordion.tsx';
import KnifeForkIcon from '@/assets/Icons/Knife-Fork.svg?react';
import MapPreview from '@/components/MapPreview/MapPreview.tsx';
import ContentCard from '@/pages/Dashboard/UpcomingTrips/ContentCard.tsx';
import breakpoints from '@/constants/breakpoints.ts';
import MapModal from '@/pages/Dashboard/UpcomingTrips/MapModal.tsx';
import { useModalStore } from '@/store/useModalStore.ts';
import TrainIcon from '@/assets/Icons/Train.svg?react';
import ManWalkingIcon from '@/assets/Icons/Man-Walking.svg?react';
import BikeIcon from '@/assets/Icons/Bike.svg?react';
import CarIcon from '@/assets/Icons/Car.svg?react';
import { formatDistance, formatDuration } from '@/utils/stringUtils.ts';
import moment from 'moment';
import GoogleDirectionsRouteData = App.Data.GoogleDirectionsRouteData;
import TransportationMode = App.Enums.TransportationMode;
import RestaurantData = App.Data.RestaurantData;
import TripPlace = App.Data.TripPlace;

interface Marker {
	lat: number;
	lng: number;
}

interface MapModalData {
	route?: GoogleDirectionsRouteData;
	markers?: Marker[];
}

const getIconByMode = (mode: TransportationMode) => {
	const icons = {
		transit: <TrainIcon />,
		walking: <ManWalkingIcon />,
		driving: <CarIcon />,
		bicycling: <BikeIcon />,
	};

	return icons[mode] || null;
};

const dynamicRefetchInterval = (intervalMs: number) => {
	return (data: { state?: { data?: { complete?: boolean } } }) => {
		return !data?.state?.data?.complete ? intervalMs : false;
	};
};

const UpcomingTrips: React.FC = () => {
	const accordionRef = useRef<HTMLDivElement>(null);
	const { openModal } = useModalStore();

	// Currently selected TripPlace (from the list of cities on the left side, listed from API)
	const [selectedPlace, setSelectedPlace] = useState<TripPlace | null>(null);

	// Stores the Google Place ID of the region or the hotel Google Place ID (autocomplete, on the right side)
	const [chosenGooglePlaceId, setChosenGooglePlaceId] = useState<string | null>(null);

	// Tracks whether the "Resources" accordion is open or closed.
	const [isResourcesAccordionOpen, setIsResourcesAccordionOpen] = useState(false);

	// Currently selected transportation mode, used in "Transportation from airport" section
	const [chosenTransportationMode, setChosenTransportationMode] = useState<TransportationMode | null>(null);

	// Pagination page index for "Restaurants" and "Places to Relax"
	const [restaurantsPage, setRestaurantsPage] = useState(0);
	const [placesToRelaxPage, setPlacesToRelaxPage] = useState(1);

	// Used to simulate loading state and to show skeleton (when the API request/JS code is executed almost instantly)
	const [simulateRestaurantsLoading, setSimulateRestaurantsLoading] = useState(false);
	const [simulatePlacesToRelaxLoading, setSimulatePlacesToRelaxLoading] = useState(false);

	// Map modal data, shown within sections: "Transportation from airport", "Restaurants" and "Places to Relax"
	const [mapModalData, setMapModalData] = useState<MapModalData>({});

	const {
		data: upcomingTrips,
		isLoading: upcomingTripsLoading,
		isError: upcomingTripsError,
		isSuccess: upcomingTripsSuccess,
	} = useQuery({
		queryKey: [queryKeys.upcomingTrips],
		queryFn: getUpcomingTrips,
		refetchInterval: dynamicRefetchInterval(1000),
	});

	const {
		data: suggestedRestaurants,
		isLoading: suggestedRestaurantsLoading,
		isSuccess: suggestedRestaurantsSuccess,
		refetch: suggestedRestaurantsRefetch,
	} = useQuery({
		queryKey: [queryKeys.suggestedRestaurants],
		queryFn: () =>
			getSuggestedRestaurants({
				coordinates: selectedPlace?.coordinates || null,
				googlePlaceId: chosenGooglePlaceId!,
			}),
		enabled: !!selectedPlace || !!chosenGooglePlaceId,
		refetchInterval: dynamicRefetchInterval(1000),
	});

	const {
		data: suggestedPlacesToRelax,
		isLoading: suggestedPlacesToRelaxLoading,
		isSuccess: suggestedPlacesToRelaxSuccess,
		refetch: suggestedPlacesToRelaxRefetch,
	} = useQuery({
		queryKey: [queryKeys.suggestedPlacesToRelax],
		queryFn: () =>
			getPlacesToRelax({
				coordinates: selectedPlace?.coordinates || null,
				googlePlaceId: chosenGooglePlaceId!,
				page: placesToRelaxPage,
			}),
		enabled: !!selectedPlace || !!chosenGooglePlaceId,
		refetchInterval: dynamicRefetchInterval(3000),
	});

	const {
		data: transportationData,
		isLoading: transportationDataLoading,
		refetch: transportationDataRefetch,
	} = useQuery({
		queryKey: [queryKeys.transportationToHotel],
		queryFn: () =>
			getTransportationToHotel({
				coordinates: selectedPlace!.coordinates,
				airportIataCode: selectedPlace?.iataAirportCode || '',
			}),
		enabled: !!selectedPlace && !!selectedPlace?.iataAirportCode,
		refetchInterval: dynamicRefetchInterval(1000),
	});

	// Paginate restaurants and get the current selected page slice
	const restaurantsPerPage = 3;
	const displayedRestaurants: RestaurantData[] = chunk(suggestedRestaurants?.restaurants || [], restaurantsPerPage)[restaurantsPage] || [];

	// Get final loading states of "Restaurants" and "Places to Relax" (including both backend and simulated loadings)
	const isRestaurantsLoading = simulateRestaurantsLoading || suggestedRestaurantsLoading;
	const isPlacesToRelaxLoading = simulatePlacesToRelaxLoading || suggestedPlacesToRelaxLoading;

	const formik = useFormik({
		initialValues: {
			city: undefined,
			hotel: undefined,
		},
		onSubmit: values => {
			resetSearchStates();
			setChosenGooglePlaceId(values.hotel || values.city || null);
		},
	});

	const { values, setFieldValue } = formik;

	const handlePageChange = async (section: string, direction: 'next' | 'previous') => {
		const isNext = direction === 'next';

		if (section === 'restaurants') {
			setSimulateRestaurantsLoading(true);
			setTimeout(() => {
				setSimulateRestaurantsLoading(false);

				if (isNext) {
					if (suggestedRestaurants?.restaurants && last(displayedRestaurants) !== last(suggestedRestaurants?.restaurants)) {
						setRestaurantsPage(restaurantsPage + 1);
					}
				} else {
					if (restaurantsPage > 0) {
						setRestaurantsPage(restaurantsPage - 1);
					}
				}
			}, 500);
		} else if (section === 'placesToRelax') {
			if (isNext || (!isNext && placesToRelaxPage > 1)) {
				setSimulatePlacesToRelaxLoading(true);
				setPlacesToRelaxPage(isNext ? placesToRelaxPage + 1 : placesToRelaxPage - 1);
				setTimeout(() => setSimulatePlacesToRelaxLoading(false), 300);
			}
		}
	};

	// Used in map modal within "Restaurants" and "Places to Relax" sections
	const handleOpenMapWithMarker = (marker: Marker) => {
		setMapModalData({ markers: [marker] });
		openModal('mapModal');
	};

	// Used in map modal within "Transportation from airport" section
	const handleOpenMapWithRoute = (route: GoogleDirectionsRouteData) => {
		setMapModalData({ route });
		openModal('mapModal');
	};

	useEffect(() => {
		if (values.city && values.hotel) {
			setFieldValue('hotel', null);
		}
	}, [setFieldValue, values.city]);

	useEffect(() => {
		if (selectedPlace || chosenGooglePlaceId) {
			suggestedPlacesToRelaxRefetch();
		}
	}, [placesToRelaxPage]);

	const resetSearchStates = () => {
		setChosenGooglePlaceId(null);
		setSelectedPlace(null);
	};

	const loadResources = async () => {
		if (!selectedPlace && !chosenGooglePlaceId) {
			setIsResourcesAccordionOpen(false);
			return;
		}

		await Promise.all([
			!!selectedPlace && !!selectedPlace?.iataAirportCode && transportationDataRefetch(),
			suggestedRestaurantsRefetch(),
			suggestedPlacesToRelaxRefetch(),
		]);

		if (transportationData?.modes) {
			setChosenTransportationMode(Object.keys(transportationData.modes)[0] as TransportationMode);
		}

		if (isResourcesAccordionOpen) {
			setSimulateRestaurantsLoading(true);
			setTimeout(() => setSimulateRestaurantsLoading(false), 500);
		} else {
			setIsResourcesAccordionOpen(true);

			if (accordionRef.current) {
				accordionRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		}
	};

	useEffect(() => {
		loadResources();
	}, [selectedPlace, chosenGooglePlaceId, transportationData]);

	return (
		<Container>
			<Card className={classNames(styles.cardWrapper, upcomingTripsSuccess && isEmpty(upcomingTrips?.places) && styles.noTrips)}>
				<div
					className={classNames(styles.upcomingTripsInfoWrapper, upcomingTripsSuccess && isEmpty(upcomingTrips?.places) && styles.noTrips)}
				>
					<div className={styles.upcomingTripsInfo}>
						<h2 className={styles.headerTitle}>Sustainable inspiration for your trip!</h2>
						<div className={styles.upcomingTripsCardsWrapper}>
							{upcomingTripsLoading ? (
								<FlexBlock flexDirection="column" flex="1">
									<div>
										<Skeleton
											width="360px"
											height="100%"
											containerClassName={classNames(styles.skeletonTripCard, styles.title)}
										/>
									</div>
									<FlexBlock
										columnGap="20px"
										rowGap="15px"
										flex="1"
										flexDirection={{
											[breakpoints.zero]: 'column',
											[breakpoints.phoneWide]: 'row',
										}}
									>
										<Skeleton width="100%" height="100%" containerClassName={styles.skeletonTripCard} />
										<Skeleton width="100%" height="100%" containerClassName={styles.skeletonTripCard} />
									</FlexBlock>
								</FlexBlock>
							) : isEmpty(upcomingTrips?.places) ? (
								upcomingTripsError ? (
									<span className={styles.errorMessage}>Oops! We're encountering an issue retrieving your trips.</span>
								) : (
									<></>
								)
							) : (
								<div className={styles.upcomingTripsCards}>
									<p className={styles.carouselTitle}>Choose from your upcoming destinations</p>
									<div className={styles.carouselWrapper}>
										{Array.isArray(upcomingTrips?.places) &&
											upcomingTrips?.places.map((place: TripPlace, index) => {
												return (
													<div className={styles.carouselCard} key={index}>
														<div className={styles.imageWrapper}>
															<Image src={place?.cityImageUrl || ''} fallbackSrc={FallbackImage} />
														</div>

														<div className={styles.cardDesc}>
															<p className={styles.city}>{place.city}</p>
															<div className={styles.location}>
																<MarkerPinIcon />
																{place.city}, {place.country}
															</div>
															<Button
																color="green"
																size="medium"
																fullWidth
																onClick={() => {
																	resetSearchStates();
																	setSelectedPlace(place);
																}}
															>
																Choose
															</Button>
														</div>
													</div>
												);
											})}
									</div>
								</div>
							)}
							{((upcomingTripsSuccess && !isEmpty(upcomingTrips?.places)) || upcomingTripsLoading) && (
								<div className={styles.divider}>
									<div className={styles.dashedLine}></div>
									<span>or</span>
									<div className={styles.dashedLine}></div>
								</div>
							)}
						</div>
					</div>
					<div className={classNames(styles.searchWrapper, upcomingTripsSuccess && isEmpty(upcomingTrips?.places) && styles.noTrips)}>
						<h2>Search for another destination</h2>
						<FormikProvider value={formik}>
							<Form className={styles.form}>
								<FlexBlock
									flexDirection={{
										[breakpoints.zero]: 'column',
										[breakpoints.phoneWide]: isEmpty(upcomingTrips?.places) ? 'row' : 'column',
									}}
								>
									<FormikAutocompletePlaceInput
										name="city"
										placeholder="Enter city"
										fetchFn={query => searchCity(query)}
										className={styles.autocompleteField}
									/>
									<FormikAutocompletePlaceInput
										name="hotel"
										placeholder="Enter hotel (optional)"
										fetchFn={query => searchLodging(values.city || '', query)}
										disabled={!values.city}
										key={values.city}
										className={styles.autocompleteField}
									/>
								</FlexBlock>
								<Button type="submit" size="medium" color="green" disabled={!values.city} leftIcon={<SearchIcon />}>
									Search
								</Button>
							</Form>
						</FormikProvider>
					</div>
				</div>
				<div className={classNames(styles.accordionContent, isResourcesAccordionOpen && styles.isOpen)} ref={accordionRef}>
					{selectedPlace && selectedPlace.iataAirportCode && !chosenGooglePlaceId && upcomingTripsSuccess && (
						<Accordion title="Transportation from airport" leftIcon={<MarkerPinIcon />}>
							<div className={styles.mapAccordion}>
								{!transportationDataLoading && transportationData?.complete ? (
									<>
										<div className={styles.transportation}>
											<div className={styles.transportationModes}>
												{transportationData?.modes &&
													Object.entries(transportationData.modes).map(([mode, data], index) => (
														<div
															className={classNames(
																styles.transportationMode,
																mode === chosenTransportationMode && styles.selected,
															)}
															onClick={() => setChosenTransportationMode(mode as TransportationMode)}
															key={index}
														>
															{getIconByMode(mode as TransportationMode)}

															<h3>{upperFirst(mode)}</h3>

															<p>{formatDuration(data.duration)}</p>
														</div>
													))}
											</div>

											{chosenTransportationMode && transportationData?.modes[chosenTransportationMode]?.steps && (
												<div className={styles.stepsContainer}>
													{getIconByMode(chosenTransportationMode)}

													<ul>
														{transportationData.modes[chosenTransportationMode].steps.length > 0 &&
															transportationData.modes[chosenTransportationMode].steps?.map((step, index) => (
																<li key={index}>
																	{step.text} ({formatDistance(step.distance)}, {formatDuration(step.duration)})
																</li>
															))}
													</ul>
												</div>
											)}
										</div>

										{transportationData ? (
											chosenTransportationMode && (
												<MapPreview
													mode={chosenTransportationMode}
													route={transportationData.modes[chosenTransportationMode]}
													onOpenModal={handleOpenMapWithRoute}
												/>
											)
										) : (
											<Skeleton width="560px" height="320px" />
										)}
									</>
								) : (
									<FlexBlock
										flexDirection={{
											[breakpoints.zero]: 'column',
											[breakpoints.tabletWide]: 'row',
										}}
										justifyContent="space-between"
										columnGap="20px"
										className={styles.transportationSkeletonWrapper}
									>
										<FlexBlock flexDirection="column" rowGap="16px">
											<FlexBlock columnGap="15px">
												<Skeleton width="112px" height="95px" />
												<Skeleton width="112px" height="95px" />
												<Skeleton width="112px" height="95px" />
											</FlexBlock>
											<Skeleton width="552px" height="150px" />
										</FlexBlock>
										<Skeleton width="560px" height="320px" />
									</FlexBlock>
								)}
							</div>
						</Accordion>
					)}
					{(suggestedRestaurantsSuccess || isRestaurantsLoading) && (
						<Accordion title="Eco-friendly places to eat" leftIcon={<KnifeForkIcon />}>
							<FlexBlock
								columnGap="20px"
								flexDirection={{
									[breakpoints.zero]: 'column',
									[breakpoints.tabletWide]: 'row',
								}}
								rowGap="20px"
								alignItems={{
									[breakpoints.zero]: 'center',
									[breakpoints.tabletWide]: 'unset',
								}}
							>
								{isRestaurantsLoading || !suggestedRestaurants?.complete ? (
									<div className={styles.skeletonRestaurantsWrapper}>
										{[...Array(3)].map((_, index) => (
											<Skeleton key={index} width="100%" height="100%" containerClassName={styles.skeletonRestaurantCard} />
										))}
									</div>
								) : (
									displayedRestaurants &&
									displayedRestaurants.length > 0 &&
									displayedRestaurants.map((restaurant, index) => (
										<ContentCard
											key={index}
											image={<Image src={restaurant.photoUrl || ''} fallbackSrc={RestaurantFallbackImage} />}
											title={restaurant.name || ''}
											rating={restaurant.rating}
											expensiveness={restaurant.priceLevel || 0}
											website={restaurant.website}
											tags={restaurant.types}
											description={restaurant?.reviews && restaurant?.reviews.length > 0 ? restaurant?.reviews[0]?.text : null}
											location={restaurant.coordinates}
											googlePlaceId={restaurant.googlePlaceId}
											distance={`${restaurant.distance && formatDistance(restaurant.distance)} from the ${values.hotel || selectedPlace?.hasHotelBooking ? 'hotel' : 'city center'}`}
											reviews={restaurant?.reviews && restaurant?.reviews?.length > 0 ? restaurant.reviews : null}
											onSeeLocationClick={value => handleOpenMapWithMarker(value)}
											arrivalAt={selectedPlace?.arrivalAt ? moment(selectedPlace.arrivalAt) : null}
										/>
									))
								)}
							</FlexBlock>
							<FlexBlock
								flexDirection={{
									[breakpoints.zero]: 'column',
									[breakpoints.phoneWide]: 'row',
								}}
								alignItems="center"
								rowGap="20px"
								className={styles.searchMoreWrapper}
							>
								<Button
									color="grey"
									size="medium"
									onClick={() => handlePageChange('restaurants', 'next')}
									disabled={
										!suggestedRestaurants?.complete ||
										isRestaurantsLoading ||
										last(displayedRestaurants) === last(suggestedRestaurants?.restaurants)
									}
									leftIcon={<SearchIcon />}
								>
									Find more restaurants
								</Button>
								<Button
									color="transparent"
									size="medium"
									onClick={() => handlePageChange('restaurants', 'previous')}
									disabled={!suggestedRestaurants?.complete || isRestaurantsLoading || restaurantsPage === 0}
									className={styles.previousPageBttn}
								>
									Previous page
								</Button>
							</FlexBlock>
						</Accordion>
					)}
					{(suggestedPlacesToRelaxSuccess || isPlacesToRelaxLoading) && (
						<Accordion title="Be the sustainable you - find places to unwind" leftIcon={<MarkerPinIcon />}>
							<FlexBlock
								columnGap="20px"
								flexDirection={{
									[breakpoints.zero]: 'column',
									[breakpoints.tabletWide]: 'row',
								}}
								rowGap="20px"
								alignItems={{
									[breakpoints.zero]: 'center',
									[breakpoints.tabletWide]: 'unset',
								}}
							>
								{!suggestedPlacesToRelaxSuccess || isPlacesToRelaxLoading || !suggestedPlacesToRelax?.complete ? (
									<div className={styles.skeletonRestaurantsWrapper}>
										{[...Array(3)].map((_, index) => (
											<Skeleton key={index} width="100%" height="100%" containerClassName={styles.skeletonRestaurantCard} />
										))}
									</div>
								) : (
									suggestedPlacesToRelax &&
									suggestedPlacesToRelax.placesToRelax.length > 0 &&
									suggestedPlacesToRelax.placesToRelax.map((place, index) => (
										<ContentCard
											key={index}
											image={<Image src={place.photoUrl || ''} fallbackSrc={RestaurantFallbackImage} />}
											title={place.name}
											website={place.website}
											description={place.description}
											location={place.coordinates}
											googlePlaceId={place.googlePlaceId}
											distance={`${formatDistance(place.distance)} from the ${values.hotel || selectedPlace?.hasHotelBooking ? 'hotel' : 'city center'}`}
											onSeeLocationClick={value => handleOpenMapWithMarker(value)}
											arrivalAt={selectedPlace?.arrivalAt ? moment.parseZone(selectedPlace.arrivalAt) : null}
										/>
									))
								)}
							</FlexBlock>
							<FlexBlock alignItems="center" className={styles.searchMoreWrapper}>
								<Button
									color="grey"
									size="medium"
									onClick={() => handlePageChange('placesToRelax', 'next')}
									leftIcon={<SearchIcon />}
									disabled={!suggestedPlacesToRelax?.complete || isPlacesToRelaxLoading}
								>
									Discover more relaxing spots
								</Button>
								<Button
									color="transparent"
									size="medium"
									disabled={!suggestedPlacesToRelax?.complete || isPlacesToRelaxLoading || placesToRelaxPage === 1}
									onClick={() => handlePageChange('placesToRelax', 'previous')}
									className={styles.previousPageBttn}
								>
									Previous page
								</Button>
							</FlexBlock>
						</Accordion>
					)}
				</div>
			</Card>
			{mapModalData && (!!selectedPlace || !!chosenGooglePlaceId) && (
				<MapModal mode={chosenTransportationMode!} route={mapModalData.route} markers={mapModalData.markers} />
			)}
		</Container>
	);
};

export default UpcomingTrips;
