import React, { useState, useRef, useEffect } from 'react';
import styles from './Accordion.module.scss';
import IconDown from '@/assets/Icons/Arrow-Down.svg?react';
import classNames from 'classnames';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock.tsx';

interface AccordionProps {
	title: string;
	children: React.ReactNode;
	leftIcon?: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ title, children, leftIcon }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [height, setHeight] = useState<number | undefined>(0);
	const contentRef = useRef<HTMLDivElement>(null); // The container with dynamic height
	const innerRef = useRef<HTMLDivElement>(null); // The content whose size can change
	const observerRef = useRef<ResizeObserver | null>(null);

	const updateHeight = () => {
		setHeight(innerRef.current?.scrollHeight);
	};

	useEffect(() => {
		if (isOpen) {
			updateHeight(); // Set initial height

			observerRef.current = new ResizeObserver(() => {
				updateHeight();
			});

			if (innerRef.current) {
				observerRef.current.observe(innerRef.current);
			}
		} else {
			setHeight(0);
		}

		return () => {
			if (observerRef.current && innerRef.current) {
				observerRef.current.unobserve(innerRef.current);
				observerRef.current.disconnect();
			}
		};
	}, [isOpen]);

	const toggleAccordion = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className={classNames(styles.accordion, isOpen && styles.open)}>
			<button className={styles.accordionToggle} onClick={toggleAccordion}>
				<FlexBlock alignItems="center">
					{leftIcon && <div className={styles.iconWrapper}>{leftIcon}</div>}
					<span className={styles.title}>{title}</span>
				</FlexBlock>
				<IconDown className={`${styles.chevron} ${isOpen ? styles.chevronOpen : ''}`} />
			</button>
			<div className={styles.accordionContent} style={{ height: height }} ref={contentRef}>
				<div className={styles.accordionInner} ref={innerRef}>
					{children}
				</div>
			</div>
		</div>
	);
};

export default Accordion;
