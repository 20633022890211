import Button from '@/components/ui/Button/Button';
import CardWithImage from '@/components/ui/CardWithImage/CardWithImage';
import TravelLogIllustration from '@/assets/Illustrations/Travel-Log.svg?react';
import styles from './TripEmissionLogCard.module.scss';
import { externalLinks } from '@/constants/externalLinks.ts';

const TripEmissionLogCard = () => (
	<CardWithImage
		illustration={<TravelLogIllustration />}
		header={'Trip emission log'}
		text={
			<p>
				If you booked travel outside of Goodwings, make sure to register it to accurately represent your travel
				activity in emission reports.
			</p>
		}
		button={
			<Button
				color="default"
				onClick={() => (window.location.href = externalLinks.goodwingsLegacy.travelLog)}
				className={styles.logEmissionBttn}
			>
				Log trip booked outside of Goodwings
			</Button>
		}
		className={styles.tripEmissionCard}
	/>
);

export default TripEmissionLogCard;
