import CardWithImage from '@/components/ui/CardWithImage/CardWithImage';
import styles from './TravelPolicyCard.module.scss';
import TravelPolicy from '@/assets/Illustrations/Create-travel-policy.svg?react';
import ButtonLink from '@/components/ui/ButtonLink/ButtonLink.tsx';
import { externalLinks } from '@/constants/externalLinks.ts';

const TravelPolicyCard = () => (
	<CardWithImage
		illustration={<TravelPolicy />}
		header="How to create a sustainable corporate travel policy"
		button={
			<ButtonLink href={externalLinks.travelPolicy} target="_blank" color="link">
				Learn more
			</ButtonLink>
		}
		className={styles.travelPolicyCard}
	/>
);

export default TravelPolicyCard;
