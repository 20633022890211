import React, { useEffect } from 'react';
import styles from './SelectBaseline.module.scss';
import SelectHeader from '@/pages/Dashboard/EmissionTargetModal/SelectHeader.tsx';
import GraphIcon from '@/assets/Icons/Graph.svg?react';
import { useEmissionTargetStore } from '@/store/useEmissionTargetStore.ts';
import FormikInput from '@/components/Formik/FormikInput.tsx';
import { useFormikContext } from 'formik';
import { EmissionTargetValues } from '@/pages/Dashboard/EmissionTargetModal/EmissionTargetModal.tsx';
import { getCarbonIntensity } from '@/constants/emissionTarget.ts';

const SelectBaseline: React.FC = () => {
	const { carbonIntensity, setCarbonIntensity } = useEmissionTargetStore(state => state);
	const { values } = useFormikContext<EmissionTargetValues>();

	const { selectedType } = useEmissionTargetStore();

	useEffect(() => {
		values.lastYearCo2 &&
			values.lastYearRevenue &&
			setCarbonIntensity(getCarbonIntensity(values.lastYearCo2, values.lastYearRevenue));
	}, [setCarbonIntensity, values]);

	return (
		<div className={styles.selectBaseline}>
			<SelectHeader
				icon={<GraphIcon />}
				title="Decide on a baseline"
				text="Establishing a baseline is a critical step in setting your emission target.It provides a reference point from which to measure your progress."
			/>

			<div className={styles.content}>
				<h3>Enter your travel emissions from last {selectedType === 'absolute' ? 'year' : 'period'}</h3>
				<p>
					Input the total travel emissions (in metric tons of CO2e) from your last full reporting year. This
					quantifies the environmental impact of your business travel and lays the foundation for setting
					reduction goals.
				</p>

				<FormikInput
					type="number"
					label="Emissions in metric tons of CO2e"
					placeholder="Enter CO2e in metric tons"
					name="lastYearCo2"
					min={0.0001}
					color="gray"
					className={styles.inputField}
				/>

				{selectedType === 'intensity' && (
					<>
						<h3>Enter your revenue from last period</h3>

						<p>
							Provide your company's revenue from the last full year in millions of dollars. This will
							allow us to establish the ratio of emissions to economic output.
						</p>

						<FormikInput
							type="number"
							name="lastYearRevenue"
							label="Revenue in millions of dollars"
							placeholder="Enter revenue in millions"
							min={0.0001}
							color="gray"
							className={styles.inputField}
						/>

						<p>
							You current carbon intensity is <strong>{carbonIntensity} </strong>
							tons of CO2 per million dollar in revenue
						</p>
					</>
				)}
			</div>
		</div>
	);
};

export default SelectBaseline;
