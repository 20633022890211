import axios from 'axios';

interface RatingData {
	rating: number;
	comment?: string;
}

interface RatingResponse {
	showReviewPopup: boolean;
}

export const showRatingModal = async (): Promise<RatingResponse> => {
	const response = await axios.get('/users/review/show');
	return response.data;
};

export const setRating = async (data: RatingData): Promise<void> => {
	const response = await axios.post('/users/review', data);
	return response.data;
};

export const dismissRatingModal = async (): Promise<void> => {
	const response = await axios.post('/users/review/dismiss');
	return response.data;
};
