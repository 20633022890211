import CardWithImage from '@/components/ui/CardWithImage/CardWithImage';
import styles from './ExpenseGuideCard.module.scss';
import GuideTravel from '@/assets/Illustrations/Guide-travel-expenses-mng.svg?react';
import ButtonLink from '@/components/ui/ButtonLink/ButtonLink.tsx';
import { externalLinks } from '@/constants/externalLinks.ts';

const ExpenseGuideCard = () => (
	<CardWithImage
		illustration={<GuideTravel />}
		header="A complete guide to travel and expense management"
		button={<ButtonLink href={externalLinks.guideToTravelExpenseManagement} target='_blank' color='link'>Learn more</ButtonLink>}
		className={styles.expenseGuideCard}
	/>
);

export default ExpenseGuideCard;
