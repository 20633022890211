import React, { useEffect } from 'react';
import useScript from 'react-script-hook';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';

const WeglotProvider: React.FC = () => {
	const weGlotLanguageCode = useWhoamiStore(state => state.weGlotLanguageCode);

	// Load WeGlot externally and initialize it
	useScript({
		src: 'https://cdn.weglot.com/weglot.min.js',
		checkForExisting: true,
		onload: () =>
			window.Weglot.initialize({
				api_key: import.meta.env.VITE_WEGLOT_API_KEY,
			}),
	});

	useEffect(() => {
		// If Weglot is not loaded, or if whoami is not available yet, skip
		if (!window.Weglot || !weGlotLanguageCode) {
			return;
		}

		// Switch Weglot language to the language code from the whoami store
		window.Weglot.switchTo(weGlotLanguageCode);
	}, [weGlotLanguageCode]);

	return null;
};

export default WeglotProvider;
