import React from 'react';
import Header from '@/components/Header/Header.tsx';
import styles from './MainLayout.module.scss';

const MainLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<div className={styles.mainWrapper}>
			<Header />
			<div className={styles.layoutBackground}>{children}</div>
		</div>
	);
};

export default MainLayout;
