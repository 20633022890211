import { FC } from 'react';
import { useField } from 'formik';
import Select, { SelectProps } from '@/components/ui/Select/Select';

interface FormikSelectProps extends Omit<SelectProps, 'value' | 'onChange' | 'errorMessage' | 'touched'> {
	name: string;
}

const FormikSelect: FC<FormikSelectProps> = ({ name, options, ...props }) => {
	const [{ value, onBlur }, { error, touched }, { setValue }] = useField(name);
	return (
		<Select
			{...props}
			options={options}
			value={value}
			onChange={newValue => setValue(newValue)}
			errorMessage={error}
			touched={touched}
			onBlur={onBlur}
			name={name}
		/>
	);
};

export default FormikSelect;
