import React, { FC, useEffect } from 'react';
import styles from './Theme.module.scss';
import { each } from 'lodash';

interface ThemeProps {
	mode: 'light' | 'dark';
	children: React.ReactNode;
}

const Theme: FC<ThemeProps> = ({ mode, children }) => {
	useEffect(() => {
		// Theme mappings
		const themeClassnames = {
			dark: styles.dark,
			light: styles.light,
		};

		// Remove existing theme class from body
		each(themeClassnames, className => document.body.classList.remove(className));

		// Add theme class to body
		document.body.classList.add(themeClassnames[mode]);
	}, [mode]);

	return children;
};

export default Theme;
