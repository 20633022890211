import FlexBlock from '@/components/ui/FlexBlock/FlexBlock';
import styles from './ContributionList.module.scss';
import { motion } from 'framer-motion';
import { Title, UnifiedBreakdown } from './Contribution';

interface ContributionListProps {
	data: UnifiedBreakdown[];
	title?: Title;
}

const COLORS = ['#25528F', '#9861F6', '#46ffb9', '#FFDE86', '#F16D5E'];

const ContributionList: React.FC<ContributionListProps> = ({ data, title }) => {
	return (
		<div className={styles.contributionList}>
			<FlexBlock flexDirection="column" className={styles.contributionList}>
				<motion.div
					key={title?.value}
					initial={{ opacity: 0, x: -20 }}
					animate={{ opacity: 1, x: 0 }}
					exit={{ opacity: 0, x: -20 }}
					transition={{ duration: 0.5 }}
				>
					<h4>{title?.label}</h4>
				</motion.div>
				{data.map((item, index) => (
					<div className={styles.contibutionItem} key={index}>
						<div className={styles.circle} style={{ backgroundColor: COLORS[index % COLORS.length] }} />
						<FlexBlock flexDirection="column">
							<div className={styles.titleWrapper}>
								<div className={styles.title}>{item.title}</div>
								<div className={styles.tooltip}>{item.title}</div>
							</div>
							<div className={styles.subtitle}>{item.subtitle}</div>
						</FlexBlock>
						<FlexBlock className={styles.amountColumn} flexDirection="column">
							<motion.div
								key={item.co2eTotal}
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: '0' }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className={styles.amount}
							>
								{item.co2eTotal.toFixed(2)}
							</motion.div>
							<div className={styles.unit}>Tons of CO2</div>
						</FlexBlock>
					</div>
				))}
			</FlexBlock>
		</div>
	);
};

export default ContributionList;
