import { useField } from 'formik';
import DatePicker, { CustomDatePickerProps } from '@/components/ui/DatePicker/DatePicker.tsx';
import { DateValue } from 'react-aria-components';

interface FormikDatePickerProps<T extends DateValue>
	extends Omit<CustomDatePickerProps<T>, 'value' | 'onChange' | 'errorMessage' | 'touched'> {}

const FormikDatePicker = <T extends DateValue>({ name, ...props }: FormikDatePickerProps<T>) => {
	const [field, meta, helpers] = useField<T>(name);

	return (
		<DatePicker<T>
			{...props}
			name={name}
			value={field.value}
			onChange={value => helpers.setValue(value as unknown as T)}
			onBlur={field.onBlur}
			errorMessage={meta.error}
			touched={meta.touched}
		/>
	);
};

export default FormikDatePicker;
