import styles from './Modal.module.scss';
import React, { FC, ReactNode, useEffect, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useModalStore } from '@/store/useModalStore.ts';
import classNames from 'classnames';
import CloseIcon from '@/assets/Icons/Close.svg?react';

interface ModalHandlerProps {
	children: ReactNode;
	closeable?: boolean;
	className?: string;
	onClose?: () => void;
	outsideClickEnabled?: boolean;
}

interface ModalProps {
	name: string;
	children: ReactNode;
	closeable?: boolean;
	className?: string;
	onClose?: () => void;
	outsideClickEnabled?: boolean;
}

const ModalHandler: FC<ModalHandlerProps> = ({
	children,
	closeable = true,
	onClose,
	className,
	outsideClickEnabled = true,
}) => {
	const closeModal = useModalStore(store => store.closeModal);
	const modalRef = useRef<HTMLDivElement>(null);

	const handleCloseModal = () => {
		closeModal();
		onClose && onClose();
	};

	const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (outsideClickEnabled && e.target === modalRef.current) {
			handleCloseModal();
		}
	};

	useHotkeys('esc', handleCloseModal, {
		enabled: closeable,
	});

	useEffect(() => {
		document.body.style.overflowY = 'hidden';

		return () => {
			document.body.style.overflowY = 'auto';
		};
	}, []);

	return (
		<div className={styles.wrapper}>
			<div className={styles.overlay} ref={modalRef} onClick={handleOverlayClick}>
				<div className={classNames(styles.box, className)}>
					{children}
					{closeable && (
						<button onClick={handleCloseModal} className={styles.closeButton}>
							<CloseIcon />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

const Modal: FC<ModalProps> = ({ name, children, closeable = true, onClose, className, outsideClickEnabled }) => {
	const activeModal = useModalStore(store => store.activeModal);

	if (activeModal !== name) {
		return null;
	}

	return (
		<ModalHandler
			closeable={closeable}
			className={className}
			onClose={onClose}
			outsideClickEnabled={outsideClickEnabled}
		>
			{children}
		</ModalHandler>
	);
};

export default Modal;
