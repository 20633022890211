import { FC } from 'react';
import { useField } from 'formik';
import { omit } from 'lodash';
import PinInput, { PinInputProps } from '@/components/ui/PinInput/PinInput.tsx';

interface FormikPinInputProps extends Omit<PinInputProps, 'value' | 'onChange'> {
	name: string;
}

const FormikPinInput: FC<FormikPinInputProps> = ({ name, length, ...props }) => {
	const [{ value }, { error, touched }, { setValue }] = useField(name);

	return (
		<PinInput
			{...omit(props, ['value', 'onChange'])}
			error={touched ? error : undefined}
			length={length}
			value={value}
			onChange={newValue => setValue(newValue)}
		/>
	);
};

export default FormikPinInput;
