import { create } from 'zustand';

interface ModalStoreTypes {
	activeModal: null | string;
	openModal: (modalName: string) => void;
	closeModal: () => void;
}

export const useModalStore = create<ModalStoreTypes>(set => ({
	activeModal: null,
	openModal: (modalName: string) =>
		set({
			activeModal: modalName,
		}),
	closeModal: () =>
		set({
			activeModal: null,
		}),
}));
