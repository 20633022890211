import MainLayout from '@/layouts/MainLayout/MainLayout.tsx';
import Welcome from '@/pages/Dashboard/Welcome/Welcome';
import Row from '@/components/ui/Row/Row.tsx';
import Column from '@/components/ui/Column/Column.tsx';
import Container from '@/components/ui/Container/Container.tsx';
import breakpoints from '@/constants/breakpoints.ts';
import React, { useEffect } from 'react';
import BudgetEmissionChart from './BudgetEmissionChart/BudgetEmissionChart';
import ForecastChart from './ForecastChart/ForecastChart';
import TripEmissionLogCard from './InfoCards/TripEmissionLogCard/TripEmissionLogCard';
import LearnHowToTravelCard from './InfoCards/LearnHowToTravelCard/LearnHowToTravelCard';
import CertificateCard from './InfoCards/CertificateCard/CertificateCard';
import EmissionReportCard from './InfoCards/EmissionReportCard/EmissionReportCard';
import VideoCard from './InfoCards/VideoCard/VideoCard';
import ExpenseGuideCard from './InfoCards/ExpenseGuideCard/ExpenseGuideCard';
import TravelPolicyCard from './InfoCards/TravelPolicyCard/TravelPolicyCard';
import QuizCard from './InfoCards/QuizCard/QuizCard';
import UpcomingTrips from './UpcomingTrips/UpcomingTrips';
import SustainabilityToolsHeader from './SustainabilityToolsHeader/SustainabilityToolsHeader';
import EmissionTarget from './EmissionTarget/EmissionTarget';
import ContainerTitle from './ContainerTitle/ContainerTitle';
import Filters from './Filters/Filters';
import FlexBlock from '@/components/ui/FlexBlock/FlexBlock';
import Contribution from './Contribution/Contribution';
import { useQuery } from '@tanstack/react-query';
import { getDashboardAnalytics } from '@/api/dashboardAnalytics.ts';
import queryKeys from '@/constants/queryKeys.ts';
import useDashboardAnalytics from '@/store/useDashboardAnalytics.ts';
import { Roles } from '@/api/user.ts';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';

const Dashboard: React.FC = () => {
	const { timePeriod } = useDashboardAnalytics();
	const spotnanaRole = useWhoamiStore(store => store.user?.spotnanaRole);
	const showSustainalbeTools =
		spotnanaRole === Roles.CompanyAdmin || spotnanaRole === Roles.TmcAdmin || spotnanaRole === Roles.TmcAgent;

	const query = useQuery({
		queryKey: [queryKeys.getDashboardAnalytics, timePeriod],
		queryFn: () => getDashboardAnalytics(timePeriod),
		enabled: false,
	});

	useEffect(() => {
		timePeriod && query.refetch();
	}, [timePeriod]);

	return (
		<MainLayout>
			<title>Goodwings - Compromise free travel</title>
			<Welcome />
			<UpcomingTrips />
			<Container>
				<Row
					rowGap="40px"
					columnGap="65px"
					flexDirection={{
						[breakpoints.zero]: 'column',
						[breakpoints.desktop]: 'row',
					}}
				>
					<Column>
						<TripEmissionLogCard />
					</Column>
					<Column>
						<LearnHowToTravelCard />
					</Column>
				</Row>
			</Container>
			{showSustainalbeTools && (
				<>
					<SustainabilityToolsHeader />
					<EmissionTarget queryAnalytics={query} />
					<Container>
						<FlexBlock
							justifyContent="space-between"
							alignItems="center"
							flexDirection={{
								[breakpoints.zero]: 'column',
								[breakpoints.tablet]: 'row',
							}}
						>
							<ContainerTitle text="CO2 Analytics" />
							<Filters queryAnalytics={query} />
						</FlexBlock>
					</Container>
					<Container>
						<Row
							flexDirection={{
								[breakpoints.zero]: 'column',
								[breakpoints.tablet]: 'row',
							}}
							columnGap="30px"
						>
							<Column>
								<BudgetEmissionChart queryAnalytics={query} />
							</Column>
							<Column flex="0 0 25%">
								<Contribution queryAnalytics={query} />
							</Column>
						</Row>
					</Container>
					<Container>
						<ContainerTitle
							highlightedText="AI Powered analytics:"
							text="Emission forecasting and mitigation tool"
						/>
					</Container>
					<Container>
						<ForecastChart queryAnalytics={query} />
					</Container>
					<Container>
						<ContainerTitle text="Emission reporting, certification and documentation" />
					</Container>
					<Container>
						<Row
							rowGap="65px"
							columnGap="65px"
							flexDirection={{
								[breakpoints.zero]: 'column',
								[breakpoints.desktop]: 'row',
							}}
						>
							<Column>
								<CertificateCard />
							</Column>
							<Column>
								<EmissionReportCard />
							</Column>
						</Row>
					</Container>
				</>
			)}
			<Container>
				<ContainerTitle text="Climate academy, quizzes, e-learning and more" />
			</Container>
			<Container>
				<Row
					rowGap="40px"
					columnGap="65px"
					flexDirection={{
						[breakpoints.zero]: 'column',
						[breakpoints.desktop]: 'row',
					}}
				>
					<Column>
						<VideoCard />
					</Column>
					<Column>
						<ExpenseGuideCard />
					</Column>
				</Row>

				<Row
					rowGap="40px"
					columnGap="65px"
					flexDirection={{
						[breakpoints.zero]: 'column',
						[breakpoints.desktop]: 'row',
					}}
				>
					<Column>
						<TravelPolicyCard />
					</Column>
					<Column>
						<QuizCard />
					</Column>
				</Row>
			</Container>
		</MainLayout>
	);
};

export default Dashboard;
