import axios from 'axios';

export interface AutocompletePlaceOption {
	id: string;
	name: string;
	region?: string;
}

export const searchCity = async (query: string): Promise<AutocompletePlaceOption[]> => {
	const response = await axios.post(`/trip-planner/places/search`, { query });
	return response.data.places;
};

export const searchLodging = async (googlePlaceId: string, query: string): Promise<AutocompletePlaceOption[]> => {
	const response = await axios.post(`/trip-planner/lodging/search`, { googlePlaceId, query });
	return response.data.lodging;
};
