import React from 'react';
import styles from './Card.module.scss';
import classNames from 'classnames';

interface CardProps {
	className?: string;
	children: React.ReactNode;
	onClick?: () => void;
}

const Card: React.FC<CardProps> = ({ children, className, onClick }) => {
	return (
		<div className={classNames(styles.cardWrapper, className)} onClick={onClick}>
			{children}
		</div>
	);
};

export default Card;
