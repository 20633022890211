import axios from 'axios';

export type EmissionTargetType = 'intensity' | 'absolute';
export type EmissionTargetPeriod = 'fiscal' | 'calendar';

export interface EmissionTargetResponse {
	type?: EmissionTargetType;
	yearType?: EmissionTargetPeriod;
	fiscalStartDay?: number;
	fiscalStartMonth?: number;
	lastYearCo2?: number;
	lastYearRevenue?: number;
	expectedRevenue?: number;
	reductionPercentage?: number;
	targetCo2?: number | null;
}

export interface EmissionTargetRequest extends EmissionTargetResponse {}

export const getEmissionTarget = async (): Promise<EmissionTargetResponse> => {
	const response = await axios.get('/carbon-emission-target');
	return response.data;
};

export const createEmissionTarget = async (data: EmissionTargetRequest): Promise<void> => {
	await axios.post<EmissionTargetResponse>('/carbon-emission-target', data);
};

export const deleteEmissionTarget = async (): Promise<void> => {
	await axios.delete('/carbon-emission-target');
};
