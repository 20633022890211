import styles from './ContainerTitle.module.scss';

interface ContainerTitle {
	text: string;
	highlightedText?: string;
}

const ContainerTitle: React.FC<ContainerTitle> = ({ text, highlightedText }) => (
	<h3 className={styles.title}>
		{highlightedText && <span>{highlightedText}</span>}
		{text}
	</h3>
);

export default ContainerTitle;
