import { FC } from 'react';
import { useField } from 'formik';
import CalendarPicker, { CalendarPickerProps } from '@/components/ui/CalendarPicker/CalendarPicker.tsx';

interface FormikCalendarPickerProps
	extends Omit<CalendarPickerProps, 'value' | 'onSelect' | 'errorMessage' | 'touched'> {
	name: string;
}

const FormikCalendarPicker: FC<FormikCalendarPickerProps> = ({ name, ...props }) => {
	const [{ value, onBlur }, { error, touched }, { setValue }] = useField(name);

	return (
		<CalendarPicker
			{...props}
			value={value}
			onSelect={newValue => setValue(newValue)}
			errorMessage={error}
			touched={touched}
			onBlur={onBlur}
			name={name}
		/>
	);
};

export default FormikCalendarPicker;
